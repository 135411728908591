import React from 'react'
import Button from '../../../common/components/Button'
import Icons from '../../../base/icons'
import Iframe from './Iframe'
import block from 'bem-cn-lite';
import FirtsQuiz from '../FirstQuiz';
import QiuzAdditionalFunctionality from './QiuzAdditionalFunctionality';

const b = block('Controls');

export default
  class Offer extends React.Component {

    _handlerOffer = () => {
      this.props.showModal(QiuzAdditionalFunctionality, {
        actions: this.props
      })

    }

    render() {
      return(
        <aside className={b('Offer')}>
          <h3>Нужен сервис с дополнительным функционалом?</h3>
          <ul>
            <li>Управление автопарком для 2х и более авто</li>
            <li>Построение маршрутов от 30 адресов</li>
            <li>Рабочее место логиста + Мобильное приложение для водителя(контроль движения, отчеты)</li>
            <li>Отчеты план-факт</li>
            <li>Работа по API</li>
          </ul>
          <br />
          <Button
            className={b('')}
            icon={Icons.FEEDBACK}
            onClick={this._handlerOffer}
          >Оставить заявку
          </Button>
        </aside>
      );
    }
  }