import React from 'react'
import block from 'bem-cn-lite';
import { Icon, TimeInput, NumberInput, Button, ConfirmButton, ToggleSwitch } from '../../../common/components';
import { mlUnits } from '../../../common/multylanguage'
import Icons from '../../../base/icons';
import queries from '../../../base/queries';
import I18N from './../../../base/i18n'
import { toast } from 'react-toastify';
// Enum
import { Agents, ButtonKinds } from '../../../common/helpers/enums';

const b = block('ControlsPersonalArea');

export default
	class ControlsPersonalArea extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			favoriteRoute: false,
			quizAnswersStatus: false,
			quizAnswers: {},
			manifests: [],
			ToggleSwitchDistance: props.user.distance === 'miles' ? true : false,
			ToggleSwitchTime: props.user.time === '12Hour' ? true : false,
		}
	}

	componentWillMount() {
		/* eslint-disable-next-line */
		// VK !== null && VK.Widgets.Comments("vk_comments", { limit: 10, attach: "*" })
		this.getManifests();
	}

	getManifests = () => {
		queries.MANIFEST_LIST()
			.then((res) => {
				if (res.data.result) {
					this.setState({ manifests: res.data.result });
				}
			})
			.catch(() => {
				toast.error(I18N.FAVORITE_ROUTE_LOAD_MANIFESTS_ERROR);
			});
	}

	_handlerLoadManifest = (manifestId) => {
		queries.MANIFEST_POINTS(manifestId)
		.then((res) => {
			if (res.data.result) {
				this.props.resetAddresses();
				this.props.updatePointsIds(res.data.result);
				this.props.setCurrentManifestID(manifestId);
				// Rebuild map
				this.props.updatePointCoords(res.data.result[0].id, res.data.result[0].lat_lon);
				queries.MANIFEST_WAYBILL_POINTS(manifestId)
				.then((resWPoints) => {
					// this.props.sortPoints( this.props.points, res.data.result)
					const resWaybillPoints = resWPoints.data.result;
					this.props.updateWaybillData(resWaybillPoints);
				});
				queries.MANIFEST_COURIERS(manifestId)
				.then((resCouriers) => {
				  this.props.updateAgentOnDBSave(resCouriers.data.result);
				});
				queries.MANIFEST(manifestId)
				.then((resManifest) => {
					const manifest_data = resManifest.data.result;
					this.props.updateStatistics(manifest_data);
				});


				// this.props.ChangeTopSwitcherMode('route');
				toast.success(I18N.FAVORITE_ROUTE_LOAD_MANIFEST_SUCCESS);
			} else {
				toast.error(I18N.FAVORITE_ROUTE_LOAD_MANIFEST_ERROR);
			}
		})
	}

	_handlerDeleteManifest = (manifestId) => {
		queries.DELETE_MANIFEST(manifestId)
			.then((res) => {
				if (res.data.result) {
					const newManifests = this.state.manifests.filter(manifest => manifest.id != manifestId)
					this.setState({manifests: newManifests})
					toast.success(I18N.FAVORITE_ROUTE_DELETE_MANIFEST_SUCCESS);
				} else {
					toast.success(I18N.FAVORITE_ROUTE_DELETE_MANIFEST_ERROR);
				}
			})
	}

	favoriteRoute = (manifest) => {
		return <div style={{ display: "flex", alignItems: "center", margin: "0.3rem", }}>
			<div className={b('RouteButton')}>
				{manifest.name}
			</div>
			<ConfirmButton
        		kind={ButtonKinds.DANGER}
				icon={Icons.POINT_REMOVE}
				smallerIcon={true}
				toolTip={I18N.PERSONAL_AREA_DELETE_MANIFEST_TIP}
				onClick={() => this._handlerDeleteManifest(manifest.id)}
				confirmMessage={I18N.PERSONAL_AREA_DELETE_MANIFEST_ALERT.replace("%%", manifest.name)}
			></ConfirmButton>
			<ConfirmButton
        		kind={ButtonKinds.SMALL}
				icon={Icons.CALCULATION_DOWNLOAD}
				smallerIcon={true}
				toolTip={I18N.PERSONAL_AREA_LOAD_MANIFEST_TIP}
				onClick={() => this._handlerLoadManifest(manifest.id)}
				confirmMessage={I18N.PERSONAL_AREA_LOAD_MANIFEST_ALERT.replace("%%", manifest.name)}
			></ConfirmButton>
		  </div> 
		
	}

	renderFavoriteRoutes = (manifests) => {
		if (manifests.length > 0) {
			return (
				<div className={b('RoutesButtonWrapper')}>
					{manifests.map((manifest) => this.favoriteRoute(manifest))}
				</div>
			)
		} else {
			return (<div>
				{ I18N.FAVORITE_ROUTE_NO_MANIFEST }
			</div>);
		}
	}

	_handleChangeDefaultAgent = (name, value) => {
		this.props.updateDefault('agent', name, value);
	}

	_handleChangeDefaultPoint = (name, value) => {
		this.props.updateDefault('point', name, value);
	}
	_toggleDistance = () => {
		this.props.updateUser(this.props.user, 'distance', !this.state.ToggleSwitchDistance ? 'miles' : 'kilometres');
		this.setState((state) => ({ ...state, ToggleSwitchDistance: !state.ToggleSwitchDistance }))
	}

	_toggleTime = () => {
		this.props.updateUser(this.props.user, 'time', !this.state.ToggleSwitchTime ? '12Hour' : '24Hour');
		this.setState((state) => ({ ...state, ToggleSwitchTime: !state.ToggleSwitchTime }))
	}

	render() {
		return <div className={b()}>
			{/* Settings togle switchers */}
			<div className={b('Wrapper')}>
				<div className={b('Switcher')}>
					<ToggleSwitch
						ToggleSwitchChecked={this.state.ToggleSwitchDistance}
						onChange={this._toggleDistance}
					/>
					<div className={b('SwitcherInfo')}>
						{this.state.ToggleSwitchDistance && I18N.PERSONAL_AREA_M}
						{!this.state.ToggleSwitchDistance && I18N.PERSONAL_AREA_KM}
					</div>
				</div>
				<div className={b('Switcher')}>
					<ToggleSwitch
						ToggleSwitchChecked={this.state.ToggleSwitchTime}
						onChange={this._toggleTime}
					/>
					<div className={b('SwitcherInfo')}>
						{this.state.ToggleSwitchTime && I18N.PERSONAL_AREA_12}
						{!this.state.ToggleSwitchTime && I18N.PERSONAL_AREA_24}
					</div>
				</div>
			</div>

			{/* courier default fields */}

			<label className={b("WorkTimeLabel")}>
				{I18N.CONTROLS_AGENT_WORKTIME}:
			</label>
			<div
				className={b("WorkTime")}
			>
				<TimeInput
					name={"workStart"}
					value={this.props.default.agent.workStart}
					onChangeValue={this._handleChangeDefaultAgent}
				/>
				<TimeInput
					name={"workEnd"}
					value={this.props.default.agent.workEnd}
					onChangeValue={this._handleChangeDefaultAgent}
				/>
				<NumberInput
					name={"maxPoints"}
					placeHolder={ I18N.COURIER_MAX_POINTS }
					value={this.props.default.agent.maxPoints}
					onChangeValue={this._handleChangeDefaultAgent}
				/>
				<Button
					icon={Icons.AGENT_LIGHT}
					disabled={this.props.default.agent.delType === Agents.CAR}
					onClick={() =>
						this._handleChangeDefaultAgent('delType', Agents.CAR)
					}
					className="NewBtn"
				/>
				<Button
					icon={Icons.AGENT_MAN}
					disabled={this.props.default.agent.delType === Agents.FOOT}
					// disabled={agent.deltype === Agents.FOOT}
					onClick={() =>
						this._handleChangeDefaultAgent('delType', Agents.FOOT)
					}
					className="NewBtn"
				/>
			</div>

			{/* Point default fields */}

			<label className={b('WorkTimeLabel')}>{I18N.CONTROLS_POINT_WORKTIME}:</label>
			<div className={b('WorkTime')}>
				<TimeInput
					name={'workStart'}
					onChangeValue={this._handleChangeDefaultPoint}
					value={this.props.default.point.workStart}
				/>
				<TimeInput
					name={'workEnd'}
					onChangeValue={this._handleChangeDefaultPoint}
					value={this.props.default.point.workEnd}
				/>
				<NumberInput
					name={'shipping_time'}
					onChangeValue={this._handleChangeDefaultPoint}
					value={this.props.default.point.shipping_time}
				/>
			</div>

			{/* Favorite routes */}

			<div className={b('RoutesWrapper')}>
				<h3>{ I18N.FAVORITE_ROUTE_TITLE }</h3>
				{this.renderFavoriteRoutes(this.state.manifests)}
			</div>

		</div>;
	}
}