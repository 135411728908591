export
const pointsSort = (p1, p2) => {
	if ((p1.coords && !p2.coords) || (p1.agent && !p2.agent)) return 1;
	if (!(p1.coords && p2.coords) || (!p1.agent && p2.agent)) return -1;
  if ( p1.agent === p2.agent && p1.totalPoints === p2.totalPoints && p1.garage === p2.garage ) return 0;
  if ( p1.totalPoints && p2.totalPoints && !p1.garage && !p2.garage )
  return p1.agent===p2.agent ? p1.totalPoints-p2.totalPoints : p1.agent-p2.agent;
	if (!p1.totalPoints && p2.totalPoints && !p1.garage && !p2.garage) return 1;
	if (p1.totalPoints && !p2.totalPoints && !p1.garage && !p2.garage) return -1;
	if (p1.garage && !p2.garage) return -1;
	else return 1;
};

export
const decimalHoursToTime = (hours) => {
  if (hours === null)
    return '';
  const h = Math.floor(hours);
  const m = Math.round(hours % 1 * 60);
  return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`;
};

export
const timeToDecimalHours = (time) => {
  const [h, m] = time.split(":");
  if (isNaN(h) || isNaN(m))
    return null;
  // console.log(m,"M");
  let newM = time15step(m);
  return Number(h) + Number(newM) / 60;
};

export
const getCoordsScope = (points) => {
  const min = [360, 360];
  const max = [-360, -360];

  for (const p of points) {
    if (!p.coords)
      continue;
    min[0] = Math.min(min[0], p.coords[0]);
    min[1] = Math.min(min[1], p.coords[1]);
    max[0] = Math.max(max[0], p.coords[0]);
    max[1] = Math.max(max[1], p.coords[1]);
  }

  return { min: min, max: max };
};

export
const getAppropriateZoom = (maxDiff) => {
  const empericalFactor = 1000;

  let zoom = 15;
  while (zoom > 0) {
    zoom--;
    if (empericalFactor / maxDiff > Math.pow(2, zoom))
      return zoom;
  }
  return 10;
};

export
const decodeDateRU = (date, delimeter = '.') => {
  const nDate = new Date(date);
  const d = `0${nDate.getDate()}`.slice(-2);
  const m = `0${nDate.getMonth() + 1}`.slice(-2);
  const y = `000${nDate.getFullYear()}`.slice(-4);
  return `${d}${delimeter}${m}${delimeter}${y}`;
};

export
const metersToMiles = (meters) => (Number(meters) / 1609.34).toFixed(2);

export
const metersToKilometers = (meters) => (Number(meters) / 1000).toFixed(2);

export
const isMobileDevice = () => window.innerWidth <= 720;

export
const time15step = (value) => {
  const steps = [0,15,30,45,60];
  let elem = steps.find((elem,idx)=>{
    if((elem <= Number(value)) && (Number(value) <= steps[idx+1])){
      return true
    }
  })
  return elem
}