import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { Map, TileLayer } from 'react-leaflet';

import LeafletMapPointsLayer from './LeafletMapPointsLayer';
import LeafletMapRoutesLayer from './LeaflerMapRoutesLayer';
import ContextMenu from "./ContextMenu";
import { getCoordsScope, getAppropriateZoom } from '../../../common/helpers/misc';
import AddressPoint from '../../../common/helpers/AddressPoint';
import {actions, DEFAULT_LOCATION} from '../../../actions';
import "leaflet-polylinedecorator";

const b = block('LeafletMap');
const DEFAULT_ZOOM = 3;

export default
class LeafletMap extends React.Component {
  static propTypes = {
    points: PropTypes.objectOf(PropTypes.instanceOf(AddressPoint).isRequired),
	  currentLocation: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      mapCenter: this.props.currentLocation,
      mapZoom: DEFAULT_ZOOM,
    };

    this.contextMenu = React.createRef();
  }

  componentDidMount() {
    this._setMapParams(this.props);
    this.updatePointCoordsEvent = actions.updatePointCoords.subscribe(this._setMapParams);
		this.updateCurrentLocationEvent = actions.setCurrentLocation.subscribe(this._setMapParams);
    // this.resetAddressesEvent = actions.resetAddresses.subscribe(this._resetMapParams);
  }

  componentWillUnmount() {
    this.updatePointCoordsEvent.unsubscribe();
    // this.resetAddressesEvent.unsubscribe();
		this.updateCurrentLocationEvent.unsubscribe();
  }

  _resetMapParams = () => this.setState({
		mapCenter: this.props.currentLocation,
		mapZoom: this._calculateZoom(
				this._makeSinglePointCoordsScope(this.props.currentLocation)
		)
  });

  _makeSinglePointCoordsScope(coords) {
		return getCoordsScope([
			{ coords: coords }
		]);
	};

  _calculateZoom(coordsScope) {
		return getAppropriateZoom(
			Math.max(coordsScope.max[0] - coordsScope.min[0], coordsScope.max[1] - coordsScope.min[1])
		);
	}

  _setMapParams = (action) => {
    const geocodedPoints = Object
      .values(action.points)
      .filter((p) => p.coords);

    let coordsScope, mapCenter, forceZoom;

		if (geocodedPoints.length) {
			coordsScope = getCoordsScope(geocodedPoints);
			mapCenter = [
				(coordsScope.min[0] + coordsScope.max[0]) / 2,
				(coordsScope.min[1] + coordsScope.max[1]) / 2
			];
		} else {
			mapCenter = action.currentLocation;
			if (mapCenter !== DEFAULT_LOCATION) {
					coordsScope = this._makeSinglePointCoordsScope(action.currentLocation);
			}
		}

		this.setState({
			mapCenter: mapCenter,
			mapZoom: coordsScope ? this._calculateZoom(coordsScope) : DEFAULT_ZOOM
		});
  };

  render() {

    return <div className={b()}>
      <Map
        center={this.state.mapCenter}
        zoom={this.state.mapZoom}
        zoomControl={false}
        maxZoom={18}
        onContextMenu={e => this.contextMenu.current.show(e)}
      >
        <TileLayer
          className={b('Map')}
          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          url="https://tile.zig-zag.org/tile/{z}/{x}/{y}.png"
          useCache={true}
        />
        <LeafletMapPointsLayer {...this.props} />
        <LeafletMapRoutesLayer {...this.props} />

        <ContextMenu ref={this.contextMenu} {...this.props}/>
      </Map>
    </div>;
  }
}
