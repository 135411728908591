import {decodeDateRU} from "../../../common/helpers/misc";

export default {
		generate(points) {
				const name = 'Маршрут от ' + decodeDateRU(new Date(), '.');
				let pointsXml = '';

				for (let i = 0; i < points.length; i++) {
						let point = points[i];
						pointsXml += `<trkpt lat="${point.coords[0]}" lon="${point.coords[1]}"/>`;
				}

				return `<?xml version="1.0"?>
<gpx
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xmlns="http://www.topografix.com/GPX/1/1"
    xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd"
    version="1.1"
    creator="poncy.su" >
    <metadata>
				<name>${name}</name>
				<author><name>Poncy</name></author>
				<time>${(new Date()).toISOString()}</time>
		</metadata>
		<trk>
				<name>${name}</name>
				<cmt></cmt>
				<desc></desc>
				<src></src>
				<trkseg>${pointsXml}</trkseg>
		</trk>
</gpx>`;
		},

		download(points, fileName) {
				const fileType = 'application/gpx+xml';
				const blob = new Blob([this.generate(points)], { type: fileType });

				const a = document.createElement('a');
				a.download = fileName;
				a.href = URL.createObjectURL(blob);
				a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
				a.style.display = "none";
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);
		}
}