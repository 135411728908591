import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from "history"

import App from './App';
import { getAllReducers } from './base/reducer';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory()

export
const store = createStore(
  combineReducers({
    ...getAllReducers(),
    router: connectRouter(history)
  }),
  {},
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history))
  )
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter store={store} history={history}>
      <App/>
    </ConnectedRouter>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
