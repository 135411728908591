export const Agents = {
  CAR: "car",
  HGV: "hgv",
  FOOT: "realfoot",
};

export const ButtonKinds = {
  WARNING: "WARNING",
  DANGER: "DANGER",
  UNIMPORTANT: "UNIMPORTANT",
  CONFIRM: "CONFIRM",
  SMALL: "SMALL",
};

// Для панели управления
// По одному
// Списком
// Файл
export const Controls_enum = {
  MANUAL: "MANUAL",
  TEXT: "TEXT",
  FILE: "FILE",
};
