import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import queries from '../../base/queries';

const b = block('Input');

export default
class AddressInput extends React.PureComponent {
  static fieldName = 'address';

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    coordsName: PropTypes.string.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    focusPoint: PropTypes.func,
    hoverPoint: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.geocodeRequestTimer = null;
    this.fromView = String;

    this.state = {
      hintSelected: false,
      hintInFocus: undefined,
      hints: []
    };
  }

  _navigateHints = (e) => {
    if (this._hasVisibleHints()) {
      let newHintInFocus = this.state.hintInFocus;

      if (e.key === 'ArrowDown') {
        newHintInFocus = this.state.hintInFocus >= 0 && this.state.hintInFocus < this.state.hints.length - 1
          ? this.state.hintInFocus + 1
          : 0;
      }
      else if (e.key === 'ArrowUp') {
        newHintInFocus = this.state.hintInFocus > 0 && this.state.hintInFocus < this.state.hints.length
          ? this.state.hintInFocus - 1
          : this.state.hints.length - 1;
      }
      else if (e.key === 'Enter' && newHintInFocus !== undefined) {
        this._onHintSelected(newHintInFocus);
      }

      this.setState({ hintInFocus: newHintInFocus });
    }
  }

  _hasVisibleHints = () => !this.state.hintSelected
    && this.state.hints
    && this.state.hints.length > 0;
  
  _onHintSelected = (hindIndex) => {
    const h = this.state.hints[hindIndex];
    this.setState({
      hintSelected: true,
      hintInFocus: undefined,
      value: h.caption 
    }, () => {
      this._onCaptionChanged(h.caption);
      this.props.updatePointCoords(this.props.name, h.value);
    });
  }

  _renderHints = () => this.state.hints
    .map((h, i) => <li
      key={h.key}
      onClick={() => this._onHintSelected(i)}
      className={b('OptionItem')}
    >
      {h.caption}
    </li>);

_getGeocodeOptions = () => {
  queries.getGeocodeOptions(this.state.value)
    .then((options) => {
      this.setState({
        hints: options,
      });
    });
}

_onCaptionChanged = (value) => this.props.onChangeValue
    && this.props.onChangeValue(this.props.name, value);

_onChange = (e) => {
  this._onCaptionChanged(e.target.value);
  this.setState({ value: e.target.value });
  clearTimeout(this.geocodeRequestTimer);
  this.geocodeRequestTimer = setTimeout(this._getGeocodeOptions, 400);
  this.setState({ hintSelected: false });
}

  render() {
    return [
      <input
        {...this.props}
        onBlur={() => this.props.focusPoint()}
        onFocus={() => this.props.focusPoint(this.props.name)}
        key={'input'}
        id={this.props.name}
        className={b()}
        value={(this.props.toView ? this.props.toView(this.props.value) : this.props.value) || ''}
        onChange={this._onChange}
        type='text'
        onKeyDown={this._navigateHints}
        x-webkit-speech
      />,
      this._hasVisibleHints()
        && <div
          key={'hints'}
          className={b('Options')}
        >
          {this._renderHints()}
        </div>
    ];
  }
}
