import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import ReactTooltip from 'react-tooltip';

const b = block('Input');

export default
class PhoneInput extends React.PureComponent {
    static propTypes = {
        size: PropTypes.number,
        caption: PropTypes.string,
        name: PropTypes.string,
        placeHolder: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChangeValue: PropTypes.func,
        toolTip: PropTypes.string,
    };

    _getInputNumbersValue = (input) => {
        // Return stripped input value — just numbers
        return input.value.replace(/\D/g, '');
    }

    _onPhonePaste = (e) => {
        var input = e.target,
            inputNumbersValue = this._getInputNumbersValue(input);
        var pasted = e.clipboardData || window.clipboardData;
        if (pasted) {
            var pastedText = pasted.getData('Text');
            if (/\D/g.test(pastedText)) {
                // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
                // formatting will be in onPhoneInput handler
                this.props.onChangeValue(inputNumbersValue);
                return;
            }
        }
    }
    _onPhoneInput = (e) => {
        var input = e.target,
            inputNumbersValue = this._getInputNumbersValue(input),
            selectionStart = input.selectionStart,
            formattedInputValue = "";

        if (!inputNumbersValue) {
            return this.props.onChangeValue("");
        }

        if (input.value.length != selectionStart) {
            // Editing in the middle of input, not last symbol
            if (e.data && /\D/g.test(e.data)) {
                // Attempt to input non-numeric symbol
                this.props.onChangeValue(inputNumbersValue);
            }
            return;
        }

        if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] == "9") inputNumbersValue = "7" + inputNumbersValue;
            var firstSymbols = (inputNumbersValue[0] == "8") ? "8" : "+7";
            formattedInputValue = input.value = firstSymbols + " ";
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
            }
        } else {
            formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
        }
        this.props.onChangeValue(formattedInputValue);
    }
    _onPhoneKeyDown = (e) => {
        // Clear input after remove last symbol
        var inputValue = e.target.value.replace(/\D/g, '');
        if (e.keyCode == 8 && inputValue.length == 1) {
            this.props.onChangeValue("");
        }
    }

    render() {
        return (
            <div data-tip={this.props.toolTip}>
                {this.props.toolTip && <ReactTooltip /> }
                <input
                    autoComplete={this.props.autoComplete}
                    className={b()}
                    id={this.props.name}
                    type='tel'
                    name={this.props.name}
                    placeholder={this.props.placeHolder}
                    value={this.props.value}

                    onPaste={this._onPhonePaste}
                    onInput={this._onPhoneInput}
                    onKeyDown={this._onPhoneKeyDown}
                />
            </div>
        );
    }
}
