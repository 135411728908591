import React from 'react'
import block from 'bem-cn-lite'
import I18N from '../../base/i18n'

const b = block('Controls');

export default
  class AboutService extends React.Component {
    constructor(props){
      super(props);

      this.state = {
        aboutSwitcher: 'about',
        activeAnswer: '',
      }
    }

    _aboutNav = () => {
      return <nav className={b('Second-Switcher')}>
        <div className={b('item', this.state.aboutSwitcher === 'about' && 'active')}
          onClick={() => this.setState({ aboutSwitcher: 'about' })}
        >Описание</div>
        <div className={b('item', this.state.aboutSwitcher === 'FAQ' && 'active')}
          onClick={() => this.setState({ aboutSwitcher: 'FAQ' })}
        >FAQ</div>
      </nav>
    }

    _activeAnswer = (id) => {
      if (id == this.state.activeAnswer) {
        this.setState({ activeAnswer: '' })
      } else
      this.setState({ activeAnswer: id })
    }

  render() {
    return (
      <section className={b('AboutService')}>
        {/* <h2>О сервисе</h2> */}
        {this._aboutNav()}
        {this.state.aboutSwitcher === 'about' && <div>
        <p>
          Сервис poncy.su позволяет бесплатно построить оптимальный маршрут на карте по точкам. Точки можно заполнить вручную (добавляя каждый адрес <strong>по одному</strong>) или выгрузить списком из текстового документа на <strong>вкладке списком</strong>. Начальную, конечную и промежуточную <strong>точку можно добавить с помощью мышки</strong>, кликните  правой кнопкой по карте и выберете соответствующий пункт из выпадающего списка.
          </p>
        <br />
        <h3>Сервис является бесплатным</h3>
        <br />
        <p>
          В бесплатном режиме сервис позволяет построить маршрут из <strong>30 точек</strong>, включая начальную и конечную точку Вашего маршрута. Один маршрут может содержать до 28 промежуточных адресов.
          </p>
        <br />
        <h3>Как работает алгоритм оптимизации</h3>
        <br />
        <p>
          Алгоритм оптимизации маршрута, за счет неоднократного перестроения расстояний между точками и подбора кратчайшего пути, позволяет <strong>сократить расстояние</strong> между точками в среднем <strong>на 30%</strong> (по сравнению с ручным заполнением маршрутного задания) и существенно уменьшить временные затраты на построение маршрутов.
          </p>

        <br />
          <h3>Строим маршруты</h3>
        <p>
          <ul>
            <li>1.	Для легкового автомобиля;</li>
            <li>2.	Для грузового автомобиля;</li>
            <li>3.	С учетом пробок;</li>
            <li>4.	С учетом временных окон;</li>
          </ul>
        </p>
        <p>
          Сервис poncy.su позволяет построить маршрут с учетом интенсивности движения в часы пик и городских автомобильных пробок. Построение маршрута в режиме онлайн, это не только удобная возможность быстрой доставки груза, но и исчерпывающая информация для всех участников логистической цепочки - водителей компании, логиста, руководителя.
        </p>
        <br />
        <p>
          Построить маршрут по точкам с помощью приложения - это удобно, практично, эффективно и выгодно, для любого логиста и водителя.
        </p>
        <br></br>
        </div>}

        {this.state.aboutSwitcher === 'FAQ' && <div>
        <div className={b('FAQ_Header')}
            onClick={() => this._activeAnswer('3')}
          >✓ Используйте голосовой ввод при наборе адреса на смартфоне</div>
          <p className={b('d-none', this.state.activeAnswer ==='3' && b('activeFAQ') )} >
            Для этого необходимо, на мобильном устройстве нажать на поле адреса, чтобы открылась клавиатура. Нажмие на значек микрофона и продиктуйте адрес.
          </p>

          <div className={b('FAQ_Header')}
            onClick={() => this._activeAnswer('1')}
          >✓ Использование GPX файла</div>
          <p className={b('d-none', this.state.activeAnswer ==='1' && b('activeFAQ') )} >
            Для работы с GPX файлами на мобильном устройстве, рекомендуем использовать приложение OsmAnd. Скачть OsmAnd можно из официального Google Play Маркета.
          </p>

          <div className={b('FAQ_Header')}
            onClick={() => this._activeAnswer('2')}
          >✓ Избранный маршрут и как им пользоваться</div>
          <p className={b('d-none', this.state.activeAnswer ==='2' && b('activeFAQ') )} >
            Функционал избранного маршрута, позволяет сохранить один маршрут, чтобы Вы могли загрузить его в любой момент, на любом устройстве.<br /><br />
            К примеру: Вы можете построить маршрут на ПК, а потом отрыть его на своём смартфоне. <br /><br />
            Для этого необходимо на ПК построить маршрут, в нажать кнопку «Сохранить». Потом открыть сервис в браузере мобильного телефона, авторизоваться и нажать на кнопку «Загрузить» сохраненный ранее маршрут.
          </p>
          
          
          
          




        </div>}
      </section>
    );
  }
}