export default {
    isValid(point) {
        return point.coords;
    },

    isGeocoded(point) {
        return point.geocoded === true;
    },

    hasError(point) {
        return this.isGeocoded(point) && !this.isValid(point);
    }
};