import React from 'react'
import Button from '../../../common/components/Button'
import Icons from '../../../base/icons'
import block from 'bem-cn-lite';
import queries from '../../../base/queries';
import { SubmitFormButton } from '../../../common/components';
import I18N from '../../../base/i18n';
import { toast } from 'react-toastify';


const b = block('Controls');

const amocrmURL = `https://amogate.zig-zag.org/amo`

class QiuzAdditionalFunctionality extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      quiz: null,
      NumberQuestions: 0,
      SelectRadioCheck: '',
      theEnd: false,
    }
  }

  componentDidMount() {
    queries.POLL(Number(4))
      .then((res) => {
        //console.log('res', res)
        res.data.result.spec.push({ type: 'endQuestions' })
        this.setState({ quiz: res.data.result.spec })
      })
  }

  _QuizSend = async (quiz) => {

    let answers = []
    quiz.map((row, index) => {
      if (row.answer) {
        let num = Number(row.answer)
        row.option.map((rr, i) => {
          if(i === num) {
            answers.push({
              q: row.title,
              a: rr
            })
          }
        })
      }
    })

    await fetch(amocrmURL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hostName: 'poncy.su',
        email: this.props.actions.user.email,
        phone: this.props.actions.user.phone,
        answers: answers,
      })
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        return Promise.reject(new Error('Error send Quiz'))
      })
  }

  Forward = () => {
    if (this.state.SelectRadioCheck) {
      let ttt = this.state.quiz;
      ttt[this.state.NumberQuestions].answer = this.state.SelectRadioCheck;
      this.state.NumberQuestions < ttt.length - 1 &&
        this.setState({
          NumberQuestions: this.state.NumberQuestions + 1,
          SelectRadioCheck: null,
          Quiz: ttt,
        })
    } else {
      toast.warn(`${I18N.QUIZ_TOAST_WARN}`)
    }
  }

  Back = () => {
    let ttt = this.state.quiz;
    if ((this.state.NumberQuestions > 1) && (ttt[this.state.NumberQuestions - 1].answer === false)) this.setState({ NumberQuestions: this.state.NumberQuestions - 2 })
    else this.state.NumberQuestions > 0 && this.setState({ NumberQuestions: this.state.NumberQuestions - 1 })
  }

  RenderAnswer = (Q, indexQ) => {
    const HandleOptionChange = e => {
      this.setState({ SelectRadioCheck: e.target.value })
    }

    let Num = 1;
    return <div className='Answer'>
      <p>{Q.title}</p>
      {
        Q.option.map((item, i) => (
          <label className="radio">
            <input type="radio"
              value={i}
              checked={this.state.SelectRadioCheck === String(i)}
              onChange={HandleOptionChange}
            />
            <div className="radio__text">{item}</div>
          </label>
        ))
      }
    </div>;
  }

  RenderAnswerMulty = (Q, indexQ) => {
    const HandleOptionChange = e => {
      this.setState({ SelectRadioCheck: e.target.value })
    }

    let Num = 1;
    return <div className='Answer'>
      <p>{Q.title}</p>
      {
        Q.option.map((item, i) => (
          <label className="radio">
            <input type="radio"
              value={i}
              checked={this.state.SelectRadioCheck === String(i)}
              onChange={HandleOptionChange}
            />
            <div className="radio__text">{item}</div>
          </label>
        ))
      }
    </div>;
  }

  EndQuiz = (index) => {
    if (this.state.theEnd === false) {
      this.setState({ theEnd: true })
      this._QuizSend(this.state.quiz)
        .then((res) => {
          // console.log('_post result', res)
        })
        .catch((e) => {
          console.log('Send QiuzAdditionalFunctionality Error', e)
        })
    }
  }


  PreliminaryStage = (Quiz) => {
    // console.log('PreliminaryStage', Quiz);
    let indexQ = this.state.NumberQuestions;
    return <div>
      {
        Quiz[indexQ].type === 'single' && this.RenderAnswer(Quiz[indexQ], indexQ)
      }
      {
        Quiz[indexQ].type === 'multy' && this.RenderAnswerMulty(Quiz[indexQ], indexQ)
      }
      {
        Quiz[indexQ].type === 'endQuestions' && this.EndQuiz(Quiz[indexQ])
      }

      <div className='QuizBtnGroup'>
        {!this.state.theEnd ? <>
          {indexQ > 0 &&
            <SubmitFormButton
              onClick={this.Back}
            >{I18N.SUBMIT_BACK}
            </SubmitFormButton>
          }

          <SubmitFormButton
            onClick={this.Forward}
          >{I18N.SUBMIT_FORWARD}
          </SubmitFormButton>
        </>
          :
          <div className="endWindow">
            <p>{ I18N.QUIZ_REQUEST_SEND }</p>
            <div className="endWindowBtn">
              <Button
                className={b('')}
                icon={Icons.FEEDBACK}
                onClick={this.props.closeModal}
              >{ I18N.CLOSE }
            </Button>
            </div>
          </div>
        }
      </div>
    </div>
  }

  render() {
    return <div className="FirstQuiz">
      {/* {console.log('Info', this.state.quiz)} */}
      {
        this.state.quiz ? this.PreliminaryStage(this.state.quiz) : `Загрузка`
      }
    </div>
  }
}
export default QiuzAdditionalFunctionality;
