export default 
class WaybillPoint {
  constructor(p) {
    this.id = p.id;
    this.departureLoad = p.departure_load;
    this.departureVolume = p.departure_volume;
    this.arrivalTime = p.arrival_time;
    this.departureTime = p.departure_time;
    this.routeCode = p.route_code;
    this.errorCode = p.error_code;
    this.totalDistanceKm = p.total_distance_km;
    this.totalPoints = p.total_points;
    this.weight = p.weight;
    this.cost = p.cost;
    this.geometry = p.geometry;
    this.agent = p.courier.id ? p.courier.id % 10 : null;
    this.pointId = p.point_id;
    this.courierId = p.courier.id;
    this.comment = p.driver_comment;
    this.status = p.status;
    Object.freeze(this);
  }
}