import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import ReactTooltip from 'react-tooltip';

const b = block('Input');

export default
class CheckboxInput extends React.PureComponent {
  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.bool,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.fromView = (val) => Boolean(Number(val));
  }

  _onChange = (e) => {
    this.props.onChangeValue && this.props.onChangeValue(e.target.name, e.target.checked);
  }

  render() {
    return (
      <div className={b('Checkbox')} data-tip={this.props.toolTip}>
        {this.props.toolTip && <ReactTooltip /> }
        <div className={b('CheckboxInput')}>
          <div className={b('CheckboxMarker', {'Enabled': this.props.value})}/>
        </div>
        <input
          type='checkbox'
          className={b('CheckboxHidden')}
          defaultChecked={this.props.value}
          id={this.props.name}
          name={this.props.name}
          onChange={this._onChange}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}
