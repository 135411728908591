import React from 'react';
import block from 'bem-cn-lite';
import queries from '../../base/queries';
import Icons from '../../base/icons';
import { Icon, TextInput, TimeInput, Button, PasswordInput, SubmitFormButton } from '../../common/components';
import I18N from '../../base/i18n'
import { toast } from 'react-toastify';

const b = block('Controls');

export default
  class PasswordRestoreForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      failureCode: null,
      password: '',
      confirmPassword: '',
      processing: false,
    }
  }

  _setProcessing = (yes) => {
    this.setState({
      processing: yes
    });
  };

  btnClick = () => {
    if (!this.state.password || this.state.password !== this.state.confirmPassword)
      return this.setState({ failureCode: 'C001' });

    this._setProcessing(true);
    queries.UPDATE_USER_PASSWD(this.state.password, this.props.user.id)
      .then((res) => {
        toast.success(I18N.PASSWORD_CHANGE_SUCCESS);
        this.props.ChangeTopSwitcherMode('route');
        this.props.logoutUser();

      })
      .finally(() => this._setProcessing(false))
  }

  handelChangePass = (name, value) => {
    this.setState({
      [name]: value,
      failureCode: null,
    })
  }

  render() {
    return <div>
      <h2>{I18N.ENTER_NEW_PASSWD}</h2>
      <div className={b('Wrapper')}>
        <PasswordInput
          type="password"
          autoComplete={'off'}
          className="Input"
          placeHolder={I18N.USER_PASSWORD}
          name={'password'}
          value={this.state.password}
          onChangeValue={this.handelChangePass}
        />
      </div>
      <div className={b('Wrapper')}>
        <PasswordInput
          type="password"
          autoComplete={'off'}
          className="Input"
          placeHolder={I18N.USER_CONFIRM_PASSWORD}
          name={'confirmPassword'}
          value={this.state.confirmPassword}
          onChangeValue={this.handelChangePass}
        />
      </div>
      {this.state.failureCode && <div className={b('Validation')}>{I18N[`ERROR_${this.state.failureCode}`]}</div>}
      <div className={b('Wrapper')}>
        <SubmitFormButton
          icon={Icons.CALCULATION_RUN}
          className={b('Button')}
          Disabled={this.submitDisabled}
          onClick={this.btnClick}
          inProgress={this.state.processing}>
          { I18N.PASSWORD_CHANGE_REQUEST }</SubmitFormButton>
      </div>
      {this.state.passwdValue}
    </div>
  }
}