import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

import I18N from 'base/i18n';
import Icons from 'base/icons';
import { Button } from 'common/components';
import WaybillPoint from '../../../common/helpers/WaybillPoint';
import ManifestStatistics from '../../../common/helpers/ManifestStatistics';
import AddressPoint from '../../../common/helpers/AddressPoint';
import xlsHelper from '../../../common/helpers/generateXLS';

import { yandexEvent } from '../../others/';
import { actions } from "../../../actions";
import GpxBuilder from "../helpers/GpxBuilder";
import { decodeDateRU } from "../../../common/helpers/misc";
import { isMobileDevice } from '../../../common/helpers/misc'

const b = block('ControlsResults');

export default
  class ControlsResults extends React.Component {
  static propTypes = {
    points: PropTypes.objectOf(PropTypes.instanceOf(AddressPoint).isRequired).isRequired,
    waybillArray: PropTypes.arrayOf(PropTypes.instanceOf(WaybillPoint).isRequired).isRequired,
    statistics: PropTypes.instanceOf(ManifestStatistics).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      navigatorUrl: this._buildNavigatorUrl(this.props),
    };
  }

  componentDidMount() {
    this.updateWaybillEvent = actions.updateWaybillData.subscribe(action => {
      this._setNavigatorUrl(this._buildNavigatorUrl(action));
    });
    this.resetAddressesEvent = actions.resetAddresses.subscribe(() => {
      this._setNavigatorUrl(null);
    });
  }

  componentWillUnmount() {
    this.updateWaybillEvent.unsubscribe();
    this.resetAddressesEvent.unsubscribe();
  }

  _generateReport() {
    xlsHelper.generateXLS(this.props.points, this.props.agents, this.props.waybillArray, this.props.statistics)
      .then(() => toast.success(`${I18N.CONTROLS_REPORT_DOWNLOAD}. ${I18N.NOTIFY_SUCCESS}`))
      .catch(() => toast.error(`${I18N.CONTROLS_REPORT_DOWNLOAD}. ${I18N.NOTIFY_ERROR}`));
    yandexEvent('routelist', '');
  }

  _printReport() {
    xlsHelper.printXLS(this.props.points, this.props.agents, this.props.waybillArray, this.props.statistics);
  }

  _buildNavigatorUrl(context) {
    if (!context) {
      context = this;
    }

    if (context.waybillArray.length === 0) {
      return null;
    }

    let urlQuery = [];

    const buildPoint = (id, latName, lonName) => {
      const point = context.points[id];

      if (!point) {
        return null;
      }

      return point.coords ? latName + '=' + point.coords[0] + '&' + lonName + '=' + point.coords[1] : null;
    };

    let viaNum = 0;
    context.waybillArray.forEach((value, idx) => {
      if (idx === 0) {
        urlQuery.push(buildPoint(value.id, 'lat_from', 'lon_from'));
      } else if (idx === context.waybillArray.length - 1) {
        urlQuery.push(buildPoint(value.id, 'lat_to', 'lon_to'));
      } else {
        urlQuery.push(buildPoint(value.id, 'lat_via_' + viaNum, 'lon_via_' + viaNum));
        viaNum++;
      }
    });

    return 'yandexnavi://build_route_on_map?' + urlQuery.join('&');
  }

  _setNavigatorUrl(url) {
    this.setState(state => ({
      ...state,
      navigatorUrl: url
    }));
  }

  _openNavigator() {
    window.location.href = this.state.navigatorUrl;
  };

  _downloadGpx() {
    const points = [];
    for (let k in this.props.waybillArray) {
      const point = this.props.waybillArray[k];
      if (this.props.points[point.id]) {
        points.push(this.props.points[point.id]);
      }
    }

    GpxBuilder.download(points, 'marshrut_' + decodeDateRU(new Date(), '_') + '.gpx');
  }

  render() {
    return <div className={b()}>
      <h2>{I18N.STATS_TITLE}</h2>
      <div className={b('Statistics')}>
        <span className={b('Param')}>{this.props.user && this.props.user.distance === 'miles' ? I18N.STATS_DISTANCE_MI : I18N.STATS_DISTANCE}: <b>
          {this.props.user && this.props.user.distance === 'miles'
            ? this.props.statistics.totalDistanceMi
            : this.props.statistics.totalDistance
          }
        </b></span>
        <span className={b('Param')}>
          {I18N.STATS_TIME}: <b>{this.props.statistics.totalTime}</b>
        </span>
        <span className={b('Param')}>
          {I18N.STATS_POINTS}: <b>{Object.keys(this.props.points).length}</b>
        </span>
      </div>

      {/* <div className={b('Descriprion')}>
        {I18N.CONTROLS_REPORT_DESCRIPTION}
      </div> */}

      <div className={b('RouteControls')}>
        <Button
          icon={Icons.CALCULATION_DOWNLOAD}
          onClick={this._generateReport.bind(this)}
        >{I18N.CONTROLS_REPORT_DOWNLOAD}</Button>
        <Button
          onClick={this._downloadGpx.bind(this)}
          icon={Icons.CALCULATION_DOWNLOAD}
        >{I18N.CONTROLS_SAVE_GPX}</Button>
        <Button
          icon={Icons.CALCULATION_PRINT}
          onClick={this._printReport.bind(this)}
          toolTip={I18N.CONTROLS_REPORT_PRINT}
        />
      </div>

      {this.state.navigatorUrl
        ? <div className={b('RouteControls', { 'TopMargin': true })}>
          <Button
            onClick={this._openNavigator.bind(this)}
            icon={Icons.NAVIGATE}
            disabled={!isMobileDevice()}
            toolTip={!isMobileDevice() ? I18N.CONTROLS_YANDEXNAVI_TOOLTIP : null}
          >{I18N.CONTROLS_YANDEXNAVI_OPEN}</Button>
        </div>
        : ''
      }

      {/* {this.state.navigatorUrl
        ? <div className={b('RouteControls', { 'TopMargin': true })}>
          <Button
            className={b('NavigateButton')}
            onClick={this._downloadGpx.bind(this)}
            icon={Icons.CALCULATION_DOWNLOAD}
          >{I18N.CONTROLS_SAVE_GPX}</Button>
        </div>
        : ''
      } */}
    </div>;
  }
}
