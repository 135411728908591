import React from 'react'

export default
class ToggleSwitch extends React.PureComponent {

    render() {
        return(
            <div
                className={!this.props.ToggleSwitchChecked ? 'switch-btn' : 'switch-btn switch-on'}
                onClick={this.props.onChange}
            >
            </div>
        );
    }
}