import React, { Component } from "react";
import { Grow, Paper, Modal, IconButton, Button } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { random_banners } from "./../helpers/banners";
// Components
import BannerPhoneInput from "./BannerPhoneInput";

// компонент орисовки баннеров
export default class BannerWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      show: false,
      open: false,
      phoneShow: false,
      formid: "",
      data: "",
      dispBanner: "flex"
    };
  }

  componentDidMount() {
    let t = this;
    let banners = random_banners().slice(3);
    setTimeout(() => {
      t.setState({
        banners: banners,
        show: true,
      });
    }, 1000);
  }

  handleRemoveBanner = (id) => {
    let prevArr = this.state.banners;
    let newBanners = prevArr.filter((item, index) => item !== prevArr[id]);
    this.setState({ banners: newBanners });
    if (newBanners.length < 1) {
      this.setState({ dispBanner: "none" })
    };
  };

  handleTextBanner = (text) => {
    this.setState({ data: text });
  }

  render() {
    return (
      <div
        className="banner-window"
        style={{ margin: "auto", left: "0px", justifyContent: "center", zIndex: 900, display: this.state.dispBanner, height: "90%" }}
      >
        {this.state.banners.map((b, id) => {
          return (
            <Growblock
              inputid={id + 1}
              id={id}
              key={id}
              show={this.state.show}
              timeout={(id + 1) * 1000}
              data={b}
              handleClose={() => this.setState({ open: !this.state.open })}
              action={this.handleRemoveBanner}
              setId={(value) => this.setState({ formid: value })}
              handleTextBanner={this.handleTextBanner}
            />
          );
        })}
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            verticalAlign: "center",
            alignItems: "center",
            overflowY: "auto",
          }}
          open={this.state.phoneShow}
        >
          <BannerPhoneInput
            // token={this.props.token}
            // user={this.props.user}
            action={() => this.setState({ phoneShow: !this.state.phoneShow })}
            formid={this.state.formid}
          />
        </Modal>
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: !this.state.open })}
          style={{
            display: "flex",
            justifyContent: "center",
            verticalAlign: "center",
            alignItems: "center",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              width: 800,
              margin: "auto",
              left: "50%",
              top: "42%",
              backgroundColor: "#f3f6f4",
              padding: 30,
              transform: "translate(" - (50 % ",") - (50 % ")"),
            }}
          >
            <div style={{ position: "relative" }}>
              <div
                dangerouslySetInnerHTML={{ __html: this.state.data }}
              />
              <IconButton
                style={{ position: "absolute", top: "-26px", right: "-22px" }}
                onClick={() => this.setState({ open: !this.state.open })}
              >
                <CancelIcon />
              </IconButton>
            </div>
            <div style={{ textAlign: "center", paddingTop: "15px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  this.setState({
                    phoneShow: !this.state.phoneShow,
                    open: !this.state.open,
                  })
                }
              >
                Попробовать
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const sendBannerMetrica = (id) => {
  fetch(
    `https://api.telegram.org/bot5677699875:AAG06BRBFfCf-yHZ4E6nOS1VkDPvGrdH9Es/sendMessage?chat_id=-851386537&text=${"Клик по банеру " + id
    }`
  );
};
// единичный баннер
function Growblock(props) {
  return (
    <Grow
      in={props.show}
      style={{ transformOrigin: "0 0 0" }}
      {...(props.show ? { timeout: props.timeout } : {})}
    >
      <Paper elevation={6} square={true} style={{ position: "relative" }}>
        <IconButton
          style={{ position: "absolute", top: "-8px", right: "-12px" }}
          onClick={(e) => props.action(props.id)}
        >
          <CancelIcon />
        </IconButton>
        <p>
          <b>{props.data.to}</b>
        </p>
        <p>{props.data.desc}</p>
        <p>
          <a
            // href={props.data.link}
            // title={props.data.link}
            rel="noopener noreferrer"
            target="_blank"
            // onClick={() => this.setState({ open: !this.state.open })}
            onClick={() => {
              props.handleTextBanner(props.data.text)
              sendBannerMetrica(props.data.desc);
              props.setId(`banner ${props.id + 1}`);
              props.handleClose();
            }}
          >
            Подробнее...
          </a>
        </p>
      </Paper>
    </Grow>
  );
}

// Text to html
// function textToHTML (text) {
// 	const splitedText = text.split('\n');
// 	let htmlText = ``;

// 	let oldElemType = "NOEL";
// 	let curElemType = "NOEL";
// 	for (const line of splitedText) {
// 		if (line.startsWith('** ')) {
// 			// Выделяем жирным линию
// 			oldElemType = curElemType;
// 			curElemType = "BOLD";
// 			htmlText += getCloser(curElemType, oldElemType);

// 			htmlText += `<strong class="onetow">${line.slice(3)}</strong> <br />`
// 		}
// 		else if (line.startsWith('* ')) {
// 			// Выделяем жирным линию
// 			oldElemType = curElemType;
// 			curElemType = "BOLD";
// 			htmlText += getCloser(curElemType, oldElemType);

// 			htmlText += `<strong>${line.slice(2)}</strong><br />`
// 		}
// 		else if (line.startsWith('- ')) {
// 			// Деалаем список без нумерации
// 			oldElemType = curElemType;
// 			curElemType = "UL";
// 			htmlText += getCloser(curElemType, oldElemType);

// 			const lineWords = line.slice(2).split(' ');
// 			htmlText += `\n  <li><span style="padding-left: 15px;">${lineWords[0]}</span> ${lineWords.slice(1).join(' ')}</li>` 
// 		}
// 		else if (line.startsWith('1 ')) {
// 			// Дулаем нумированный список
// 			oldElemType = curElemType;
// 			curElemType = "OL";
// 			htmlText += getCloser(curElemType, oldElemType);

// 			const lineWords = line.slice(2).split(' ');
// 			htmlText += `\n  <li><span style="padding-left: 15px;">${lineWords[0]}</span> ${lineWords.slice(1).join(' ')}</li>` 
// 		}
// 		else {
// 			// Иначе просто отступ
// 			oldElemType = curElemType;
// 			curElemType = "TEXT";
// 			htmlText += getCloser(curElemType, oldElemType);

// 			const lineWords = line.split(' ');
// 			htmlText += `\n    <span style="padding-left: 15px;">${lineWords[0]}</span> ${lineWords.slice(1).join(' ')}<br />`
// 		}
// 	}

// 	return htmlText;
// }

// function getCloser(newElemType, oldElemType) {
// 	const newTag = {
// 		"OL": "\n<ol style='padding-left: 25px;''>\n",
// 		"UL": "\n<ul>\n",
// 		"BOLD": "",
// 		"TEXT": "",
//     "NOEL": "",
// 	};
// 	const oldTag = {
// 		"OL": "\n</ol>\n",
// 		"UL": "\n</ul>\n",
// 		"BOLD": "",
// 		"TEXT": "",
//     "NOEL": "",
// 	};
// 	if (newElemType !== oldElemType) {
// 		return `${oldTag[oldElemType]}${newTag[newElemType]}`
// 	}
// 	return ''
// }