import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

const b = block('Input');

const DivInput = ({children, onChangeValue}) => <div
    onInput={(e) => onChangeValue(e.target)}
    contentEditable
    className={b('DivInput')}
  >
    {children}
  </div>;

DivInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChangeValue: PropTypes.func,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
}

export default DivInput;