import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import I18N from '../../base/i18n';
import TextLink from './../../common/components/TextLink';
import LoginForm from './LoginForm';
import { reducer, actions } from '../../actions';

const b = block('AccountBadge');

class AccountBadge extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    token: PropTypes.string,
    user: PropTypes.object,
  }

  exitHandler = () => {
    this.props.actions.setNavBarStatus('route')
    this.props.actions.logoutUser()
  }

  render() {
    return this.props.user
      ? <div className={b()}>
          <span className={b('Name')}>
            {this.props.user.name || this.props.user.email}
          </span>
          <span className={b('Email')}>
            <TextLink onClick={()=> this.exitHandler()}>
              {I18N.LOGIN_LOGOUT}
            </TextLink>
          </span>
        </div>
      : <div>
        <TextLink
          onClick={() => this.props.actions.showModal(LoginForm, {
            loginFormWindow: 'login',
          })}
        >{I18N.LOGIN_LOGIN} </TextLink>
         / 
        <TextLink
          onClick={() => this.props.actions.showModal(LoginForm, {
            loginFormWindow: 'singup',
          })}
        > {I18N.LOGIN_SIGNUP}</TextLink>
      </div>;
  }
}

export default
connect(
  (state) => ({
    user: state[reducer.name].user,
    token: state[reducer.name].token
  }),
  (dispatch) => ({
    "actions": bindActionCreators(actions, dispatch)
  })
)(AccountBadge);