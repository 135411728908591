export const VALIDATE = {
  notEmpty: (val) => val && String(val).length > 0,
  email: (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && re.test(String(email).toLowerCase());
  },
  pass: (pass) => {
    const re = /^[a-zA-Z0-9]{6,}$/;
    return pass !== null && re.test(String(pass));
  },
  phone: (phone) => {
    const re = /^[0-9]{5,}$/;
    return phone && re.test(String(phone));
  },
  ruPhone: (phone) => {
    const re =
      /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm;
    return phone && re.test(phone);
  },
};
