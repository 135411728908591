import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { reducer, actions } from '../../actions';

import I18N from 'base/i18n';
import { ENDPOINTS } from 'base/api';
import { SubmitFormButton, Button, TextInput } from 'common/components';
import { TextareaMassage } from 'common/components';


const b = block('FeedBack');



class FeedBack extends React.PureComponent {
    static propTypes = {
        actions: PropTypes.object,
        closeModal: PropTypes.func,
        token: PropTypes.string,
        formid: PropTypes.string,
        user: PropTypes.object,
      }

    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email,
            phone: '',
            FeedBackMessage: '',
            formid: this.props.formid,
            token: this.props.token,
            processing: false
        };
      }

    _setProcessing = (yes) => {
        this.setState({
            processing: yes
        });
    };

      _sendMassege = () => {
        if ( (!this.state.phone) || (this.state.FeedBackMessage.length < 8) ) {
          // Ограничение на отправку сообщения меньше 8 символов. 
            return this.setState({ failureCode: 'C005' });
        }
      

        this._setProcessing(true);
        fetch(
            ENDPOINTS.graphql, {
              method: 'POST',
              headers: {
                'Authorization': this.state.token,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',

              },
              body: JSON.stringify({
                operationName: "FEEDBACK",
                variables: {
                    formid: this.state.formid,
                    email: this.state.email,
                    phone: this.state.phone,
                    message: this.state.FeedBackMessage,
                }
              }),
            })
            .then((res) => {
              if (!res)
                return this.setState({ failureCode: 'C004', });
                return res.json();
            })
            .then((res) => {
                if (!res.result) return this.setState({ failureCode: res.code });
                toast.success(`${I18N.FEEDBACK_SUCCESS}`);
                this.props.closeModal();
              })
            .finally(() => this._setProcessing(false));
      }
      
      _onInputChange = (name, value) => {
        this.setState({
          [name]: value,
          failureCode: null,
        });
      }


    render() {
        return (
         <div className={b()}>
             {this.state.failureCode && <div className={b('Validation')}>{I18N[`ERROR_${this.state.failureCode}`]}</div>}
                <div className={b('Input')}>
                    <TextInput className={b('Input')}
                        autoComplete={'off'}
                        placeHolder={I18N.USER_PHONE}
                        name='phone'
                        value={this.state.phone}
                        onChangeValue={this._onInputChange}
                    />
                </div>
                <TextareaMassage
                    placeHolder={I18N.FEEDBACK_TEXTAREA_PLACEHOLDER}
                    value={this.state.FeedBackMessage}
                    name='FeedBackMessage'
                    onChangeValue={this._onInputChange}
                />
                <div className={b('Submit')}>
                    <SubmitFormButton onClick={this._sendMassege} inProgress={this.state.processing}>
                        {I18N.SUBMIT_SEND}
                    </SubmitFormButton>
                </div>
            </div>
        );
    }
}

export default
connect(
    (state) => ({
        user: state[reducer.name].user,
        token: state[reducer.name].token,
      }),
  (dispatch) => ({
    "actions": bindActionCreators(actions, dispatch)
  })
)(FeedBack);

