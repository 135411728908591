export const I18N_ru = {
  _lang: 'ru',
  _locale: 'ru-RU',

  SUBMIT_DEFAULT: 'Готово',
  SUBMIT_SAVE: 'Сохранить',
  SUBMIT_CREATE: 'Создать',
  SUBMIT_SEND: 'Отправить',
  SUBMIT_CANCEL: 'Отмена',
  SUBMIT_CONFIRM: 'Подтвердить',
  SUBMIT_FEEDBACK: 'Обратная связь',
  SUBMIT_FEEDBACK_BOTTOM: 'Отзыв или предложение',
  SUBMIT_PROGRESS: 'Отправка...',
  SUBMIT_FORWARD: 'Вперед',
  SUBMIT_BACK: 'Назад',
  SUBMIT_DOWNLOAD_POINTS: 'Загрузить введенные адреса',
  SUBMIT_LOAD_EXCEL_POINTS: 'Точки из Excel',
  SUBMIT_DOWNLOAD_FILE_TEMPLATE: 'Скачать шаблон',

  CLOSE: 'Закрыть',
  CONTROLS_NAV_ROUTE: 'Маршрут',
  CONTROLS_NAV_PERSONAL_AREA: 'Личный кабинет',
  CONTROLS_NAV_ABOUT_SERVICE: 'О сервисе',
  CONTROLS_HEADER_ADDRESS: 'Адреса',
  CONTROLS_HEADER_COURIERS: 'Курьеры',
  CONTROLS_SWITCHER_MANUAL: 'По одному',
  CONTROLS_SWITCHER_TEXT: 'Списком',
  CONTROLS_SWITCHER_FILE: 'Из файла',
  CONTROLS_HEADER_CONDITIONS: 'Задайте условия',
  CONTROLS_HEADER_CALCULATION: 'Рассчитайте маршрут',
  CONTROLS_TEXT_EDITOR_PLACEHOLDER: 'Введите адреса по одному в строку',
  CONTROLS_AGENT_LIGHT: 'Легковой автомобиль', 
  CONTROLS_AGENT_HEAVY: 'Грузовой автомобиль', 
  CONTROLS_AGENT_MAN: 'Пеший курьер',
  CONTROLS_AGENT_WORKTIME: 'Время работы курьера',
  CONTROLS_AGENT_PHONE_TIP: 'Номер телефона необходим для передачи маршрута курьеру в Telegram боте',
  CONTROLS_AGENT_TELERGAM_TIP: 'Бот Poncy для курьера',
  CONTROLS_JAMS_ON: 'Учесть пробки', 
  CONTROLS_JAMS_OFF: 'Без пробок', 
  CONTROLS_ADDRESS_ADD: 'Адрес доставки', 
  CONTROLS_ADDRESS_LIMIT_EXCEEDED: 'Вы достигли лимита бесплатных адресов. Если вам интересно построение маршрута на большее количество адресов, пожалуйста напишите нам.',
  CONTROLS_ADDRESS_LIMIT_EXCEEDED_NOREGISTRATION: 'Вы достигли лимита адресов без регистрации. Пожалуйста зарегистрируйтесь или войдите в аккаунт чтобы добавить больше точек.',
  CONTROLS_RESET_WORKING_TIME_DESCRIPTION: 'Сбросить время работы точки',
  CONTROLS_HEADER_TEXT_EDITOR: 'Ввидите каждый адрес с новой строки',
  CONTROLS_NOT_RETURN_DESCRIPTION: 'Возвращаться на базу',
  CONTROLS_RETURN_DESCRIPTION: 'Не возвращаться на базу',

  CONTROLS_RUN: 'Построить', 
  CONTROLS_RUN_LIMIT_EXCEEDED: 'Вы достигли лимита рассчётов без регистрации. Пожалуйста зарегистрируйтесь или войдите в аккаунт чтобы рассчитать маршрут.',
  CONTROLS_DEL: 'Очистить',
  CONTROLS_YANDEXNAVI_OPEN: 'Яндекс.навигатор',
  CONTROLS_YANDEXNAVI_TOOLTIP: 'Работает на мобильном устройстве при установленном приложении',
  CONTROLS_SAVE_GPX: 'GPX-файл',
  CONTROLS_RUN_SUUCCESS: 'Маршрут построен успешно',
  CONTROLS_POINT_WORKTIME: 'Время работы точки (с / до), время на точке (минуты)', 
  CONTROLS_CALCULATING: 'Сейчас рассчитаем...', 
  CONTROLS_ALERT_CLEAR_ADDRESSES: 'Внимание! Удалить все адреса и результаты расчетов?',
  CONTROLS_ALERT_RUN_TASK: 'Построить оптимальный маршрут?',
  CONTROLS_ALERT_DOWNLOAD_POINTS: 'Подтвердите загрузку новых адресов',
  CONTROLS_REPORT_DOWNLOAD: 'Маршрутный лист',
  CONTROLS_REPORT_PRINT: 'Отправить на печать',
  CONTROLS_REPORT_DESCRIPTION: 'Результаты рассчитываются с учетом времени погрузки/разгрузки на каждой точке 15 минут. Время выезда курьера считается временем начала работы первой точки, по умолчанию задано 8:00.',
  CONTROLS_REMOVE_ALL: 'Очистить данные',
  CONTROLS_COPYRIGHT: 'Права защищены © 2020',
  CONTROLS_ADRESS_HINT: '*После загрузки проверьте правильность геокодирования каждого адреса',

  GPX_BUILDER_NAME: 'Маршрут от ',

  LOGIN_AUTORIZATION: 'Авторизация',
  LOGIN_LOGIN: 'Вход',
  LOGIN_LOGOUT: 'Выйти',
  LOGIN_SIGNUP: 'Регистрация',
  LOGIN_SIGNUP_INFO: 'Добро пожаловать! Вы можете БЕСПЛАТНО пользоваться нашим сервисом после быстрой регистрации.',
  LOGIN_RESTORE_PASSWORD: 'Восстановить пароль',
  LOGIN_WRONG_CREDENTIALS: 'Неверное имя пользователя или пароль',
  LOGIN_SIGNUP_FAILURE: 'Ошибка регистрации, проверьте введенные данные',
  LOGIN_AGREEMENT: 'Я даю согласие на обработку моих персональных данных',

  MAP_HEADER_TITLE: 'Построение оптимального (короткого, быстрого) маршрута по нескольким точкам, планирование пути и расчет расстояний',
  MAP_HEADER_H1: 'Построение оптимального маршрута по нескольким точкам',

  USER_NAME: 'Логин',
  USER_PHONE: 'Телефон',
  USER_PASSWORD: 'Пароль',
  USER_CONFIRM_PASSWORD: 'Подтвердите пароль',
  USER_EMAIL: 'E-mail',

  POINT_COMMENT: 'Комментарий к заказу',

  COURIER_PHONE: 'Телефон',
  COURIER_NAMING: 'Имя',
  COURIER_MAX_POINTS: 'Макс. адресов',
  COURIER_ADD: 'Добавить курьера',
  COURIER_DELETE: 'Удалить курьера',

  NOTIFY_ERROR: 'Произошла ошибка.',
  NOTIFY_SUCCESS: 'Успешно.',
  NOTIFY_DELETE: 'Удаление элемента.',
  NOTIFY_IMPORT_POINTS_TEMPLATE_ERROR: 'Файл поврежден или шаблон заполнен некорректно.',

  MAP_MARKER_START: 'Начальная точка маршрута',
  MAP_MARKER_FINISH: 'Конечная точка маршрута',
  MAP_MENU_USE_AS_START: 'Начальная точка',
  MAP_MENU_USE_AS_INTERMEDIATE: 'Добавить точку доставки',
  MAP_MENU_USE_AS_FINISH: 'Конечная точка',

  STATS_TITLE: "Результаты",
  STATS_DISTANCE: 'Расстояние (км)',
  STATS_DISTANCE_MI: 'Расстояние (мили)',
  STATS_TIME: 'Время в пути',
  STATS_POINTS: 'Адресов',

  PERSONAL_AREA_KM: 'Километры',
  PERSONAL_AREA_M: 'Мили',
  PERSONAL_AREA_24: '24 часа',
  PERSONAL_AREA_12: 'AM/PM',
  PERSONAL_AREA_DELETE_MANIFEST_TIP: 'Удалить маршрут',
  PERSONAL_AREA_DELETE_MANIFEST_ALERT: 'Удалить маршрут "%%"? Восстановить его уже будет невозможно.',
  PERSONAL_AREA_LOAD_MANIFEST_TIP: 'Загрузить маршрут',
  PERSONAL_AREA_LOAD_MANIFEST_ALERT: 'Загрузить маршрут "%%"? Текущий маршрут будет удалён.',

  REPORT_PRINT_HEADER: 'Маршрутный лист',
  REPORT_STAT_DATE: 'Дата',
  REPORT_STAT_POINTS: 'Всего точек',
  REPORT_STAT_DISTANCE: 'Длина пути (км.)',
  REPORT_STAT_TIME: 'Время в пути',
  REPORT_SHEET_NAME: 'Маршрут',
  REPORT_ORDER: 'Номер',
  REPORT_FIELD_ADDRESS: 'Адрес',
  REPORT_FIELD_WORK_START: 'Начало работы',
  REPORT_FIELD_WORK_END: 'Конец работы',
  REPORT_ARRIVAL: 'Время прибытия',
  REPORT_DEPARTURE: 'Время отбытия',
  REPORT_TOTAL_DISTANCE: 'Общий путь (км.)',
  REPORT_POINT_COMMENT: "Комментарий к точке",
  REPORT_COURIER_COMMENT: "Комментарий от курьера",
  REPORT_POINT_STATUS: "Статус выполнения",
  REPORT_POINT_STATUS_0: "Нет статуса",
  REPORT_POINT_STATUS_1: "Выполняется",
  REPORT_POINT_STATUS_2: "Выполнена",
  REPORT_POINT_STATUS_5: "Отмена",
  REPORT_COURIER_NAME: "Имя",
  REPORT_COURIER_PHONE: "Телефон",
  REPORT_COURIER_WORK_START: "Начало смены курьера",
  REPORT_COURIER_WORK_END: "Окончание смены курьера",

  FAVORITE_ROUTE_TITLE: 'Избранные маршруты',
  FAVORITE_ROUTE_LOAD_MANIFEST_SUCCESS: 'Маршрут загружен',
  FAVORITE_ROUTE_LOAD_MANIFEST_ERROR: 'Не удалось загрузить маршрут',
  FAVORITE_ROUTE_LOAD_MANIFESTS_ERROR: 'Не удалось получить сохранённые маршруты',
  FAVORITE_ROUTE_DELETE_MANIFEST_SUCCESS: 'Маршрут удалён',
  FAVORITE_ROUTE_DELETE_MANIFEST_ERROR: 'Ошибка при удалении маршрута',
  FAVORITE_ROUTE_NO_MANIFEST: 'Нет сохраннёных маршрутов',
  FAVORITE_ROUTE_ADD_MANIFEST_SUCCESS: 'Маршрут добавлен в личный кабинет.',
  FAVORITE_ROUTE_ADD_MANIFEST_ERROR: 'Нет маршрутов для сохранения. Сначала расчитайте маршрут',
  FAVORITE_ROUTE_SAVE_CONFIRM: 'Сохранить текущий маршрут?',
  FAVORITE_ROUTE_SAVE_MANIFEST: 'Сохранить',

  FILE_TEMPLATE_NAME: 'Шаблон загрузки точек',
  FILE_POINT_NAME: 'Наименование точки',
  FILE_POINT_ADDRESS: 'Адрес',
  FILE_POINT_LAT: 'Широта',
  FILE_POINT_LON: 'Долгота',
  FILE_POINT_WORK_START: 'Начало работы',
  FILE_POINT_WORK_END: 'Окончание работы',
  FILE_POINT_SHIPPING_TIME: 'Время обслуживания',
  FILE_POINT_COMMENT: 'Комментарий',

  ERROR: "Ошибка",
  // Ошибки очереди задач
  ERROR_2000: "Внутренняя ошибка очереди задач",
  ERROR_2001: "Роутер не ответил",
  ERROR_2002: "Ошибка роутера: %message%",
  ERROR_2003: "Маршрут не содержит курьеров",
  ERROR_2004: "Маршрут не содержит точки",

  // Ошибки внутри клиента
  ERROR_C001: "Пароли должны совпадать",
  ERROR_C002: "Сервер не отвечает, пожалуйста свяжитесь с менеджером",
  ERROR_C003: 'Неверное имя пользователя или пароль',
  ERROR_C004: 'Ошибка регистрации, проверьте введенные данные',
  ERROR_C005: 'Заполните все поля',
  ERROR_C006: 'Регистрация прошла успешно. Скоро с Вами свяжется наш менеджер, чтобы провести ознакомление с возможностями сервиса. Пользователь еще не активен.',
  ERROR_C007: 'Для построения маршрута пройдите регистрацию',
  ERROR_C008: 'Вы не авторизованы.',
  ERROR_C009: 'Запуск расчета невозможен.',
  ERROR_C010: 'Ошибка в рассчете маршрута. Проверьте данные.',
  ERROR_C011: 'Адрес не найден! Введите корректный адрес.',
  ERROR_C012: 'Не удалось определить адрес по координатам.',
  ERROR_C013: 'Не все введенные адреса найдены, проверьте список адресов',
  ERROR_C014: 'Введен некорректный email',

  // Ошибки сервера приложений
  ERROR_1000: "Внутренняя ошибка сервера приложений",
  ERROR_1001: "Пользователь не найден или неверный пароль.",
  ERROR_1001A: "Пользователь с таким email не найден.",
  ERROR_1002: "Email не найден",
  ERROR_1003: "Пароль не найден",
  ERROR_1004: "Неизвестный формат данных: %format%, требуется \"json\" или \"xml\"",
  ERROR_1005: "Незвестная операция: %operation%",
  ERROR_1006: "Нет прав доступа к текущему манифесту",
  ERROR_1007: "Нет прав доступа к текущему каталогу",
  ERROR_1008: "Некоректный или недостающий параеметр: %param_name%",
  ERROR_1009: "Множественные ошибки сервера приложений", // {errors: [error]}
  ERROR_1010: "Сущность %id% не найдена",
  ERROR_1011: "Запрошенная задача не выполнена",
  ERROR_1012: "Маршрут уже находится в процессе рассчета",
  ERROR_1013: "Запрещенная операция",
  ERROR_1014: "Данный Email уже зарегистрирован",
  ERROR_1015: "Укажите номер телефона",
  ERROR_1016: "Ошибка формарирования отчета",
  ERROR_1017: "Пользователь заблокирован, пожалуйста, свяжитесь с менеджером",
  ERROR_1018: "Данный номер телефона уже зарегистрирован",

  // Ошибки решателя
  ERROR_3000: "Внутренняя ошибка рассчетного модуля",
  ERROR_3001: "Неизвестная спецификация",
  ERROR_3002: "Неизвестная задача",
  ERROR_3003: "Точки %point_id% не попала в кластер",
  ERROR_3004: "Поле %field_name% не заполнено (курьер: %agent_id%, точка: %point_id%)",
  ERROR_3005: "Неизвестная зона %zone_id% (курьер: %agent_id%, точка: %point_id%)",
  ERROR_3006: "Слишком много зон в маргруте, 63 максимум",
  ERROR_3007: "Задача распредения по зонам требует наличия только одной зоны у точки %point_id%",
  ERROR_3008: "Кол-во рассм. соседних точек должно быть не менее 1",
  ERROR_3009: "Маршрут не содержит складов",
  ERROR_3010: "Нет доступа к серверу лицензий",
  ERROR_3011: "Проблемы с лицензией",
  ERROR_3012: "IP заблокирован",
  ERROR_3013: "Превышение допустимых лимитов задачи",
  ERROR_3014: "Unknown point (курьер: %agent_id%, точка: %point_id%)",
  ERROR_3015: "Unknown agent (курьер: %agent_id%, точка: %point_id%)",

  // Прочее
  TOOLTIP_WORKING_HOURS: "Указать время работы точки",
  FEEDBACK_SUCCESS: "Ваше сообщение отправлено",
  ADDRESS_DESTINATION: "Введите адрес в формате город улица дом",
  FEEDBACK_TEXTAREA_PLACEHOLDER: "Ваш вопрос",
  QUIZ_TOAST_WARN: "Заполните все необходимые поля",
  QUIZ_SEND_SUCCESS: "Благодарим за участие в опросе",
  QUIZ_REQUEST_SEND: 'Спасбо, Ваша заявка отправлена!',
  ENTER_NEW_PASSWD: "Введите новый пароль",
  RESTORE_PASSWORD_SUCCESS: "Запрос на восстановление пароля отправлен",
  PASSWORD_CHANGE_SUCCESS: 'Вы успешно сменили пароль',
  PASSWORD_CHANGE_REQUEST: 'Изменить пароль',
  PHONE_NUMBER_LABEL: "Номер телефона",
  PHONE_SEND_SUCCESS: "Ваш телефон отправлен",
  TYPE_PHONE: "Введите номер телефона",
};
