import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import Button from './Button';
import Spinner from 'svg/animation/SVG_Spinner.svg';
import ReactTooltip from "react-tooltip";
import I18N from "../../base/i18n";

const b = block('Button');

export default class SubmitFormButton extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        children: PropTypes.any,
        inProgress: PropTypes.bool
    };

    render() {
        return <Button
            onClick={this.props.onClick}
            className={b({'WithSpinner': this.props.inProgress, 'disabled': this.props.disabled})}
            disabled={this.props.disabled}
        >
            <div className={b('Spinner')}><img src={Spinner}/> {I18N.SUBMIT_PROGRESS}</div>
            {!this.props.inProgress && this.props.children}
        </Button>
    }
}