import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

const b = block('Input');

const TextareaInput = ({name, disabled, value, onChangeValue, placeHolder}) => {
  const textarea = React.createRef();

  const setSelectionRange = (input, selectionStart, selectionEnd) => {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }

  const onChange = (e) => {
    setSelectionRange(textarea, 1, 2)
    onChangeValue(e.target.name, e.target.value);
  }

  return <textarea
    className={b('TextareaInput')}
    id={name}
    ref={textarea}
    name={name}
    placeholder={placeHolder}
    onChange={onChange}
    disabled={disabled}
    value={value}
  />;
  };

TextareaInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChangeValue: PropTypes.func,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
}

export default TextareaInput;