import React from 'react';
import block from 'bem-cn-lite';
import sprite from 'svg/sprite.svg';

import ReactTooltip from 'react-tooltip';

const b = block('Icon');

const tooltipId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const Icon = ({svgName, color, style, className, onClick, name, toolTip}) => {
    const svgIcon = <svg onClick={onClick} name={name} key={svgName} style={{...style, background: 'none'}}
                         className={`${b()} ${className || ''}`}>
        <use xlinkHref={`${sprite}#SVG_${svgName}`} fill={color}></use>
    </svg>;

    if (!toolTip) {
        return svgIcon;
    }

    const tipId = tooltipId();

    return <span data-for={tipId} data-tip={toolTip}>
        {svgIcon}
        {toolTip && <ReactTooltip id={tipId} className={b('Tooltip')}/>}
    </span>;
};

export default Icon;
