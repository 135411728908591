import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import Icon from './Icon';
import { ButtonKinds } from '../helpers/enums';

import ReactTooltip from 'react-tooltip';

const b = block('Button');

export default
class Button extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    iconIsRight: PropTypes.bool,
    disabled: PropTypes.bool,
    toggledOff: PropTypes.bool,
    clickOnce: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.any,
    smallIcon: PropTypes.bool,
    smallerIcon: PropTypes.bool,
    kind: PropTypes.oneOf(ButtonKinds),
  };

  constructor() {
    super();
    this.state = {
      disabled: false,
      toggledOff: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      disabled: nextProps.disabled || false,
      toggledOff: nextProps.toggledOff || false,
    });
  }

  componentDidMount() {
    this.setState({
      disabled: this.props.disabled || false,
      toggledOff: this.props.toggledOff || false,
    });
  }

  _onClick(e) {
    if (!this.state.disabled) {
      this.props.onClick && this.props.onClick(e);
      this.props.to && this.props.route(this.props.to);
      this.setState({ disabled: this.props.clickOnce || false });
    }
  }

  _renderIcon() {
    if (this.props.icon)
      return (
        <div className={b('Icon', { 'NoTitle': !this.props.children, 'Small': this.props.smallIcon, 'Smaller': this.props.smallerIcon })}>
          <Icon svgName={ this.props.icon }/>
        </div>
      );
    return null;
  }

  _getStyleMod = () => ({
    'Default': !this.props.icon,
    'Iconed': Boolean(this.props.icon),
    'Disabled': !this.props.icon && this.props.disabled,
    'DisabledIconed': this.props.icon && this.props.disabled,
    'ToggledOff': !this.props.icon && this.props.toggledOff,
    'ToggledOffIconed': this.props.icon && this.props.toggledOff,
    'Warning': this.props.kind && this.props.kind === ButtonKinds.WARNING,
    'Danger': this.props.kind && this.props.kind === ButtonKinds.DANGER,
    'Unimportant': this.props.kind && this.props.kind === ButtonKinds.UNIMPORTANT,
    'Confirm': this.props.kind && this.props.kind === ButtonKinds.CONFIRM,
    'NewCheck': this.props.icon && this.props.disabled && this.props.className === 'NewBtn',
    'NewCheckDis': !this.props.disabled && this.props.className === 'NewBtn',
    'Small': this.props.kind && this.props.kind === ButtonKinds.SMALL,
  });

  render() {
    return (
      <div
        onClick={this._onClick.bind(this)}
        className={`${this.props.className || ''} ${b(this._getStyleMod())}`}
        data-tip={this.props.toolTip}
      >{this.props.toolTip && <ReactTooltip /> }
        {!this.props.iconIsRight && this._renderIcon()}
        {this.props.children && <div className={b('Title', {'Iconed': Boolean(this.props.icon)})}>{this.props.children}</div>}
        {this.props.iconIsRight && this._renderIcon()}
      </div>
    );
  }
}