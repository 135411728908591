export const QuizOne = [
  {
    type: 'single',
    title: '',
    option: [
      '',
      '',
      '',
    ],
  },
];