import { metersToKilometers, decimalHoursToTime, metersToMiles } from "./misc";

export default 
class ManifestStatistics {
  constructor({ total_cost, total_distance, total_points, total_time, total_weight }) {
    this.totalCost = total_cost;
    this.totalDistance = metersToKilometers(total_distance);
    this.totalDistanceMi = metersToMiles(total_distance);
    this.totalPoints = total_points;
    this.totalTime = decimalHoursToTime(total_time);
    this.totalWeight = total_weight;
    Object.freeze(this);
  }
}