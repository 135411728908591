import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import I18N from 'base/i18n';
import Icons from 'base/icons';
import { Icon, Button, TextInput } from 'common/components';
import ControlsAddressItem from './ControlsAddressItem';
import AddressPoint from '../../../common/helpers/AddressPoint';
import FileUpload from '../../../common/helpers/FileUpload';
import { pointsSort } from '../../../common/helpers/misc';
import { MAX_POINTS,FREE_MAX_POINTS } from '../../../actions';
import FeedBack from '../../../common/components/FeedBack';
import LoginForm from '../../../common/components/LoginForm';

const b = block('ControlsManualEditor');

export
const _isPointLimit = (token, pointLength) => {
  return (token
    ? pointLength >= MAX_POINTS
    : pointLength >= FREE_MAX_POINTS
    )
}

export
const _getMaxPointLimit = (token) => {
  return token ? MAX_POINTS : FREE_MAX_POINTS
}

export default
class ControlsManualEditor extends React.Component {
  static propTypes = {
    points: PropTypes.objectOf(PropTypes.instanceOf(AddressPoint)),
    addPoint: PropTypes.func,
  }

  _renderRows = (points, waybillPoints) => Object
    .values(points)
    .map((point) => ({ ...waybillPoints[point.id], ...point}))
    .filter((point) => !point.garage)
    .sort(pointsSort)
    .map((p) => <ControlsAddressItem
      key={p.id}
      point={p}
      {...this.props}
    />);
  
  _autoriz = () => {
    let form = "";
    if (localStorage.getItem("appUserHistory")) {
      form = "login";
    } else {
      form = "singup";
    }
    this.props.showModal(LoginForm, {
      loginFormWindow: form,
      caption: I18N.CONTROLS_ADDRESS_LIMIT_EXCEEDED_NOREGISTRATION,
    });
  }

  render() {
    return <div className={b()}>
      <FileUpload {...this.props} />
      <div style={{
        display: "flex",
        margin: "1em",
        justifyContent: "center",
      }}>
        <Button
          icon={Icons.POINT_ADD}
          onClick={_isPointLimit(this.props.token, Object.keys(this.props.points).length)
            ? this._autoriz
            :this.props.addPoint}
          // disabled={ _isPointLimit(this.props.token, Object.keys(this.props.points).length) }
          // className={ _isPointLimit(this.props.token, Object.keys(this.props.points).length) &&
          // 'ButtonDisabled'
          // }
        >{ I18N.CONTROLS_ADDRESS_ADD }</Button>
      </div>
      <div className={b('AddressList')}>
        {this._renderRows(this.props.points, this.props.waybillPoints)}
      </div>
      { Object.keys(this.props.points).length >= _getMaxPointLimit(this.props.token) ?
        <div className={b('LimitMassage')}>
        { this.props.token ? I18N.CONTROLS_ADDRESS_LIMIT_EXCEEDED : I18N.CONTROLS_ADDRESS_LIMIT_EXCEEDED_NOREGISTRATION }
        <Button
        className={b('BtnLimitMassage')}
        icon={Icons.FEEDBACK}
        onClick={this._autoriz}
      >{ I18N.SUBMIT_FEEDBACK }</Button>
        </div>
      : null }
    </div>;
  } 
}
