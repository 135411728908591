import React from 'react';
import { ContextMenu as ReactContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropTypes from 'prop-types';
import {ID_FINISH, ID_START} from "../../../actions";
import I18N from "../../../base/i18n";
import queries from '../../../base/queries';
import {toast} from "react-toastify";
import block from 'bem-cn-lite';
import { _isPointLimit } from '../../controls/components/ControlsManualEditor';
import { point } from 'leaflet';

const b = block('MapContextMenu');

export default class ContextMenu extends React.PureComponent {
    static propTypes = {
        addGeocodedPoint: PropTypes.func,
        updatePointAddress: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.trigger = React.createRef();
        this.coords = null;
    }

    show(e) {
        this.trigger.current.handleContextClick(e.originalEvent);
        this.coords = e.latlng;
    };


    _putStartPoint = () => {
        this._geocode(this.coords).then(address => {
            let garage_id = ''
            for (const point_id of Object.keys(this.props.points)) {
                if (this.props.points[point_id].garage) {
                    garage_id = point_id
                }
            } 
            this.props.updatePointAddress(garage_id, address, this._latLngToPointCoords(this.coords));
        });
    };

    _putIntermediatePoint = () => {
        if (!_isPointLimit(this.props.token, Object.keys(this.props.points).length)) {
            this._geocode(this.coords).then(address => {
                this.props.addGeocodedPoint(address, this._latLngToPointCoords(this.coords));
            });
        } else {
            toast.error(I18N.CONTROLS_ADDRESS_LIMIT_EXCEEDED_NOREGISTRATION)
        }
    };

    _putEndPoint = () => {
        this._geocode(this.coords).then(address => {
            this.props.updatePointAddress(ID_FINISH, address, this._latLngToPointCoords(this.coords));
        });
    };

    _geocode = coords => {
        return new Promise((resolve, reject) => {
            queries
                .GEOCODE_REVERSED(this._latLngToPointCoords(coords))
                .then(address => {
                    if (!address) {
                        return;
                    }

                    resolve(address);
                })
                .catch(() => {
                    toast.error(I18N.ERROR_C012);
                    reject('Reverse geocoding failed.');
                })
        });
    };

    _latLngToPointCoords = latlng => {
        return [latlng.lat, latlng.lng];
    };

    render() {
        return <div>
            <ContextMenuTrigger id={'mapContextMenu'} ref={this.trigger}>
                <span style={{'display':'none'}}/>
            </ContextMenuTrigger>

            <ReactContextMenu id={'mapContextMenu'} preventHideOnScroll={true} className={b()}>
                {/* <MenuItem onClick={this._putStartPoint}>{I18N.MAP_MENU_USE_AS_START}</MenuItem> */}
                <MenuItem onClick={this._putIntermediatePoint}>{I18N.MAP_MENU_USE_AS_INTERMEDIATE}</MenuItem>
                {/* <MenuItem onClick={this._putEndPoint}>{I18N.MAP_MENU_USE_AS_FINISH}</MenuItem> */}
            </ReactContextMenu>
        </div>
    }
}