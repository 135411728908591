const Icons = {
  AGENT_HEAVY: "AgentHeavy",
  AGENT_LIGHT: "AgentLight",
  AGENT_MAN: "AgentMan",
  CALCULATION_CLEAR: "CalculationClear",
  CALCULATION_DOWNLOAD: "CalculationDownload",
  CALCULATION_PRINT: "CalculationPrint",
  CALCULATION_LINK: "CalculationLink",
  CALCULATION_RUN: "CalculationRun",
  JAMS_OFF: "JamsOff",
  JAMS_ON: "JamsOn",
  LOGO: "Logo2",
  SAVE: "Save",
  RESET: "Reset",
  MARKER_FINISH: "MarkerFinish",
  MARKER_REGULAR: "MarkerRegular",
  MARKER_START: "MarkerStart",
  POINT_ADD: "PointAdd",
  POINT_FINISH: "PointFinish",
  POINT_REGULAR: "PointRegular",
  POINT_START: "PointStart",
  POINT_REMOVE: "PointRemove",
  POINT_WORKTIME: "PointWorkTime",
  FEEDBACK: "FeedBack",
  NAVIGATE: "Navigate",
  TELEGRAM_LOGO: "TelegramLogoFilled",
};

export default Icons;
