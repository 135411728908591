import React, { Component } from "react";
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { Modal, IconButton, Button } from "@material-ui/core";
import { CheckboxInput } from "../components";
import CancelIcon from "@material-ui/icons/Cancel";
import { isMobileDevice } from "./../helpers/misc";

const b = block('BannerWindow');

export default class UpdateBannerWindow extends Component {
  static propTypes = {
    showUpdateBannerState: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showUpdateBanner: this.props.showUpdateBanner,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={b()} style={{ display: this.props.showUpdateBanner && this.state.show ? '' : 'none' }}
      >
        <Modal
          open={this.props.showUpdateBanner && this.state.show}
          className={b("Modal")}
        >
          <div
            className={b("ModalInner")}
          >
            <div style={{ position: "relative" }}>
              <IconButton
                style={{ position: "absolute", top: "-26px", right: "-22px" }}
                onClick={() => {
                  this.setState({ show: !this.state.show });
                  this.props.showUpdateBannerState(this.state.showUpdateBanner);
                }}
              >
                <CancelIcon />
              </IconButton>
            </div>

            <div className={b("ModalContent")}>

              <div className={b("Text")}>Мы добавили новый функционал на Poncy, что бы Вы могли повысить качество своей доставки</div>
              <ul className={b("Ul")}>
                <li>
                  <div class={b("IconCircle", {"FixedSize": !isMobileDevice()})}><img src="poncy/img/excel32.png"/></div>
                  <div>
                    <div class={b("DivH")}>Загружайте заказы из Excel файлов</div>
                    <p>Адреса доставки (заказы) можно загружать двумя способами:<br/> 
                      вручную или через Excel файл. <a href="poncy/loadfromexcel.html">Подробнее...</a>
                    </p>
                  </div>
                </li>
                <li>
                  <div class={b("IconCircle", {"FixedSize": !isMobileDevice()})}><img src="poncy/img/couriers32.png"/></div>
                  <div>
                    <div class={b("DivH")}>Стройте маршруты на несколько курьеров</div>
                    <p>Заказы можно распределять на несколько курьеров<br/> автомобильных или пеших. <a href="poncy/courierspoints.html">Подробнее...</a>
                    </p>
                  </div>
                </li>
                <li>
                  <div class={b("IconCircle", {"FixedSize": !isMobileDevice()})}><img src="poncy/img/save32.png"/></div>
                  <div>
                    <div class={b("DivH")}>Сохраняйте маршруты в избранном</div>
                    <p>Построенный маршрут можно сохранить и загрузить<br/> не боясь потерять данные. <a href="poncy/bookmark.html">Подробнее...</a>
                    </p>
                  </div>
                </li>
                <li>
                  <div class={b("IconCircle", {"FixedSize": !isMobileDevice()})}><img src="poncy/img/marker32.png"/></div>
                  <div>
                    <div class={b("DivH")}>Контролируйте выполнение маршрутов на карте online</div>
                    <p>Маршут можно передать курьеру через Telegram бот<br/>
                      и контролировать выполнение маршрута в реальном времени. <a href="poncy/monitoring.html">Подробнее...</a>
                    </p>
                  </div>
                </li>
              </ul>
                
              <div className={b("AboutService")}>
                <h3>О сервисе Poncy</h3>
                <p>Логистический сервис Poncy предоставляет бесплатную помощь в построении маршрутов на карте по точкам. Заполнять точки можно, выгрузив названия из текстового документа списком или вводя их вручную. Выбрать стартовую, конечную и промежуточные точки можно вручную из выпадающего списка, кликнув отметку на карте.</p>
                <h3>Преимущества бесплатного сервиса</h3>
                <p>Каждому пользователю доступен маршрут из 30 точек. Помимо начальной и финальной точки в алгоритм можно добавить до 28 адресов.</p>
                <h3>Работа алгоритма оптимизации</h3>
                <p>Алгоритм ищет кратчайший путь, перестраивая расстояния между точками на карте. Он делает это многократно, подбирая лучший вариант. В среднем, маршрут улучшается на 30 % в сравнении с предварительным заполнением вручную. Это существенно сокращает время подготовки маршрутов.</p>
                <h3>Принцип построения маршрута</h3>
                <p>С помощью сервиса можно построить маршруты для грузовых и легковых автомобилей, учитывая временные окна, остановки в пути и пробки. Poncy учитывает автомобильное движение в городе и начало часов пик. Это позволяет не только планировать время доставки, но и дает обширную информацию для каждого сотрудника транспортной компании. Понятный функционал делает программу удобной для участников любого этапа логистической цепочки – от водителя до руководителя.</p>
              </div>            
                
                

              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <CheckboxInput
                  value={!this.state.showUpdateBanner}
                  disabled={false}
                  onChangeValue={() => {
                    this.setState({ showUpdateBanner: !this.state.showUpdateBanner });
                  }}
                />
                <p>Больше не показывать</p>
              </div>

            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
