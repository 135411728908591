import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import fileDownload from 'js-file-download';
import unstream from 'unstream';
import print from 'print-js';

import I18N from "../../base/i18n";
import { decimalHoursToTime, decodeDateRU } from "./misc";

const groupArray = (arr, groupParamName) => {
  const grouped = {};
  for (const el of arr) {
    if (!grouped[el[groupParamName]]) grouped[el[groupParamName]] = [];
    grouped[el[groupParamName]].push(el);
  }
  return Object.values(grouped);
} 

const getXLS = (points, agents, waybillArray, statistics) => {
  const stats = [
    [I18N.REPORT_STAT_POINTS, String(statistics.totalPoints)],
    [I18N.REPORT_STAT_DISTANCE, String(statistics.totalDistance)],
    [I18N.REPORT_STAT_TIME, String(statistics.totalTime)],
    []
  ]

  const agentHeaderRow = [[
    I18N.REPORT_COURIER_NAME,
    I18N.REPORT_COURIER_PHONE,
    I18N.REPORT_COURIER_WORK_START,
    I18N.REPORT_COURIER_WORK_END,
  ]]
  const pointHeaderRow = [[
    I18N.REPORT_ORDER,
    I18N.REPORT_FIELD_ADDRESS,
    I18N.REPORT_FIELD_WORK_START,
    I18N.REPORT_FIELD_WORK_END,
    I18N.REPORT_ARRIVAL,
    I18N.REPORT_DEPARTURE,
    // I18N.REPORT_TOTAL_DISTANCE,
    I18N.REPORT_COURIER_COMMENT,
    I18N.REPORT_POINT_STATUS,
    I18N.REPORT_COURIER_COMMENT,
  ]];
  const pointStatus = {
    0: I18N.REPORT_POINT_STATUS_0,
    1: I18N.REPORT_POINT_STATUS_1,
    2: I18N.REPORT_POINT_STATUS_2,
    5: I18N.REPORT_POINT_STATUS_5,
  }

  const groupedByAgent = groupArray(waybillArray, 'courierId');

  const workbook = new ExcelJs.Workbook();
  for (const agentWaybillPoints of groupedByAgent) {
    const courierID = agentWaybillPoints[0].courierId
    const currentCourierSheet = workbook.addWorksheet(agents[courierID].name);
    currentCourierSheet.addRows([
      ...agentHeaderRow,
      [
        agents[courierID].name,
        agents[courierID].phone,
        decimalHoursToTime(agents[courierID].workStart),
        decimalHoursToTime(agents[courierID].workEnd),
      ],
      [],
      ...pointHeaderRow,
      ...agentWaybillPoints.filter((el) => !el.errorCode).map((wp) => 
      [
        wp.totalPoints + 1,
        points[wp.pointId].address,
        decimalHoursToTime(points[wp.pointId].workStart),
        decimalHoursToTime(points[wp.pointId].workEnd),
        decimalHoursToTime(wp.arrivalTime),
        decimalHoursToTime(wp.departureTime),
        // metersToKilometers(wp.totalDistanceKm),
        points[wp.pointId].comment,
        (!points[wp.pointId].garage && pointStatus[wp.status || 0]) || '',  // Статус выполнения
        wp.comment || '',  // Комментрий от курьера
      ])
    ]);
  }

  return workbook;
};

const generateXLS = (points, agents, waybillArray, statistics) => {
  return getXLS(points, agents, waybillArray, statistics).xlsx.write(unstream({}, (fileData) => {
    fileDownload(fileData, `${I18N.REPORT_SHEET_NAME} (${decodeDateRU(new Date(), '_')}).xlsx`);
  }));
};

const getTemlate = () => {
  const workbook = new ExcelJs.Workbook();
  const mainSheet = workbook.addWorksheet(I18N.REPORT_SHEET_NAME);
  mainSheet.addRow([
    I18N.FILE_POINT_ADDRESS,
    I18N.FILE_POINT_LAT,
    I18N.FILE_POINT_LON,
    I18N.FILE_POINT_WORK_START,
    I18N.FILE_POINT_WORK_END,
    I18N.FILE_POINT_SHIPPING_TIME,
    I18N.FILE_POINT_COMMENT,
  ]);

  return workbook.xlsx.write(unstream({}, (fileData) => {
    fileDownload(fileData, `${I18N.FILE_TEMPLATE_NAME}.xlsx`);
  }));
}

const printXLS = (points, agents, waybillArray, statistics) => {
  const workbook = getXLS(points, agents, waybillArray, statistics);

  let tables = ''
  for (const sheet of workbook.worksheets) {
    const maxColumns = sheet.columnCount;
    let rows = '';
    sheet.getSheetValues().forEach(row => {
      let cells = '';
      row.forEach(cell => {
        // if comment disable - get empty cells when row column < max rows
        // if (row.length < maxColumns) {
        //   cells += '<td colspan="' + maxColumns + '">' + cell + '</td>';
        // } else {
          cells += '<td>' + cell + '</td>';
        // }
      });

      rows += '<tr>' + cells + '</tr>';
    });
    tables += '<h2>' + sheet.name + '</h2>'
    tables += '<table><tbody>' + rows.concat('') + '</tbody></table>'
  }

  print({
    printable: '<h1>' + I18N.REPORT_PRINT_HEADER + '</h1>' + tables,
    type: 'raw-html',
    style: `
      table { border-collapse: collapse; }
      table, th, td { border: 1px solid black;}
     `
  });
};

export default {
  generateXLS,
  printXLS,
  getTemlate
};