import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { decimalHoursToTime, timeToDecimalHours } from '../helpers/misc';

const b = block('Input');

class SimpleInput extends React.PureComponent {
  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChangeValue: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    toView: PropTypes.func,
    fromView: PropTypes.func,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
  }

  _onChange = (e) => {
    this.props.onChangeValue
      && this.props.onChangeValue(e.target.name, this.props.fromView ? this.props.fromView(e.target.value) : e.target.value);
  }

  render() {
    return (
      <input
        autoComplete={this.props.autoComplete}
        className={b()}
        id={this.props.name}
        min={this.props.min}
        max={this.props.max}
        step={this.props.step}
        type={this.props.type}
        name={this.props.name}
        placeholder={this.props.placeHolder}
        value={(this.props.toView ? this.props.toView(this.props.value) : this.props.value) || ''}
        onChange={this._onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        disabled={this.props.disabled}
      />
    );
  }
}

export
const HiddenInput = (props) => (
  <SimpleInput
    fromView={String}
    type='hidden'
    {...props} />
);

export
const TextInput = (props) => (
  <SimpleInput
    fromView={String}
    type='text'
    {...props} />
);

export
const TimeInput = (props) => (
  <SimpleInput
    toView={decimalHoursToTime}
    fromView={timeToDecimalHours}
    type='time'
    {...props}
  />
);

export
const NumberInput = (props) => (
  <SimpleInput
    toView={(val) => val === null ? '' : String(val)}
    fromView={(val) => {
      if (val.length) {
        let num = Number(val);
        num = isFinite(props.min) && num < props.min ? props.min : num;
        num = isFinite(props.max) && num > props.max ? props.min : num;
        return num;
      }
      return null;
    } }
    type='number'
    {...props}
  />
);

export
const RangeInput = (props) => (
  <div className={b('Range')}>
    <SimpleInput
      toView={(val) => val === null ? '' : String(val)}
      fromView={(val) => val.length ? Number(val) : null }
      type='range'
      {...props}
    />
    <div className={b('RangeValue')}>{props.value}</div>
  </div>
);

export
const PasswordInput = (props) => (
  <SimpleInput
    fromView={String}
    type='password'
    {...props}
  />
);

export
const EmailInput = (props) => (
  <SimpleInput
    fromView={String}
    type='email'
    {...props}
  />
);
