import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

const b = block('Input');


class TextareaMassage extends React.PureComponent {

  _onChange = (e) => {
    this.props.onChangeValue
      && this.props.onChangeValue(e.target.name, e.target.value);
  }

  render() {
    return (
      <textarea
        className={b('TextareaInput')}
        type="text"
        name={this.props.name}
        placeholder={this.props.placeHolder}
        value={this.props.value}
        onChange={this._onChange}

      />
    );
  }
};

TextareaMassage.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChangeValue: PropTypes.func,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
}

export default TextareaMassage;