import React from 'react'
import { connect } from 'react-redux'
import 'leaflet/dist/leaflet.css'
import { PropTypes } from 'prop-types'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'

import { LeafletMap } from './modules/map'
import { Controls } from './modules/controls'
import { reducer, actions } from 'actions'
import queries from './base/queries'
import AddressPoint from './common/helpers/AddressPoint'
import WaybillPoint from './common/helpers/WaybillPoint'
import { isMobileDevice } from './common/helpers/misc'
import ManifestStatistics from './common/helpers/ManifestStatistics'
import ModalWindow from './common/components/ModalWindow'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import I18N from './base/i18n'
import BannerWindow from './common/components/BannerWindow'
import UpdateBannerWindow from './common/components/UpdateBannerWindow'
import MapHeader from './modules/map/components/MapHeader'
import Button from 'common/components/Button'
import Icons from 'base/icons'
import { InitMetric } from './modules/others/metrics'
import { RunningLine } from "./common/components/RunningLine"


class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			MobileSwitchState: false,
			ShowBanners: false,
		}
	}

	componentDidMount() {
		if (this.props.currentManifestID) {
			queries.MANIFEST_WAYBILL_POINTS(this.props.currentManifestID)
			.then((resWaybill) => {
				// this.props.sortPoints( this.props.points, res.data.result)
				const resWaybillPoints = resWaybill.data.result;
				this.props.updateWaybillData(resWaybillPoints);
			});
		}
		if (this.props.waybillPoints && this.props.waybillPoints["1"] && this.props.waybillPoints["1"].garage === undefined) {
			this._resetOldWaybill();
		}
		// this._checkPermissions()
		try {
			this._findCurrentLocation()
		} catch (e) {
			console.warn(e)
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.lastStartedManifestId && this.props.lastStartedManifestId) {
			this.checkResultTimer = setInterval(() => {
				// Расчёт для авторизованных пользователей
				if (this.props.token) {
				  queries.OPTIMIZATION_STATUS(this.props.lastStartedManifestId)
				  .then((data) => {
				    if ((data.ok === false || !data.data.result.calculated)
					  && (data.ok === false || !data.data.result.optimization_status)) {
					  this._setErrorManifest();
					}
					if (data.data.result.calculated) {
					  clearInterval(this.checkResultTimer);
					  this.setState({ MobileSwitchState: true });
					  toast.success(I18N.CONTROLS_RUN_SUUCCESS);
					  queries.MANIFEST(this.props.lastStartedManifestId)
					  .then((resManifest) => {
					    const manifest_data = resManifest.data.result;
					    this.props.updateStatistics(manifest_data);
					  });
					  queries.MANIFEST_WAYBILL_POINTS(this.props.lastStartedManifestId)
					  .then((resWaybill) => {
						// this.props.sortPoints( this.props.points, res.data.result)
						const resWaybillPoints = resWaybill.data.result;
						this.props.updateWaybillData(resWaybillPoints);
					  });
					}
				  });
				}
				// Расчёт для НЕавторизованных пользователей
				else {
				  queries.DIRECT_OPTIMIZATION_RESULT(this.props.lastStartedManifestId, true)
				  .then((data) => {
					if(data.ok === true){
					  clearInterval(this.checkResultTimer);
					  this.setState({MobileSwitchState: true});
					  toast.success(I18N.CONTROLS_RUN_SUUCCESS);
					  this.props.setLastStartedManifest(null);
					  this.props.updateStatistics(data.statistics);
					  const resWaybillPoints = data.agents.reduce((all, agent) => [
						...all,
						...(agent.points
						  ? agent.points.map((point) => ({
						    ...point,
							courier: {id: agent.id},
							point_id: point.id,
						  }))
						  : []
						)
					  ], []);
					  this.props.updateWaybillData(resWaybillPoints);
					} else {
					  this._setErrorManifest();
					}
				  });
	
				}
			}, 3000)
		}
		if (prevProps.lastStartedManifestId && !this.props.lastStartedManifestId) {
			clearInterval(this.checkResultTimer);
		}
	}

	componentWillUnmount() {
		clearInterval(this.checkResultTimer)
	}

	_setErrorManifest() {
		this.setState({ MobileSwitchState: true });
		// Обнуляем манифест чтобы снова появилась кнопка запуска расчёта
		this.props.setLastStartedManifest(null);
		this.props.setCurrentManifestID(null);
		toast.error(I18N.ERROR);
		clearInterval(this.checkResultTimer);
	}

	_checkPermissions() {
		queries.CURRENT_USER()
			.then((userData) => {
				if (!userData || !userData.id || userData.email !== this.props.user.email) {
					Promise.reject()
				}
				if (!userData.metrics) {
					if (localStorage.getItem('appUserHistory')) {
						let dataLS = JSON.parse(localStorage.getItem('appUserHistory'))
						queries.UPDATE_USER(dataLS)
					} else {
						queries.UPDATE_USER(InitMetric)
					}
				}
			})
			.catch((e) => {
				this.props.logoutUser();
				// toast.error(I18N.ERROR_C008);
			})
	}

	_findCurrentLocation() {
		navigator.geolocation.getCurrentPosition(location => {
			this.props.setCurrentLocation([location.coords.latitude, location.coords.longitude])
			// console.log('Current location lat: ' + location.coords.latitude + ', lon: ' + location.coords.longitude)
		})
	}

	_getLastWaybill() {

	}

	_resetOldWaybill() {
		const defPoint = this.props.default.point;
		const points = Object.values(this.props.points).reduce((all, point) => [
			...all,
			{
				id: point.id,
				address: point.address,
				lat_lon: point.coords,
				work_start: point.workStart ? point.workStart : defPoint.workStart,
				work_end: point.workEnd ? point.workEnd : defPoint.workEnd,
				shipping_time: point.shipping_time ? point.shipping_time/60 : defPoint.shipping_time/60,
				garage: point.id === "1",
			}
		], [])
		this.props.resetWaybills();
		this.props.updatePointsIds(points);
	}

	SwitchInterface = () => {
		this.setState((state) => ({ ...state, MobileSwitchState: !state.MobileSwitchState }))
	}

	render() {
		return (
			<div
				className={!this.state.MobileSwitchState ? 'App MobileSwitch-on' : 'App MobileSwitch-off'}
			>
				{this.props.showUpdateBanner && <UpdateBannerWindow {...this.props} />}
				{(!isMobileDevice() || this.state.MobileSwitchState) && this.state.ShowBanners && <BannerWindow user={this.props.user} token={this.props.token} />}
				<ModalWindow />
				<ToastContainer
					position="bottom-center"
					autoClose={4000}
					hideProgressBar
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
				<MapHeader
					className='siteHeader'
				/>
				<Controls {...this.props} />
				{(!isMobileDevice() || this.state.MobileSwitchState) && <LeafletMap {...this.props} />}
				<Button
					className='BtnMobile'
					icon={Icons.FEEDBACK}
					onClick={this.SwitchInterface}
				>
					{!this.state.MobileSwitchState ? 'Показать карту' : 'Показать адреса'}
				</Button>
			</div>
		)
	}
}

// <RunningLine />

function mapStateToProps(state) {
	return {
		user: state[reducer.name].user,
		token: state[reducer.name].token,
		points: state[reducer.name].points,
		lastStartedManifestId: state[reducer.name].lastStartedManifestId,
		currentManifestID: state[reducer.name].currentManifestID,
		waybillPoints: state[reducer.name].waybillPoints,
		waybillArray: state[reducer.name].waybillArray,
		statistics: state[reducer.name].statistics,
		currentLocation: state[reducer.name].currentLocation,
		lang: state[reducer.name].lang,
		userHistory: state[reducer.name].userHistory,
		NavBarStatus: state[reducer.name].NavBarStatus,
		agents: state[reducer.name].agents,
		default: state[reducer.name].default,
		showUpdateBanner: state[reducer.name].showUpdateBanner,
		freeCalculation: state[reducer.name].freeCalculation,
	}
}

export default connect(mapStateToProps, (dispatch) => ({
	showModal: (content, contentProps) => dispatch(actions.showModal(content, contentProps)),
	loginUser: (user, token) => dispatch(actions.loginUser(user, token)),
	logoutUser: () => dispatch(actions.logoutUser()),
	updateUser: (user, key, value) => dispatch(actions.updateUser(user, key, value)),
	addPoint: () => dispatch(actions.addPoint()),
	addMassPoint: (points) => dispatch(actions.addMassPoint(points)),
	addGaragePoint: () => dispatch(actions.addPoint(null, null, true)),
	addGeocodedPoint: (address, coords) => dispatch(actions.addPoint(address, coords)),
	setFirstPointToGarage: () => dispatch(actions.setFirstPointToGarage()),
	updatePointAddress: (id, name, coords) => dispatch(actions.updatePointAddress(id, name, coords)),
	updatePointComment: (id, comment) => dispatch(actions.updatePointComment(id, comment)),
	updatePointCoords: (id, coords) => dispatch(actions.updatePointCoords(id, coords)),
	updatePointTime: (id, workStart, workEnd, shipping_time) => dispatch(actions.updatePointTime(id, workStart, workEnd, shipping_time)),
	removePoint: (id) => dispatch(actions.removePoint(id)),
	updateMassPoints: (points) => dispatch(actions.updateMassPoints(points)),
	updateWaybillData: (agents, waybillPoints) => dispatch(actions.updateWaybillData(agents, waybillPoints)),
	setLastStartedManifest: (manifestId) => dispatch(actions.setLastStartedManifest(manifestId)),
	setCurrentManifestID: (manifestId) => dispatch(actions.setCurrentManifestID(manifestId)),
	resetAddresses: () => dispatch(actions.resetAddresses()),
	resetWaybills: () => dispatch(actions.resetWaybills()),
	resetAgents: () => dispatch(actions.resetAgents()),
	setCurrentLocation: coords => dispatch(actions.setCurrentLocation(coords)),
	setLang: lang => dispatch(actions.setLang(lang)),
	setUserHistory: userHistory => dispatch(actions.setUserHistory(userHistory)),
	setNavBarStatus: NavBarStatus => dispatch(actions.setNavBarStatus(NavBarStatus)),
	sortPoints: (a, b) => dispatch(actions.sortPoints(a, b)),
	addAgent: () => dispatch(actions.addAgent()),
	removeAgent: (id) => dispatch(actions.removeAgent(id)),
	updateDefault: (type, key, value) => dispatch(actions.updateDefault(type, key, value)),
	updateAgentName: (id, name, phone, delType, maxPoints, notReturning) => dispatch(actions.updateAgentName(id, name, phone, delType, maxPoints, notReturning)),
	updateAgentTime: (id, workStart, workEnd) => dispatch(actions.updateAgentTime(id, workStart, workEnd)),
	updatePointsIds: (points) => dispatch(actions.updatePointsIds(points)),
	updateAgentOnDBSave: (agents) => dispatch(actions.updateAgentOnDBSave(agents)),
	updateStatistics: (manifest) => dispatch(actions.updateStatistics(manifest)),
	showUpdateBannerState: (state) => dispatch(actions.showUpdateBannerState(state)),
	increaseFreeCalculationLimit: () => dispatch(actions.increaseFreeCalculationLimit()),
})
)(App)

App.propTypes = {
	token: PropTypes.string,
	user: PropTypes.object,
	points: PropTypes.objectOf(PropTypes.instanceOf(AddressPoint).isRequired),
	waybillPoints: PropTypes.objectOf(PropTypes.instanceOf(WaybillPoint).isRequired),
	waybillArray: PropTypes.arrayOf(PropTypes.instanceOf(WaybillPoint).isRequired),
	statistics: PropTypes.instanceOf(ManifestStatistics),
	lastStartedManifestId: PropTypes.number,
	currentManifestID: PropTypes.number,
	setLastStartedManifest: PropTypes.func,
	addPoint: PropTypes.func,
	updatePointAddress: PropTypes.func,
	updatePointCoords: PropTypes.func,
	removePoint: PropTypes.func,
	resetAddresses: PropTypes.func,
	showModal: PropTypes.func,
	setCurrentLocation: PropTypes.func,
	showUpdateBanner: PropTypes.bool,
	freeCalculation: PropTypes.number,
}
