const nullOrObject = (obj) => {
  if (!obj)
    return null;

  if (obj.constructor === Object && Object.keys(obj).length === 0)
    return null;

  if (obj.constructor === Array && obj.length === 0)
    return null;

  return obj;
};

class Reducer {
  constructor(name, init) {
    this.name = name;
    this.persistentState = nullOrObject(JSON.parse(localStorage.getItem(name))) || {};
    this.init = {...init, ...this.persistentState};
    this.actions = new Map();
  }

  store = (obj) => {
    this.persistentState = {...this.persistentState, ...obj};
    localStorage.setItem(this.name, JSON.stringify(this.persistentState));
  }

  reduce = (state = this.init, action) => {
    const handlerChain = this.actions.get(action.type);
    if (handlerChain) {
      for (const handler of handlerChain) {
        const newState = handler[0](state, action, this.store);
        state = {...state, ...newState};
      }
      return state;
    }
    return state;
  }

  addHandler = (action, newHandler) => {
    if (!newHandler)
      return;
    newHandler = [newHandler];

    const handlerChain = this.actions.get(action.type);
    if (handlerChain)
      handlerChain.push(newHandler);
    else
      this.actions.set(action.type, [newHandler]);

    newHandler.unsubscribe = () => this.removeHandler(newHandler);
    return newHandler;
  }

  removeHandler = (descriptor) => {
    for (const chain of this.actions.values()) {
      if (!chain)
        continue;
      const idx = chain.indexOf(descriptor);
      if (idx !== -1) {
        chain.splice(idx, 1);
      }
    }
  }

  makeAction = (ctor, handler, name) => {
    const type = (name) ? `${name}/${this.name}` : Symbol();
    function wrap() {
      const action = ctor ? ctor(...arguments) : {};
      action.type = type;
      return action;
    }
    wrap.type = type;
    this.addHandler(wrap, handler);
    wrap.subscribe = (nextHandler) => this.addHandler(wrap, nextHandler);
    return wrap;
  }
}

const reducers = {};

export
function makeReducer(name, init) {
  const r = new Reducer(name, init);
  reducers[name] = r.reduce;
  return r;
}

export
function getAllReducers() {
  return reducers;
}
