export default class Agent {
  constructor(
    id,
    name,
    phone,
    delType = "car",
    maxPoints = null,
    workStart = 0,
    workEnd = 23.99,
    garageID,
    notReturning = true,
  ) {
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.delType = delType;
    this.maxPoints = maxPoints;
    this.workStart = workStart;
    this.workEnd = workEnd;
    this.garageID = garageID;
    this.notReturning = notReturning;
  }
}
