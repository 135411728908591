export default 
class AddressPoint {
  constructor(id, address, coords, geocoded = false, workStart = 0, workEnd = 23.99, shipping_time = 15, garage = false, comment = '') {
    this.id = id;
    this.address = address;
    this.coords = coords;
    this.geocoded = geocoded;
    this.workStart = workStart;
    this.workEnd = workEnd;
    this.shipping_time = shipping_time;
    this.garage = garage;
    this.comment = comment;
    //Object.freeze(this);
  }
}