import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { TextareaInput, DivInput } from 'common/components';
import I18N from 'base/i18n';
import AddressPoint from 'common/helpers/AddressPoint';
import { pointsSort } from '../../../common/helpers/misc';
import ConfirmButton from '../../../common/components/ConfirmButton'
import Button from '../../../common/components/Button'
import { Agents, ButtonKinds } from '../../../common/helpers/enums';
import Icons from 'base/icons';
import LoginForm from '../../../common/components/LoginForm'

const b = block('ControlsTextEditor');

class ControlsTextEditor extends React.Component {
  static propTypes = {
    points: PropTypes.objectOf(PropTypes.instanceOf(AddressPoint)),
    placeHolder: PropTypes.string,
    updateMassPoints: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      textareaValue: '',
    }
  }

  componentWillMount() {
    let ttt = Object.values(this.props.points)
      .sort(pointsSort)
      .map((p) => this._textToRow(p.id, p.address))
      .join('\n');
    if (JSON.parse(localStorage.getItem('appListpoints')) == null) {
      // console.log('appListpoints=null');
    }
    if (this.state.textareaValue == '') {
      this.setState({ textareaValue: JSON.parse(localStorage.getItem('appListpoints')) });
    }
    //this.setState({ textareaValue: localStorage.getItem('appListpoints') });
  }

  _textToRow = (id, address) => `[${id}]: ${address}`;

  _renderPointsAsText = () => Object.values(this.props.points)
    .sort(pointsSort)
    .map((p) => this._textToRow(p.id, p.address))
    .join('\n');

  _onChange = (name, value) => {
    const newPointList = value
      .split('\n')
      .map((row, i, arr) => {
        const rgx = /^\[([0-9SF]+)\]:\s(.*)$/gu;
        const resultArr = rgx.exec(row);
        if (resultArr && resultArr[1])
          return new AddressPoint(resultArr[1], resultArr[2] || '', null);
        return new AddressPoint(null, row)
      });

    this.props.updateMassPoints(newPointList);
  }

  _pointsLoad = () => {
    if (!this.props.token) {
      let form = '';
      if (localStorage.getItem('appUserHistory')) {
        form = 'login';
      } else { form = 'singup' }
      this.props.showModal(LoginForm, {
        loginFormWindow: form,
      })
    } else {
      const newPointList = this.state.textareaValue
        .trim()
        .split('\n')
        .map((row, i, arr) => {
          const rgx = /^\[([0-9SF]+)\]:\s(.*)$/gu;
          const resultArr = rgx.exec(row);
          if (resultArr && resultArr[1])
            return new AddressPoint(resultArr[1], resultArr[2] || '', null);
          return new AddressPoint(null, row)
        });
      this.props.updateMassPoints(newPointList);
      this.props.ChangeManualMode();
    }
  }

  _textareaOnChange = (name, value) => {
    this.setState({ textareaValue: value });
    let val = value.trim().split('\n');
    localStorage.setItem('appListpoints', JSON.stringify(val));
    // console.log('_textareaOnChange', typeof this.state.textareaValue)
  }
  _textareaRender = () => {
    let ttt = this.state.textareaValue;
    if (Array.isArray(ttt)) {
      return ttt.map((row, i, arr) => `${row}`).join('\n');
    } else {
      return ttt;
    }
  }
  _pointsLoadStatus = () => {
    // console.log('_pointsLoadStatus', typeof this.state.textareaValue)
    if ((this.state.textareaValue == '') || (this.state.textareaValue == null) || (this.state.textareaValue !== String(this.state.textareaValue))) return true;
  }

  render() {
    return <div className={b()}>
      <h2>{I18N.CONTROLS_HEADER_TEXT_EDITOR}</h2>
      <TextareaInput
        onChangeValue={this._textareaOnChange}
        value={this._textareaRender()}
      />
      <div className={b('AdreeHint')}>
        {I18N.CONTROLS_ADRESS_HINT}
      </div>
      <div className={b('Wrapper')}>
        {this.props.token ?
          <ConfirmButton
            kind={ButtonKinds.CONFIRM}
            confirmMessage={I18N.CONTROLS_ALERT_DOWNLOAD_POINTS}
            onClick={this._pointsLoad}
            icon={Icons.CALCULATION_RUN}
            disabled={this._pointsLoadStatus()}
          >{I18N.SUBMIT_DOWNLOAD_POINTS}</ConfirmButton>
          : <Button
            kind={ButtonKinds.CONFIRM}
            confirmMessage={I18N.CONTROLS_ALERT_DOWNLOAD_POINTS}
            onClick={this._pointsLoad}
            icon={Icons.CALCULATION_RUN}
            disabled={this._pointsLoadStatus()}
          >{I18N.SUBMIT_DOWNLOAD_POINTS}</Button>
        }

      </div>

      {/* <TextareaInput
        onChangeValue={this._onChange}
        value={this._renderPointsAsText()}
      /> */}
    </div>;
  }
}

export default ControlsTextEditor;