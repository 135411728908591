import { I18N_ru } from './languages/i18n_ru'
import { store } from '../index'
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { reducer, actions } from '../actions';

// переменная локализации (каждое слово выполняет цикл действий ниже для получения перевода: Это надо менять!!!)
// const I18N = (item) => {
// 	let lang
// 	let ls = JSON.parse(localStorage.getItem('app'))
// 	lang = ls.lang.language ? ls.lang.language : false
// 	if(!lang){
// 		let state = store.getState()
// 		lang = state.app.lang
// 	}
// 	switch(lang){
// 		case 'English':
// 		case 'English-Default':
// 			return `${I18N_en[item]}`
// 		case 'Spanish':
// 			return `${I18N_es[item]}`
// 		case 'Portuguese':
// 			return `${I18N_pt[item]}`
// 		case 'Deutsch':
// 			return `${I18N_de[item]}`
// 		default: 
// 			return `${I18N_en[item]}`
// 	}
// }
const I18N = I18N_ru;

export default I18N