import React, { useState } from "react";
// Package for Excel
import ExcelJs from "exceljs";
// Components
import Button from "common/components/Button";
import AddressPoint from "./AddressPoint";
import { ButtonKinds } from "./enums";
import xlsHelper from './generateXLS';
import Icons from '../../base/icons';
import { toast } from 'react-toastify';
import I18N from '../../base/i18n';

const FileUpload = (props) => {
  const [dataxlsx, setDataxlsx] = useState();
  // Колонки которые используются в файле импорта\экспорта
  const columns = [
    // { header: I18N.FILE_POINT_NAME, key: 'name' },
    { header: I18N.FILE_POINT_ADDRESS, key: 'address' },
    { header: I18N.FILE_POINT_LAT, key: 'lat' },
    { header: I18N.FILE_POINT_LON, key: 'lon' },
    { header: I18N.FILE_POINT_WORK_START, key: 'work_start' },
    { header: I18N.FILE_POINT_WORK_END, key: 'work_end' },
    { header: I18N.FILE_POINT_SHIPPING_TIME, key: 'shipping_time' },
    { header: I18N.FILE_POINT_COMMENT, key: 'comment' },
  ];
  //  Обработка инпута при добавлении файла
  const handleFile = async (e) => {
    if (e.target) {
      // Берём целевой файл
      const blob = new Blob([e.target.files[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
      const buffer = await blob.arrayBuffer();
      const workbook = new ExcelJs.Workbook();
      workbook.xlsx.load(buffer)
      .then(() => {
        const worksheet = workbook.getWorksheet(1);
        worksheet.columns = columns;
        let points = [];
        if (worksheet.rowCount < 2) {
          toast.warn(`Нет данных для загрузки.`);
          return;
        }

        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1 && row.values.length && row.getCell('address').value) {
            const coords = row.getCell('lat').value && row.getCell('lon').value ? [Number(row.getCell('lat').value), Number(row.getCell('lon').value)] : [0,0];
            points.push({
              id: rowNumber,
              address: row.getCell('address').value,
              lat_lon: coords,
              work_start: row.getCell('work_start').value ? (row.getCell('work_start').value.getUTCHours() + row.getCell('work_start').value.getUTCMinutes()/60) : null,
              work_end: row.getCell('work_end').value ? (row.getCell('work_end').value.getUTCHours() + row.getCell('work_end').value.getUTCMinutes()/60) : null,
              shipping_time: row.getCell('shipping_time').value ? (row.getCell('shipping_time').value.getUTCHours() + row.getCell('shipping_time').value.getUTCMinutes()/60) : null,
              garage: false,
              comment: row.getCell('comment').value,
            });
          }
        });
        // props.resetAddresses();
        props.addMassPoint(points);
      })
      .catch((e) => {
        console.error(e.message);
      })
    };
  }

  const _generateReport = () => {
    xlsHelper.getTemlate()
      .then(() => toast.success(`${I18N.CONTROLS_REPORT_DOWNLOAD}. ${I18N.NOTIFY_SUCCESS}`))
      .catch(() => toast.error(`${I18N.CONTROLS_REPORT_DOWNLOAD}. ${I18N.NOTIFY_ERROR}`));
  }

  return (<div className={("Controls__Wrapper")}>
    <div className={("Controls__inWrapper")}>
      <input type="file"
        onChange={(e) => handleFile(e)}
        style={{ display: 'none' }}
        id="contained-button-file"
      />
      <label htmlFor="contained-button-file">
        <Button
          icon={Icons.CALCULATION_DOWNLOAD}
          kind={ButtonKinds.CONFIRM}
        // onClick={()=>postData("url",dataxlsx)}
        >
          {I18N.SUBMIT_LOAD_EXCEL_POINTS}
        </Button>
      </label>
      <Button
        icon={Icons.CALCULATION_DOWNLOAD}
        onClick={() => { _generateReport() }}
      >{I18N.SUBMIT_DOWNLOAD_FILE_TEMPLATE}</Button>
    </div>
  </div>
  );
};

export default FileUpload;
