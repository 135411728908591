import React from 'react';
import block from 'bem-cn-lite';

import { actions } from '../../actions';

const b = block('ModalWindow');

export default
class ModalWindow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      content: null,
      contentProps: null,
    }
  }

  componentDidMount() {
    this.showModalEvent = actions.showModal.subscribe((s, action) =>
      this.setState({
        opened: true,
        content: action.content,
        contentProps: action.contentProps,
      })
    );
  }

  componentWillUnmount() {
    this.showModalEvent.unsubscribe();
  }

  closeModal = () => this.setState({
    opened: false,
    content: null,
  });

  _handleClick = (e) => {
    if (e.target.id === 'modal_overlay')
      this.closeModal();
  }

  render() {
    if (!this.state.opened || !this.state.content)
      return false;

    const Content = this.state.content;
    return <div className={b()}>
      <div id='modal_overlay' className={b('Overlay')} onClick={this._handleClick}>
      <div className={b('WindowDynamicIndent')} />
        <div className={b('Window')}>
          <Content
            {...this.state.contentProps}
            closeModal={this.closeModal}
          />
        </div>
      </div>
    </div>;
  }
}
