import React from 'react';
import { isMobileDevice } from '../../../common/helpers/misc'
import AccountBadge from '../../../common/components/AccountBadge'
import Icon from '../../../common/components/Icon';
import Icons from '../../../base/icons';

export default
class MapHeader extends React.Component{
  render() {
    return(
      <header className={this.props.className} style={{ zIndex: 998 }}>
        <Icon svgName={Icons.LOGO}/>
        <div>
          <h1 class="titelh1" title="Построение оптимального (короткого, быстрого) маршрута по нескольким точкам, планирование пути и расчет расстояний">Построение оптимального маршрута по нескольким точкам</h1>
        </div>
        <div style={{width: "auto"}} class="nav" id="nav">
          <ul>
            <li style={isMobileDevice() ? {margin: "0px"} : {}}><a href="/poncy/index.html#top_content">О проекте</a></li>
            {!isMobileDevice() && <li><a href="/poncy/index.html#service">Как это работает</a></li>}
            {!isMobileDevice() && <li><a href="/poncy/index.html#Portfolio">Библиотека</a></li>}
            {/* {!isMobileDevice() && <li><a href="/poncy/index.html#pricing">Тарифы</a></li>} */}
          </ul>
        </div>
        <AccountBadge/>
      </header>
      
    );
  }
}