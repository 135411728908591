import React, { useEffect, useState } from "react";
import {
  TextField,
  InputLabel,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
// import { PropTypes } from "prop-types";
// Components
// Utils
import { VALIDATE } from "../helpers/validate";
import { toast } from "react-toastify";
import I18N from "../../base/i18n";
//
// import { ENDPOINTS } from "../../base/api";

const BannerPhoneInput = (props) => {
  // console.log(props);
  //
  const [phone, setPhone] = useState("");
  // Validation Error
  const [error, setError] = useState(false);
  //
  // useEffect(()=>{
  //   if(props.user){
  //     setPhone(props.user.phone)
  //   }
  // },[])

  const validateSend = () => {
    let v = VALIDATE.ruPhone(phone);
    console.log(v,"VALIDATE");
    if (!v) {
      setError(true);
    } else {
      setError(false);
      //357824687
      // fetch(
      //   `https://api.telegram.org/bot5677699875:AAG06BRBFfCf-yHZ4E6nOS1VkDPvGrdH9Es/sendMessage?chat_id=-851386537&text=${phone}`
      // );
      props.action();
      toast.success(I18N.PHONE_SEND_SUCCESS);
    }
  };

  return (
    <div
      style={{
        width: 800,
        margin: "auto",
        left: "50%",
        top: "42%",
        backgroundColor: "#f3f6f4",
        padding: 30,
        transform: "translate(" - (42 % ",") - (50 % ")"),
      }}
    >
      <div style={{ position: "relative" }}>
        <IconButton
          style={{ position: "absolute", top: "-26px", right: "-22px" }}
          // onClick={() => this.setState({ open: !this.state.open })}
          onClick={() => props.action()}
        >
          <Cancel />
        </IconButton>
        <Box>
          <InputLabel>{ I18N.PHONE_NUMBER_LABEL }</InputLabel>
          <TextField
            id="phone-input"
            error={error}
            value={phone}
            helperText={!error ? "" : I18N.TYPE_PHONE}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Box>
        <Box style={{ marginTop: "20px" }}>
          <Button variant="contained" color="primary" onClick={validateSend}>
            { I18N.SUBMIT_SEND }
          </Button>
        </Box>
      </div>
    </div>
  );
};

// BannerPhoneInput.propTypes = {
//   phone: PropTypes.string,
// };

export default BannerPhoneInput;
