import React from 'react';
import TagManager from 'react-gtm-module'
import queries from '../../base/queries'

const tagManagerArgs = (gtm) => {
  let tagManagerArgs = {
    gtmId: 'GTM-MTFSH2T',
    dataLayer: {
      event: gtm,
    }
  }
  return tagManagerArgs;
}

export const InitMetric = {
  firstVisit: new Date(),
  points: '',
  twoAndMoreRoute: false,
  nextDay: false,
  routelist: false,
  firstQuizStatus: false,
  countRoute: 0,
}

const yandexEvent = (yName, pointsM) => {
  if (yName === 'buildRoute') {
    let dataLS = JSON.parse(localStorage.getItem('appUserHistory'));
    if (dataLS.countRoute == 0) { //Пользователь построил 1 маршрут
      /* let appUserHistory = {
        ...dataLS,
        points: Object.keys(pointsM).length,
        countRoute: 1,
      } */
      let qUU = {
        points: Object.keys(pointsM).length,
        countRoute: 1,
      }
      /* localStorage.setItem( 'appUserHistory', JSON.stringify(appUserHistory) ); */
      queries.UPDATE_USER(qUU);
      TagManager.initialize(tagManagerArgs('oneRoute'));
    }
    if (dataLS.countRoute > 0) {
      let appUserHistory = {
        countRoute: ++dataLS.countRoute,
      }
      dataLS.countRoute++;
      //localStorage.setItem('appUserHistory', JSON.stringify(dataLS));
      queries.UPDATE_USER(appUserHistory);
    }

    if (localStorage.getItem('appUserHistory')) {
      //let dataLS = JSON.parse(localStorage.getItem('appUserHistory'));
      if ((!dataLS.twoAndMoreRoute) && (Object.keys(pointsM).length !== dataLS.points) && (dataLS.countRoute > 0)) { //Пользователь построил 2 и более маршрутов
        dataLS = {
          ...dataLS,
          twoAndMoreRoute: true,
        }
        //localStorage.setItem('appUserHistory', JSON.stringify(dataLS));
        queries.UPDATE_USER({
          'twoAndMoreRoute': true
        });
        TagManager.initialize(tagManagerArgs('severalroutes'));
      }

      let dateLS = new Date(dataLS.firstVisit);
      let dateNow = new Date();
      dateNow.setDate(dateNow.getDate() - 1);
      if ((dateNow > dateLS) && (!dataLS.nextDay)) { // Пользователь заходил 2 и более дней
        dataLS = {
          ...dataLS,
          nextDay: true,
        }
        //localStorage.setItem('appUserHistory', JSON.stringify(dataLS));
        queries.UPDATE_USER({
          'nextDay': true
        });
        TagManager.initialize(tagManagerArgs('repeat'));
      }

      if ((Object.keys(pointsM).length > 19) && (dataLS.points !== 0)) { //Пользователь строил маршруты на более 20 адресов
        dataLS = {
          ...dataLS,
          points: 0,
        }
        //localStorage.setItem('appUserHistory', JSON.stringify(dataLS));
        queries.UPDATE_USER({
          'points': 0
        });
        TagManager.initialize(tagManagerArgs('20addresses'));
      }
    }
  } // yName  buildRoute

  if (yName === 'routelist') { // Пользователь скачал маршрутный лист
    let dataLS = JSON.parse(localStorage.getItem('appUserHistory'));
    if (!dataLS.routelist) {
      dataLS = {
        ...dataLS,
        routelist: true,
      };
      //localStorage.setItem('appUserHistory', JSON.stringify(dataLS));
      queries.UPDATE_USER({
        'routelist': true
      });
      TagManager.initialize(tagManagerArgs(yName));
    }
  }
  if (yName === 'registration') {
    TagManager.initialize(tagManagerArgs(yName));
  }
  if (yName === 'FirstQuiz') {
    TagManager.initialize(tagManagerArgs(yName));
  }
  if (yName === 'Successfully_built_route') {
    TagManager.initialize(tagManagerArgs(yName));
  }
  
}
export default yandexEvent;