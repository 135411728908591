import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import L from "leaflet";

import polyUtil from '@mapbox/polyline';
import WaybillPoint from '../../../common/helpers/WaybillPoint';
import PolylineDecorator from "./PolylineDecorator";

const b = block('LeafletMap');

const directionPattern = [
  {
    offset: "2%",
    endOffset: '2%',
    repeat: 50,
    symbol: L.Symbol.arrowHead({
      pixelSize: 4,
      polygon: false,
      fillOpacity: 1,
      headAngle: 60,
      pathOptions: { stroke: true, color: '#FFF', weight: 2 }
    })
  }
];

export default
class LeafletMapRoutesLayer extends React.Component {
  static propTypes = {
    waybillArray: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.instanceOf(WaybillPoint).isRequired)),
  };

  render() {
    const colors = ["maroon", "cornflowerblue", "firebrick", "seagreen", "violet", "tomato", "orange", "indigo", "chocolate", "gold"]
    const result = [];
    result.push(this.props.waybillArray.filter((p) => p.geometry)
      .reduce((acc, p) => [
        ...acc,
        <PolylineDecorator
          key={'_' + Math.random().toString(36).substr(2, 9)}
          className={b('Route')}
          positions={polyUtil.decode(p.geometry)}
          patterns={directionPattern}
          color={colors[Number(p.agent)]}
        />
      ],
      []))
    return result;
  }
}
