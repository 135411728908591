import { makeReducer } from 'base/reducer';
import AddressPoint from './common/helpers/AddressPoint';
import I18N from './base/i18n';
import WaybillPoint from './common/helpers/WaybillPoint';
import ManifestStatistics from './common/helpers/ManifestStatistics';
import Agent from './common/helpers/Agent';
// Enum
import { Agents } from './common/helpers/enums';
import { EmojiObjects, SignalCellularNoSimOutlined } from '@material-ui/icons';
import { assertNullableType } from 'graphql';

export
  const MAX_POINTS = 30; //Максимальное кол-во точек в расчете

export
  const FREE_MAX_POINTS = 10; //Максимальное кол-во точек в расчете для нерегестрированного пользователя

export
  const DEFAULT_LOCATION = [40, 80];

export
  const ID_START = "1";

export
  const ID_FINISH = String(MAX_POINTS);

export
  const MAX_FREE_CALCULATION = 3;

const getFreeId = (pointsHash) => {
  let pointIdCounter = 2;
  while (pointsHash[pointIdCounter])
    pointIdCounter++;
  return pointIdCounter;
}

const agentsTimeStart = 8; //Время начала работы курьера(первой точки) 8:00

const init = {
  lastStartedManifestId: null,
  showUpdateBanner: true,
  currentManifestID: null,
  savedManifests: [],
  statistics: null,
  waybillPoints: {},
  waybillArray: [],
  points: {
    "1": new AddressPoint("1", null, null, false, 8, 23.99, 15, true),
    "2": new AddressPoint("2", null, null),
    // [ID_FINISH]: new AddressPoint(ID_FINISH, null, null)
  },
  agents: {
    [ID_START]: new Agent(ID_START, '', '', Agents.CAR, null, 8, 23.99, "1")
  },
  limitExceeded: false,
  highlightedPoint: null,
  pointFocused: false,
  currentLocation: DEFAULT_LOCATION,
  default: {
    agent: new Agent("0", '', '', Agents.CAR, null, agentsTimeStart, 23.99),
    point: new AddressPoint("0", null, null, null, agentsTimeStart),
  },
  freeCalculation: 0,
};
Object.freeze(init);

export const reducer = makeReducer('app', init);

export const actions = {
  'loginUser': reducer.makeAction(
    (user, token) => ({ user, token }),
    (state, action, store) => {
      store({ token: action.token, user: action.user });
      return { ...state, token: action.token, user: action.user };
    }
  ),

  'logoutUser': reducer.makeAction(
    null,
    (state, _, store) => {
      store({ token: null, user: null });
      return { ...state, token: null, user: null };
    }
  ),

  'updateUser': reducer.makeAction(
    (user, key, value) => ({ oldUser: user, key: key, value: value }),
    (state, action, store) => {
      action.oldUser[action.key] = action.value
      store({ user: action.oldUser });
      return { ...state, user: action.oldUser };
    }
  ),

  'addAgent': reducer.makeAction(
    (name, phone) => ({ name: name, phone: phone }),
    (state, action, store) => {
      const agentFreeId = getFreeId(state.agents);
      const pointFreeId = getFreeId(state.points);
      store({
        ...state,
        agents: {
          ...state.agents,
          [agentFreeId]: new Agent(
            String(agentFreeId),
            action.name || '',
            action.phone || '',
            action.delType || state.default.agent.delType,
            action.maxPoints || state.default.agent.maxPoints,
            action.workStart || state.default.agent.workStart,
            action.workEnd || state.default.agent.workEnd,
            pointFreeId,  // garageID
          )
        },
        points: {
          ...state.points,
          [pointFreeId]: new AddressPoint(
            String(pointFreeId),
            action.address || "",
            action.coords || null,
            !!action.coords,
            action.workStart || state.default.agent.workStart,
            action.workEnd || state.default.agent.workEnd,
            0,  // shipping_time
            true,  // garage
          )
        }
      });
      return {
        ...state,
        agents: {
          ...state.agents,
          [agentFreeId]: new Agent(
            String(agentFreeId),
            action.name || '',
            action.phone || null,
            action.delType || state.default.agent.delType,
            action.maxPoints || state.default.agent.maxPoints,
            action.workStart || state.default.agent.workStart,
            action.workEnd || state.default.agent.workEnd,
            pointFreeId,  // garageID
          )
        },
        points: {
          ...state.points,
          [pointFreeId]: new AddressPoint(
            String(pointFreeId),
            "",  // address
            null,  // geocoded
            !!action.coords,
            action.workStart || state.default.agent.workStart,
            action.workEnd || state.default.agent.workEnd,
            0,  // shipping_time
            true,  // garage
          )
        }
      };
    },
    'ADD_AGENT'
  ),

  'removeAgent': reducer.makeAction(
    (id) => ({ id: String(id) }),
    (state, action, store) => {
      const garageID = state.agents[action.id].garageID;

      const newPoints = { ...state.points };
      delete newPoints[garageID];

      const newAgents = { ...state.agents };
      delete newAgents[action.id];
      store({ agents: newAgents, points: newPoints });
      return {
        ...state,
        agents: newAgents,
        points: newPoints
      };
    },
    'REMOVE_AGENT'
  ),

  'updateDefault': reducer.makeAction(
    (instance, key, value) => ({ instance: instance, key: key, value: value }),
    (state, action) => {
      const defaultValues = state.default;
      defaultValues[action.instance][action.key] = action.value;
      return {
        ...state,
        default: {
          ...defaultValues
        }
      }
    },
    "UPDATE_DEFAULT"
  ),

  'updateAgentName': reducer.makeAction(
    (id, name, phone, delType, maxPoints, notReturning) => (
      { id: String(id), name: name, phone: phone, delType: delType,
        maxPoints: maxPoints, notReturning: notReturning }
    ),
    (state, action, store) => {
      store({
        agents: {
          ...state.agents,
          [action.id]: new Agent(
            action.id,
            action.name,
            action.phone,
            action.delType,
            action.maxPoints,
            state.agents[action.id].workStart,
            state.agents[action.id].workEnd,
            state.agents[action.id].garageID,  // garageID
            action.notReturning,
          )
        }
      })
      return {
        ...state,
        agents: {
          ...state.agents,
          [action.id]: new Agent(
            action.id,
            action.name,
            action.phone,
            action.delType,
            action.maxPoints,
            state.agents[action.id].workStart,
            state.agents[action.id].workEnd,
            state.agents[action.id].garageID,  // garageID
            action.notReturning,
          )
        }
      }
    },
    "UPDATE_AGENT_NAME"
  ),

  'updateAgentTime': reducer.makeAction(
    (id, workStart, workEnd) => ({ id: String(id), workStart: workStart, workEnd: workEnd }),
    (state, action, store) => {
      const updatedTimeAgents = action.id === "0" ?
        {
          point: state.default.point,
          agent: new Agent(
            action.id,
            state.default.agent.name,
            state.default.agent.phone,
            state.default.agent.delType,
            state.default.agent.maxPoints,
            action.workStart,
            action.workEnd,
            state.agents[action.id].garageID,  // garageID
          )
        }
        : {
          ...state.agents,
          [action.id]: new Agent(
            action.id,
            state.agents[action.id].name,
            state.agents[action.id].phone,
            state.agents[action.id].delType,
            state.agents[action.id].maxPoints,
            action.workStart,
            action.workEnd,
            state.agents[action.id].garageID,  // garageID
          )
        };
      return action.id === "0" ?
        {
          ...state,
          default: updatedTimeAgents
        }
        : {
          ...state,
          agents: updatedTimeAgents
        }
      // if (updatedTimePoints[action.id].coords)
      //   store({ points: updatedTimePoints });
      // return { ...state, points: updatedTimePoints };
    },
    'UPDATE_AGENT_TIME'
  ),

  'addPoint': reducer.makeAction(
    (address, coords, garage) => ({ address: address, coords: coords || null, garage: garage }),
    (state, action, store) => {
      const freeId = getFreeId(state.points);
      store({
        ...state,
        points: {
          ...state.points,
          [freeId]: new AddressPoint(
            String(freeId),
            action.address || "",
            action.coords || null,
            !!action.coords,
            state.default.point.workStart,
            state.default.point.workEnd,
            state.default.point.shipping_time,
            action.garage,
          )
        }
      });
      return {
        ...state,
        points: {
          ...state.points,
          [freeId]: new AddressPoint(
            String(freeId),
            action.address || "",
            action.coords || null,
            !!action.coords,
            state.default.point.workStart,
            state.default.point.workEnd,
            state.default.point.shipping_time,
            action.garage,
          )
        }
      };
    },
    'ADD_POINT'
  ),

  'addMassPoint': reducer.makeAction(
    (points) => ({ points: points }),
    (state, action, store) => {
      const maxPoints = MAX_POINTS - Object.keys(state.points).length;
      let newPoints = state.points;
      const defPoint = state.default.point;

      action.points
      .splice(0, maxPoints)
      .map((newPoint) => {
        const freeId = getFreeId(newPoints);
        const address = new AddressPoint(
          freeId,
          newPoint.address,
          newPoint.lat_lon,
          Boolean(newPoint.lat_lon[0]),
          newPoint.work_start || defPoint.workStart,
          newPoint.work_end || defPoint.workEnd,
          Math.round(newPoint.shipping_time * 60) || defPoint.shipping_time,
          newPoint.garage || newPoint.depot,
          newPoint.comment
        )
        newPoints = {
          ...newPoints,
          [freeId]: address,
        }
      })
      store({
        ...state,
        points: {
          ...newPoints
        }
      });
      return {
        ...state,
        points: {
          ...newPoints
        }
      };
    },
    'ADD_MASS_POINT'
  ),

  'setFirstPointToGarage': reducer.makeAction(
    null,
    (state, _, store) => {
      const firstPoint = state.points["1"];
      store({
        ...state,
        points: {
          ...state.points,
          ["1"]: new AddressPoint(
            firstPoint.id,
            firstPoint.address,
            firstPoint.coords,
            firstPoint.coords,
            firstPoint.workStart,
            firstPoint.workEnd,
            firstPoint.shipping_time,
            true,
          )
        }
      });
      return {
        ...state,
        points: {
          ...state.points,
          ["1"]: new AddressPoint(
            firstPoint.id,
            firstPoint.address,
            firstPoint.coords,
            firstPoint.coords,
            firstPoint.workStart,
            firstPoint.workEnd,
            firstPoint.shipping_time,
            true,
          )
        }
      };
    },
    'SET_FIRST_POINT_TO_GARAGE'
  ),

  'updatePointAddress': reducer.makeAction(
    (id, address, coords) => ({ id: String(id), address: address, coords: coords }),
    (state, action) => ({
      ...state,
      points: {
        ...state.points,
        [action.id]: new AddressPoint(
          action.id,
          action.address,
          action.coords || null,
          !!action.coords,
          state.points[action.id].workStart,
          state.points[action.id].workEnd,
          state.points[action.id].shipping_time,
          state.points[action.id].garage,
        )
      }
    }),
    'UPDATE_POINT_ADDRESS'
  ),

  'updatePointComment': reducer.makeAction(
    (id, comment) => ({ id: String(id), comment: comment }),
    (state, action, store) => {
      const updatedCommentPoints = {
        ...state.points,
        [action.id]: new AddressPoint(
          action.id,
          state.points[action.id].address,
          state.points[action.id].coords,
          state.points[action.id].geocoded,
          state.points[action.id].workStart,
          state.points[action.id].workEnd,
          state.points[action.id].shipping_time,
          state.points[action.id].garage,
          action.comment,
        )
      };

      if (updatedCommentPoints[action.id].coords)
        store({ points: updatedCommentPoints });
      return { ...state, points: updatedCommentPoints };
    },
    'UPDATE_POINT_COMMENT'
  ),

  'updatePointCoords': reducer.makeAction(
    (id, coords) => ({ id: String(id), coords: coords }),
    (state, action, store) => {
      const geocodedPoints = {
        ...state.points,
        [action.id]: new AddressPoint(
          action.id,
          state.points[action.id].address,
          action.coords,
          true,
          state.points[action.id].workStart,
          state.points[action.id].workEnd,
          state.points[action.id].shipping_time,
          state.points[action.id].garage,
        )
      };

      const newWaybillPoints = { ...state.waybillPoints };
      delete newWaybillPoints[action.id];

      store({
        points: geocodedPoints,
        waybillPoints: newWaybillPoints,
      });
      return {
        ...state,
        points: geocodedPoints,
        waybillPoints: newWaybillPoints,
      };
    },
    'UPDATE_POINT_COORDS'
  ),

  'updatePointTime': reducer.makeAction(
    (id, workStart, workEnd, shipping_time) => ({ id: String(id), workStart: workStart, workEnd: workEnd, shipping_time: shipping_time }),
    (state, action, store) => {
      const updatedTimePoints = {
        ...state.points,
        [action.id]: new AddressPoint(
          action.id,
          state.points[action.id].address,
          state.points[action.id].coords,
          state.points[action.id].geocoded,
          action.workStart,
          action.workEnd,
          action.shipping_time,
          state.points[action.id].garage,
        )
      };

      if (updatedTimePoints[action.id].coords)
        store({ points: updatedTimePoints });
      return { ...state, points: updatedTimePoints };
    },
    'UPDATE_POINT_TIME'
  ),

  'removePoint': reducer.makeAction(
    (id) => ({ id: String(id) }),
    (state, action, store) => {
      const newPoints = { ...state.points };
      delete newPoints[action.id];

      store({ points: newPoints });
      return {
        ...state,
        limitExceeded: Object.values(newPoints).length >= MAX_POINTS,
        points: newPoints
      };
    },
    'REMOVE_POINT'
  ),

  'updateMassPoints': reducer.makeAction(
    (points) => ({ points: points }),
    (state, action) => {
      const pointsCount = action.points.length;
      const newPoints = action.points
        .splice(0, MAX_POINTS)
        .reduce((acc, p, i) => {
          if (state.points[p.id]) {
            if (state.points[p.id].address !== p.address)
              acc[p.id] = p;
            else
              acc[p.id] = state.points[p.id];
          }
          else {
            let freeId = ID_START;

            if (i === pointsCount - 1 && p.address)
              freeId = ID_FINISH
            else if (i !== 0)
              freeId = getFreeId(acc);

            acc[freeId] = new AddressPoint(String(freeId), p.address || "")
          }
          return acc;
        }, {});

      newPoints[ID_START] = !newPoints[ID_START] ? state.points[ID_START] : newPoints[ID_START];
      newPoints[ID_FINISH] = !newPoints[ID_FINISH] ? state.points[ID_FINISH] : newPoints[ID_FINISH];

      return { ...state, points: newPoints };
    },
    'UPDATE_MASS_POINTS'
  ),

  'updatePointsIds': reducer.makeAction(
    (points) => ({ points: points }),
    (state, action, store) => {
      const newPoints = action.points.reduce((allPoints, resPoint) => ({
        ...allPoints,
        [resPoint.id]: new AddressPoint(resPoint.id, resPoint.address, resPoint.lat_lon,
          Boolean(resPoint.lat_lon[0]), resPoint.work_start, resPoint.work_end,
          Math.round(resPoint.shipping_time * 60), resPoint.garage || resPoint.depot,
          resPoint.comment),
      }), {})
      store({
        points: newPoints
      });
      return { ...state, points: newPoints };
    },
    'UPDATE_POINTS_IDS'
  ),

  'updateAgentOnDBSave': reducer.makeAction(
    (agents) => ({ agents: agents }),
    (state, action, store) => {
      const newAgents = action.agents.reduce((allAgents, resAgent) => ({
        ...allAgents,
        [resAgent.id]: new Agent(resAgent.id, resAgent.name, resAgent.phone, resAgent.vehicle_type,
          resAgent.max_points, resAgent.work_start, resAgent.work_end, String(resAgent.garage_id), resAgent.not_returning),
      }), {});
      store({
        agents: newAgents
      });
      return { ...state, agents: newAgents };
    },
    'UPDATE_AGENT_ON_DB_SAVE'
  ),

  'updateWaybillData': reducer.makeAction(
    (waybillPoints) => ({ waybillPoints: waybillPoints }),
    (state, action, store) => {
      const waybillPoints = action.waybillPoints.reduce((allWaybillPoints, waybillPoint) => ({
        ...allWaybillPoints,
        [waybillPoint.point_id]: new WaybillPoint(waybillPoint),
      }), {});
      const waybillArray = action.waybillPoints.reduce((allWaybillPoints, waybillPoint) => [
        ...allWaybillPoints,
        new WaybillPoint(waybillPoint),
      ], []);

      store({
        waybillPoints: waybillPoints,
        waybillArray: waybillArray,
      });
      return {
        ...state,
        waybillPoints: waybillPoints,
        waybillArray: waybillArray,
        lastStartedManifestId: null,
      }
    },
    'UPDATE_WAYBILL_DATA'
  ),

  'updateStatistics': reducer.makeAction(
    (manifest) => ({ manifest: manifest }),
    (state, action, store) => {
      const statistics = new ManifestStatistics(action.manifest);
      store({
        statistics: statistics,
      });
      return {
        ...state,
        statistics: statistics,
      }
    },
    'UPDATE_STATISTICS'
  ),

  'setLastStartedManifest': reducer.makeAction(
    (manifestId) => ({ manifestId: manifestId }),
    (state, action) => ({
      ...state,
      lastStartedManifestId: action.manifestId
    }),
    'SET_LAST_STARTED_MANIFEST'
  ),

  'setCurrentManifestID': reducer.makeAction(
    (manifestId) => ({ manifestId: manifestId }),
    (state, action, store) => {
      store({ currentManifestID: action.manifestId });
      return {
        ...state,
        currentManifestID: action.manifestId
      };
    },
    'SET_CURRENT_MANIFEST_ID'
  ),

  'updateSavedManifests': reducer.makeAction(
    (manifestId, manifestName) => ({ id: manifestId, name: manifestName }),
    (state, action) => ({
      ...state,
      savedManifests: action
    }),
    'UPDATE_SAVED_MANIFESTS'
  ),

  'resetAddresses': reducer.makeAction(
    null,
    (state, _, store) => {
      const defPoint = state.default.point;
      const points = {
        "1": new AddressPoint("1", null, null, false, defPoint.workStart, defPoint.workEnd, defPoint.shipping_time, true),
        "2": new AddressPoint("2", null, null, false, defPoint.workStart, defPoint.workEnd, defPoint.shipping_time),
        // [ID_FINISH]: new AddressPoint(ID_FINISH, null, null)
      };
      store({
        points: points,
        statistics: null,
        waybillPoints: {},
        waybillArray: [],
      });
      return {
        ...state,
        points: points,
        statistics: null,
        waybillPoints: {},
        waybillArray: [],
      }
    },
    'RESET_ADDRESSES'
  ),

  'resetWaybills': reducer.makeAction(
    null,
    (state, _, store) => {
      store({
        statistics: null,
        waybillPoints: {},
        waybillArray: [],
      });
      return {
        ...state,
        statistics: null,
        waybillPoints: {},
        waybillArray: [],
      }
    },
    'RESET_WAYBILLS'
  ),

  'resetAgents': reducer.makeAction(
    null,
    (state, _, store) => {
      const defAgent = state.default.point;
      const agents = {
        [ID_START]: new Agent(ID_START, '', '', defAgent.delType, defAgent.maxPoints, defAgent.workStart, defAgent.workEnd, "1")
      };
      store({
        agents: agents
      });
      return {
        ...state,
        agents: agents,
      }
    },
    'RESET_AGENTS'
  ),

  'showModal': reducer.makeAction(
    (content, contentProps) => ({ content, contentProps }),
    null,
    'SHOW_MODAL'
  ),

  'hoverPoint': reducer.makeAction(
    (pointId = null) => ({ pointId: pointId }),
    (state, action) => ({
      ...state,
      highlightedPoint: state.pointFocused ? state.highlightedPoint : action.pointId
    }),
    'HOVER_POINT'
  ),

  'focusPoint': reducer.makeAction(
    (pointId = null) => ({ pointId: pointId }),
    (state, action) => ({
      ...state,
      highlightedPoint: action.pointId,
      pointFocused: Boolean(action.pointId)
    }),
    'FOCUSED_POINT'
  ),

  'setCurrentLocation': reducer.makeAction(
    (coords) => ({ coords: coords }),
    (state, action) => ({
      ...state,
      currentLocation: action.coords,
    }),
    'SET_CURRENT_LOCATION'
  ),

  'setNavBarStatus': reducer.makeAction(
    (NavBarStatus) => ({ NavBarStatus: NavBarStatus }),
    (state, action) => ({
      ...state,
      NavBarStatus: action.NavBarStatus,
    }),
    'SET_NAVBAR_STATUS'
  ),

  'sortPoints': reducer.makeAction(
    // Сортирует точки в списке на сайте, чтобы они отображались в том же порядке что и построенный маршрут
    (oldPosition, newPosition) => ({ oldPosition, newPosition }),
    (state, action, store) => {
      const newPoints = {}
      const newPP = action.newPosition
      for (let i = 0; i < action.newPosition.length; i++) {
        let coords = []
        coords.push(action.newPosition[i].lat)
        coords.push(action.newPosition[i].lon)
        Object.values(action.oldPosition)
          .map((row, j) => {
            if (row.id === ID_START) {
              newPoints[row.id] = row
            }
            if (row.coords[0] === coords[0] && row.coords[1] === coords[1] && row.id !== ID_START && row.id !== ID_FINISH) {
              row.id = i + 1
              newPoints[i + 1] = row
              action.newPosition[i].id = i + 1
              newPP[i] = action.newPosition[i]
            }
            if (row.id === ID_FINISH) {
              newPoints[row.id] = row
            }
          })
      }
      store({
        points: newPoints,
      });
      return {
        ...state,
        points: newPoints,
        waybillPoints: newPP,
      }
    },
    'SORT_POINTS'
  ),

  'showUpdateBannerState': reducer.makeAction(
    (updateBannerState) => ({ updateBannerState: updateBannerState }),
    (state, action, store) => {
      store({
        showUpdateBanner: action.updateBannerState,
      });
      return {
        ...state,
        showUpdateBanner: action.updateBannerState,
      }
    },
    'SHOW_UPDATE_BANNER_STATE'
  ),

  'increaseFreeCalculationLimit': reducer.makeAction(
    () => ({}),
    (state, action, store) => {
      const newLimit = state.freeCalculation + 1;
      store({
        freeCalculation: newLimit,
      });
      return {
        ...state,
        freeCalculation: newLimit,
      }
    },
    'INCREASE_FREE_CALCULATION_LIMIT'
  ),
};