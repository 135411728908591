import { pointsSort } from "./misc";

export default
class ManifestTask {
  constructor(agents, points, withJams) {
    this.sortedPoints = Object
      .values(points)
      .sort(pointsSort);
    this.agents = Object.values(agents);

    this.manifestDef = {
      'optimization': "mintime",
      'task_type': "optimize",
      'runs': 1,
      'by_zone': false,
      'clusterization': "none",
      'distribution': true,
      'maxiter': 5000,
      'n_days': 30,
      'speed_coeff': 1,
      'traffic_jams': withJams,
      'zones': [],
      'points': this.sortedPoints
        .map((p, i) =>({
          'id': Number(p.id),
          'lat_lon': p.coords,
          'work_start': p.workStart,
          'work_end': p.workEnd,
          'break_start': p.workEnd,
          'break_end': p.workEnd,
          'weight': 0,
          'volume': 0,
          'shipping_time': 0.25,
          'zones': [],
          'garage': p.garage,
          'depot':  p.garage,
          'night_stay': false,
          'mandatory': false,
          'priority': 0,
          'immediate_return': false,
          'curbside': 0,
        })),
      'agents': this.agents
        .map((agent, i) => ({
          'id': Number(agent.id),
          'work_start': agent.workStart,
          'work_end': agent.workEnd,
          'break_start': agent.workEnd,
          'break_end': agent.workEnd,
          'max_points': agent.maxPoints,
          'max_weight': 0,
          'max_volume': 0,
          'zones': [],
          'speed_coeffs': [1, 1, 1, 1],
          'through_depot': false,
          'not_returning': agent.notReturning,
          'longhaul': true,
          'single_run': false,
          'vehicle_type': agent.delType,
          'cost_round': 0,
          'cost_hour': 0,
          'cost_km': 0,
          'cost_kgkm': 0,
          'cost_point': 0,
          'depot_id': Number(agent.garageID),
          'garage_id': Number(agent.garageID),
          //'last_point_id': Number(ID_FINISH),
         // 'last_point_id': Number(this.sortedPoints[this.sortedPoints.length - 1].id),
      }))
    };  }
}