import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonKinds } from '../helpers/enums';
import ConfirmDialog from './ConfirmDialog';
import Button from './Button';
import { actions } from '../../actions';

class ConfirmButton extends React.PureComponent {
  static propTypes = {
    confirmMessage: PropTypes.string.isRequired,
    confirmKind: PropTypes.oneOf(Object.keys(ButtonKinds)),
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  _onConfirm = () => {
    this.props.onClick && this.props.onClick();
  }

  _onClick = () => {
    this.props.actions.showModal(ConfirmDialog, {
      message: this.props.confirmMessage,
      confirmKind: this.props.confirmKind || ButtonKinds.WARNING,
      onConfirm: this._onConfirm
    });
  }

  render() {
    return <Button {...this.props} onClick={this._onClick}/>;
  }
}

export default
connect(
  null,
  (dispatch) => ({
    "actions": bindActionCreators(actions, dispatch)
  })
)(ConfirmButton);