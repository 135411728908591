import React from 'react';
import block from 'bem-cn-lite';
import { divIcon } from 'leaflet';
import { Marker, Tooltip } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';

import { PropTypes } from 'prop-types';
import Icon from 'common/components/Icon';
import Icons from 'base/icons';
import AddressPoint from '../../../common/helpers/AddressPoint';
import WaybillPoint from '../../../common/helpers/WaybillPoint';
import { ID_FINISH, ID_START } from '../../../actions';
import { decimalHoursToTime } from '../../../common/helpers/misc';

const b = block('LeafletMapMarker');

export default
  class LeafletMapMarker extends React.PureComponent {

  static propTypes = {
    point: PropTypes.instanceOf(AddressPoint).isRequired,
    waybillPoint: PropTypes.instanceOf(WaybillPoint),
    updatePointCoords: PropTypes.func,
    highlighted: PropTypes.bool
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.point.coords[0] !== this.props.point.coords[0]
      || prevProps.point.coords[1] !== this.props.point.coords[1]
      || prevProps.highlighted !== this.props.highlighted
      || prevProps.waybillPoint !== this.props.waybillPoint;
  }

  _onChangeMarkerPosition = (e) => {
    const newCoords = [
      Number(e.target._latlng.lat.toFixed(6)),
      Number(e.target._latlng.lng.toFixed(6))
    ];

    this.props.updatePointCoords(this.props.point.id, newCoords);
  }

  _getMarkerIcon = (point) => {
    if (point.garage) {
      return Icons.MARKER_START;
    }
    // if (point.id === ID_FINISH)
    //   return Icons.MARKER_FINISH
    return Icons.MARKER_REGULAR;
  }

  render() {
    const p = this.props.point;
    const wp = this.props.waybillPoint;
    const html = ReactDOMServer.renderToStaticMarkup([
      <Icon key={'icon'} svgName={this._getMarkerIcon(p)} />,
      !p.garage
      && (wp && !wp.errorCode
        ? <div key={'position'} className={b('Position')}>{wp.totalPoints}</div>
        : <div key={'no_position'} className={b('NoPosition')} />),
      !p.garage
      && (<div className={b('VisitedStatus', {
        'InWork': wp && wp.status && wp.status === 1,
        'Success': wp && wp.status && wp.status === 2,
        'Failed': wp && wp.status && wp.status === 5,
      })} />)
    ]);
    const div = divIcon({
      className: b({
        'Courier': wp && !wp.errorCode && String(wp.agent),
        'Failed': wp && Boolean(wp.errorCode),
        'Highlighted': this.props.highlighted
      }),
      html: html
    });

    return <Marker
      draggable={true}
      onmouseover={() => this.props.hoverPoint(p.id)}
      onmouseout={() => this.props.hoverPoint()}
      onDragEnd={this._onChangeMarkerPosition}
      position={{ lat: p.coords[0], lng: p.coords[1] }}
      icon={div}
    >
      {p.address && <Tooltip direction='center' sticky offset={[0, 30]} opacity={1} className={b('Tooltip')}>
        <span>
          <p className={b('ToolTip_Address')}>{p.address}</p>
          {!isNaN(p.workStart) && !isNaN(p.workEnd) && <p>
            Время работы: {decimalHoursToTime(p.workStart)}-{decimalHoursToTime(p.workEnd)}
            {wp && wp.arrivalTime && wp.departureTime ? `, Время прибытия на точку: ${decimalHoursToTime(wp.arrivalTime)}-${decimalHoursToTime(wp.departureTime)}` : ''}
          </p>}
          {wp && wp.comment && <p>Комментарий курьера: {wp.comment}</p>}
        </span>
      </Tooltip>}
    </Marker>;
  }
}
