import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import ControlsManualEditor from './ControlsManualEditor';
import ControlsTextEditor from './ControlsTextEditor';
import ControlsFile from './ControlsFile';
import queries from '../../../base/queries';
import AddressPoint from 'common/helpers/AddressPoint';
import I18N from '../../../base/i18n';
import { toast } from 'react-toastify';

const GEOCODE_INTERVAL = 500;

const b = block('ControlsAddressEditor');

export default
class ControlsAddressEditor extends React.Component {

  static propTypes = {
    manualMode: PropTypes.bool,
    points: PropTypes.objectOf(PropTypes.objectOf(AddressPoint).isRequired),
    addPoint: PropTypes.func,
    updatePointAddress: PropTypes.func,
    updatePointCoords: PropTypes.func,
    removePoint: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.idCounter = 1;
  }

  componentDidMount() {
    this.geocodeTimer = setTimeout(this._geocodeItem, GEOCODE_INTERVAL);
  }

  componentDidUpdate() {
    clearTimeout(this.geocodeTimer);
    this.geocodeTimer = setTimeout(this._geocodeItem, GEOCODE_INTERVAL);
  }

  componentWillUnmount() {
    clearTimeout(this.geocodeTimer);
  }

  _geocodeItem = () => {
    const pointsToGeocode = Object
      .values(this.props.points)
      .filter((p) => p.address 
        && !p.geocoded
        // && p.address !== I18N.MAP_MARKER_START
        // && p.address !== I18N.MAP_MARKER_FINISH
      );

    if (pointsToGeocode) {
      for (const point of pointsToGeocode) {
        queries.GEOCODE_ITEM(point.address)
        .then((res) => {
          if (res && res.coords)
            this.props.updatePointCoords(point.id, res.coords);
          else
            Promise.reject();
        })
        .catch(() => {
          toast.warn(I18N.ERROR_C013);
          this.props.updatePointCoords(point.id, null);
        })
      }
    }
  }

  _runGeocodeQueue = () => {
    this.geocodeQueue = setInterval(() => {
      const i = this.props.points.findIndex((p) => p.geocoded === false);
      if (i >= 0)
        this._geocodeItem(i);
      else
        clearInterval(this.geocodeQueue);
    }, GEOCODE_INTERVAL);
  }

  _modeSwitcher = () => {
    switch(this.props.manualMode){
      case null:{
        return <ControlsFile {...this.props} />
      }
      case true:{
        return <ControlsManualEditor {...this.props} />
      }
      case false:{
        return <ControlsTextEditor {...this.props} />
      }
      default:
        return <ControlsManualEditor {...this.props} />
    }
  }

  render() {
    // console.log(this.props);
    return <div className={b()}>{
      <ControlsManualEditor {...this.props} />
        // this.props.manualMode
          // ? <ControlsManualEditor {...this.props} />
          // : <ControlsTextEditor {...this.props} />
        }</div>;
  }
  //   return(
  //     <div className={b()}>
  //       {this._modeSwitcher()}
  //     </div>
  //   )
  // }
}
