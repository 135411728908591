let API_ROOT = ``;

if (document.location.hostname.match('localhost')) {
  // API_ROOT = `https://api.dev.poncy.su`;
  API_ROOT = `https://poncy-api.n2.zig-zag.org`
}
else {
  API_ROOT = 'https://poncy-api.n2.zig-zag.org';
}

export const ENDPOINTS = {
  login: `${API_ROOT}/login`,
  signup: `${API_ROOT}/registration`,
  restore: `${API_ROOT}/restore`,
  graphql: `${API_ROOT}/graphql`,
  files: `${API_ROOT}/files`,
  geocode: `${API_ROOT}/geocode`,
  geocodeReversed: `${API_ROOT}/reverse_geocode`,
  geocodeHint: `${API_ROOT}/geocode_hint`,
};
