import React from 'react';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import { reducer, actions } from '../../../actions';

import LeafletMapMarker from './LeafletMapMarker';
import { PropTypes } from 'prop-types';
import AddressPoint from '../../../common/helpers/AddressPoint';
import WaybillPoint from '../../../common/helpers/WaybillPoint';
import { pointsSort } from "../../../common/helpers/misc.js";

const b = block('LeafletMap');

class LeafletMapPointsLayer extends React.Component {
  static propTypes = {
    points: PropTypes.objectOf(PropTypes.instanceOf(AddressPoint).isRequired),
    waybillPoints: PropTypes.objectOf(PropTypes.instanceOf(WaybillPoint).isRequired),
    updatePointCoords: PropTypes.func,
    hoverPoint: PropTypes.func,
    highlightedPoint: PropTypes.string,
  }

  render() {
    const result = [];
    const geocodedPoints = Object.values(this.props.points).sort(pointsSort).filter((p) => p.coords);
    for (const p of geocodedPoints)
      result.push(<LeafletMapMarker
        key={p.id}
        point={p}
        highlighted={this.props.highlightedPoint === p.id}
        waybillPoint={this.props.waybillPoints[p.id]}
        updatePointCoords={this.props.updatePointCoords}
        hoverPoint={this.props.hoverPoint}
      />);
    return result;
  }
}

export default
connect(
  (state) => ({
    highlightedPoint: state[reducer.name].highlightedPoint,
  }),
  (dispatch) => ({
    hoverPoint: (id) => dispatch(actions.hoverPoint(id)),
  })
)(LeafletMapPointsLayer);