import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { reducer, actions } from '../../../actions';
import { bindActionCreators } from 'redux';
import Icons from 'base/icons';
import AddressPoint from 'common/helpers/AddressPoint';
import { Icon, TextInput, TimeInput, Button, NumberInput } from 'common/components';
import { ID_START, ID_FINISH } from '../../../actions';
import I18N from '../../../base/i18n';
import queries from '../../../base/queries';
import AddressInput from '../../others/AddressInput';
import PointValidator from "../helpers/PointValidator";
import { TextareaInput } from '../../../common/components';

const b = block('ControlsAddressItem');

class ControlsAddressItem extends React.Component {
  static propTypes = {
    point: PropTypes.instanceOf(AddressPoint).isRequired,
    removePoint: PropTypes.func,
    updatePointAddress: PropTypes.func,
    updatePointComment: PropTypes.func,
    updatePointTime: PropTypes.func,
    highlightedPoint: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.geocodeRequestTimer = null;
    this.fromView = String;

    this.state = {
      showWorktimeEditor: false,
      workStart: props.point.workStart,
      workEnd: props.point.workEnd,
      shipping_time: props.point.workEnd,
      hintSelected: false,
      hintInFocus: undefined,
      hints: [],
      comment: props.point.comment
    }
  }

  _handleWorktimeChange = (name, value) => {
    let newState = {};
    const nameToApiName = {
      "work_start": 'workStart',
      "work_end": 'workEnd',
      "shipping_time": "shipping_time",
    };
    newState[nameToApiName[name]] = value;

    this.setState(newState);
  };

  // check working times and commit
  _commitWorktime = name => {
    let newState = {};
    if (
      (name === 'work_start' && this.state.workStart > this.state.workEnd)
      || (name === 'work_end' && this.state.workEnd < this.state.workStart)
    ) {
      newState.workEnd = this.state.workStart;
    }

    this.setState(newState, () => this._updatePointTime());
  };

  _handleWorktimeBlur = (name, value) => {
    this.props.focusPoint();
    this._commitWorktime(name, value);
  };

  _handleShowWorktimeEditor = () => this.setState({
    showWorktimeEditor: !this.state.showWorktimeEditor,
    shipping_time: this.props.point.shipping_time,
    workStart: this.props.point.workStart,
    workEnd: this.props.point.workEnd,
  });

  _resetWorkTime = () => {
    this.setState({
      shipping_time: this.props.default.point.shipping_time,
      workStart: this.props.default.point.workStart,
      workEnd: this.props.default.point.workEnd,
    });
    const pointDefault = this.props.default.point;
    this.props.updatePointTime(this.props.point.id, pointDefault.workStart,
      pointDefault.workEnd, pointDefault.shipping_time);
  };

  _updatePointTime = () => {
    this.props.updatePointTime(this.props.point.id, this.state.workStart,
      this.state.workEnd, this.state.shipping_time);
  };

  _placeHolderValue = p => {
    if (p.garage) {
      return I18N.MAP_MARKER_START;
    }
    // if (p === ID_START) {
    //   return I18N.MAP_MARKER_START;
    // };
    // if (p === ID_FINISH) {
    //   return I18N.MAP_MARKER_FINISH;
    // };
    return I18N.ADDRESS_DESTINATION;

  }

  _commentOnChange = (name, value) => {
    this.setState({
      [name]: value,
      failureCode: null,
    }, () => this.props.updatePointComment(this.props.point.id, value));
  }

  colors = ["maroon", "cornflowerblue", "firebrick", "seagreen", "violet", "tomato", "orange", "indigo", "chocolate", "gold"]

  render() {
    const p = this.props.point ? this.props.point : {geocoded: true};
    const wp = this.props.waybillPoints[p.id];
    const agentColor = wp && !wp.errorCode ? this.colors[wp.agent] : '';
    const geocodeFiled = PointValidator.hasError(p);
    const errorMessage = geocodeFiled ? I18N.ERROR_C011 : null;
    const icon = p.garage ? Icons.POINT_START : Icons.POINT_REGULAR;

    return <div
      style={{width: '100%'}}
      className={b({ 'Highlighted': p.id === this.props.highlightedPoint })}
      onMouseEnter={() => this.props.hoverPoint(p.id)}
      onMouseLeave={() => this.props.hoverPoint()}
    >
      <Icon
        svgName={icon}
        style={{
          fill: agentColor,
        }}
        className={b('PositionIcon', {
          'Geocoding': !p.geocoded && (wp && !wp.errorCode),
          'GeocodeFailed': geocodeFiled || (wp && Boolean(wp.errorCode))
        })} />
      <div
        className={b('Address', {
          'Garage': p.garage,
          'Geocoding': !p.geocoded,
          'GeocodeFailed': geocodeFiled
        })}>

        <AddressInput
          name={p.id}
          value={p.address}
          onChangeValue={this.props.updatePointAddress}
          placeHolder={this._placeHolderValue(p)}
          {...this.props}
        />
        {/*  <TextInput
          name={p.id}
          value={p.address}
          onChangeValue={this.props.updatePointAddress}
        /> */}
        {!p.garage
        && <Icon
          name={p.id}
          className={b('EditButton', {
            'WorkTime': true,
            'WorkTimeSet': p.workStart !== 0 || p.workEnd <= 23.98
          })}
          onClick={this._handleShowWorktimeEditor}
          svgName={Icons.POINT_WORKTIME}
          toolTip={I18N.TOOLTIP_WORKING_HOURS}
        />}
        {!p.garage
          && <Icon
            name={p.id}
            className={b('EditButton', { 'Remove': true })}
            onClick={() => this.props.removePoint(p.id)}
            svgName={Icons.POINT_REMOVE}
          />}
      </div>

      {errorMessage != null && <div className={b('FieldError')}>{errorMessage}</div>}

      {this.state.showWorktimeEditor && <>
        <label className={b('WorkTimeLabel')}>{I18N.CONTROLS_POINT_WORKTIME}:</label>
        <div className={b('WorkTime')}>
          <TimeInput
            name={'work_start'}
            onChangeValue={this._handleWorktimeChange}
            onFocus={() => this.props.focusPoint(p.id)}
            onBlur={() => this._handleWorktimeBlur('work_start')}
            value={this.state.workStart}
          />
          <TimeInput
            name={'work_end'}
            onChangeValue={this._handleWorktimeChange}
            onFocus={() => this.props.focusPoint(p.id)}
            onBlur={() => this._handleWorktimeBlur('work_end')}
            value={this.state.workEnd}
          />
          <NumberInput
            name={'shipping_time'}
            onChangeValue={this._handleWorktimeChange}
            onFocus={() => this.props.focusPoint(p.id)}
            onBlur={() => this._handleWorktimeBlur('shipping_time')}
            value={this.state.shipping_time}
          />
          <Button
            icon={Icons.RESET}
            onClick={this._resetWorkTime}
            toolTip={I18N.CONTROLS_RESET_WORKING_TIME_DESCRIPTION}
            smallIcon
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <TextInput
            name="comment"
            // style={{height:"45px"}}
            placeHolder={I18N.POINT_COMMENT}
            value={this.state.comment}
            onChangeValue={this._commentOnChange}
          />
        </div>
      </>}
    </div>;
  }
}

export default
  connect(
    (state) => ({
      highlightedPoint: state[reducer.name].highlightedPoint,
    }),
    (dispatch) => ({
      focusPoint: (id) => dispatch(actions.focusPoint(id)),
      hoverPoint: (id) => dispatch(actions.hoverPoint(id)),
    })
  )(ControlsAddressItem);