import React from 'react'
import { QuizOne } from './QuizOne'
import { toast } from 'react-toastify';
import I18N from '../../base/i18n';
import { ENDPOINTS } from 'base/api';
import { SubmitFormButton } from '../../common/components';
import queries from '../../base/queries';

export default
  class FirtsQuiz extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token && this.props.token,
      email: this.props.user && this.props.user.email,
      quizName: this.props.quizName,
      quizId: this.props.quizId,
      NumberQuestions: 0,
      SelectRadioCheck: '',
      processing: false,
      failureCode: null,
      Quiz: QuizOne,
    }
  }

  _setProcessing = (yes) => {
    this.setState({
      processing: yes
    });
  };

  componentWillMount() {
    queries.POLL(Number(1))
      .then((res) => {
        this.setState({ Quiz: res.data.result.spec });
      })
  }

  SendQuiz = () => {
    // console.log('SendQuiz');
    if (this.state.SelectRadioCheck) {
      let ttt = this.state.Quiz;
      ttt[this.state.NumberQuestions].answer = this.state.SelectRadioCheck;
      let Answers =
        ttt.map((p, i) => ({
          'Question': i,
          'Answer': p.answer,
        }));
      // console.log('Answers', Answers);
      this.props.finalStage( this.state.quizId, Answers);
/*       queries.POST_ANSWER(Number(1), Answers)
        .then((res) => {
          queries.UPDATE_USER({ 'firstQuizStatus': true });
          toast.success(`${I18N.QUIZ_SEND_SUCCESS}`);
        }) */
    }
    else {
      toast.error(`${I18N.ERROR_C005}`);
    }
  }


  Forward = () => {
    if (this.state.SelectRadioCheck) {
      let ttt = this.state.Quiz;
      ttt[this.state.NumberQuestions].answer = this.state.SelectRadioCheck;
      this.state.NumberQuestions < ttt.length - 1 &&
        this.setState({
          NumberQuestions: this.state.NumberQuestions + 1,
          SelectRadioCheck: null,
          Quiz: ttt,
        })
    } else {
      toast.warn(`${I18N.QUIZ_TOAST_WARN}`);
    }
  }

  Back = () => {
    let ttt = this.state.Quiz;
    if ((this.state.NumberQuestions > 1) && (ttt[this.state.NumberQuestions - 1].answer === false)) this.setState({ NumberQuestions: this.state.NumberQuestions - 2 })
    else this.state.NumberQuestions > 0 && this.setState({ NumberQuestions: this.state.NumberQuestions - 1 })
  }

  RenderAnswer = (Q, indexQ) => {
    const HandleOptionChange = e => {
      this.setState({ SelectRadioCheck: e.target.value })
    }

    let Num = 1;
    return <div className='Answer'>
      <p>{Q.title}</p>
      {
        Q.option.map((item, i) => (
          <label className="radio">
            <input type="radio"
              value={i}
              checked={this.state.SelectRadioCheck === String(i)}
              onChange={HandleOptionChange}
            />
            <div className="radio__text">{item}</div>
          </label>
        ))
      }
    </div>;
  }

  RenderAnswerIf = (PreQ, Q, indexQ) => {
    if (Number(PreQ.variation) === Number(PreQ.answer)) {
      return this.RenderAnswer(Q, indexQ)
    } else {
      let ttt = this.state.Quiz;
      ttt[this.state.NumberQuestions].answer = false;
      this.setState({
        NumberQuestions: this.state.NumberQuestions + 1,
        Quiz: ttt,
      })
      indexQ++;
      return this.RenderAnswer(Q, indexQ)
    }
  }

  RenderAnswerInput = (Q, indexQ) => {

    const HandleOptionChange = e => {
      this.setState({ SelectRadioCheck: e.target.value })
    }

    if (Q.type === 'input') {
      return <div>
        <p>{Q.title}</p>
        <input
          className='Input'
          placeholder={Q.placeholder}
          onChange={HandleOptionChange}
        />
      </div>
    }
    if (Q.type === 'textarea') {
      return <div className='box_textarea'>
        <p>{Q.title}</p>
        <textarea
          className='textarea'
          placeholder={Q.placeholder}
          onChange={HandleOptionChange}
        />
      </div>
    }
  }

  PreliminaryStage = (QuizOne) => {
    // console.log('PreliminaryStage', QuizOne);
    let indexQ = this.state.NumberQuestions;
    return <div>
      {
        QuizOne[indexQ].type === 'single' && this.RenderAnswer(QuizOne[indexQ], indexQ)
      }
      {
        QuizOne[indexQ].type === 'if' && this.RenderAnswerIf(QuizOne[indexQ - 1], QuizOne[indexQ], indexQ)
      }
      {
        (QuizOne[indexQ].type === 'input' || QuizOne[indexQ].type === 'textarea') && this.RenderAnswerInput(QuizOne[indexQ], indexQ)
      }
      <div className='QuizBtnGroup'>
        {indexQ > 0 &&
          <SubmitFormButton
            onClick={this.Back}
          >{I18N.SUBMIT_BACK}
          </SubmitFormButton>
        }
        {!QuizOne[indexQ].end_question ?
          <SubmitFormButton
            onClick={this.Forward}
          >{I18N.SUBMIT_FORWARD}
          </SubmitFormButton>
          :
          <SubmitFormButton
            onClick={this.SendQuiz}
            inProgress={this.state.processing}
          >{I18N.SUBMIT_SEND}
          </SubmitFormButton>
        }
      </div>
    </div>;
  }

  render() {
    // console.log('this.state.Quiz', this.state.Quiz);
    return <div className="FirstQuiz">
      <h2>Уважаемый пользователь</h2>
      <h3>
        Благодарим Вас за использование нашего сервиса.
        Для улучшения качества сервиса просим Вас ответить на несколько вопросов, выберите подходящий ответ:
        </h3>
      <br></br>
      {this.PreliminaryStage(this.state.Quiz)}
      {
        // console.log('QuizOne', QuizOne)
      }
      {
        // console.log('this.state', this.state)
      }
      {this.state.failureCode && <div>{I18N[`ERROR_${this.state.failureCode}`]} </div>}
    </div>;
  }
}