import React from "react";
import block from "bem-cn-lite";
import { TextInput, NumberInput } from "../../../common/components";
import PhoneInput from "../../../common/components/PhoneInput"
import { Button, TimeInput, CheckboxInput } from "common/components";
import Icons from "base/icons";
import I18N from "../../../base/i18n";
import { Agents, ButtonKinds } from "../../../common/helpers/enums";
import ControlsAddressItem from './ControlsAddressItem';

const b = block("ControlsAddressItem");

const ControlsCourierItem = (props) => {
  const agent = props.agent;
  const garageID = props.agent.garageID;
  const colors = ["maroon", "cornflowerblue", "firebrick", "seagreen", "violet", "tomato", "orange", "indigo", "chocolate", "gold"]

  const resetWorkTime = () => {
    props.updateAgentTime(agent.id, props.default.agent.workStart, props.default.agent.workEnd);
  };

  const handleWorkTimeChange = (name, value) => {
    if (name === "work_start") {
      // setWorkstart(value);
      props.updateAgentTime(agent.id, value, agent.workEnd);
    } else if (name === "work_end") {
      // setWorkend(value);
      props.updateAgentTime(agent.id, agent.workStart, value);
    }
  };

  return (
    <div
      style={{
        paddingTop: "10px",
        paddingBottom: "30px",
        backgroundColor: "#fff",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "row"
      }}
    >
      <div style={{
        width: "0.7em",
        backgroundColor: colors[Number(agent.id % 10)],
        height: "auto",
        marginRight: "0.3em"
      }}></div>
      <div>
        <div style={{ display: "flex", disabled: !props.default }}>
          <Button
            icon={Icons.AGENT_LIGHT}
            disabled={agent.delType === Agents.CAR}
            onClick={() =>
              props.updateAgentName(
                agent.id,
                agent.name,
                agent.phone,
                Agents.CAR,
                agent.maxPoints,
                agent.notReturning
              )
            }
            className="NewBtn"
          />
          <Button
            icon={Icons.AGENT_MAN}
            disabled={agent.delType === Agents.FOOT}
            // disabled={agent.deltype === Agents.FOOT}
            onClick={() =>
              props.updateAgentName(
                agent.id,
                agent.name,
                agent.phone,
                Agents.FOOT,
                agent.maxPoints,
                agent.notReturning
              )
            }
            className="NewBtn"
          />
          <div>
            <TextInput
              name="name"
              placeHolder={I18N.COURIER_NAMING}
              value={agent.name}
              onChangeValue={(name, value) =>
                props.updateAgentName(
                  agent.id,
                  value,
                  agent.phone,
                  agent.delType,
                  agent.maxPoints,
                  agent.notReturning
                )
              }
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <PhoneInput
              name="phone"
              placeHolder={I18N.COURIER_PHONE}
              value={agent.phone}
              toolTip={I18N.CONTROLS_AGENT_PHONE_TIP}
              onChangeValue={(value) =>
                props.updateAgentName(
                  agent.id,
                  agent.name,
                  value,
                  agent.delType,
                  agent.maxPoints,
                  agent.notReturning
                )
              }
            />
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "10px" }}>
          <div className={b("WorkTime")}>
            <TimeInput
              name={"work_start"}
              value={agent.workStart}
              onChangeValue={handleWorkTimeChange}
            />
            <TimeInput
              name={"work_end"}
              value={agent.workEnd}
              onChangeValue={handleWorkTimeChange}
            />
            <Button
              icon={Icons.RESET}
              onClick={resetWorkTime}
              toolTip={I18N.CONTROLS_RESET_WORKING_TIME_DESCRIPTION}
              smallIcon
            />
          </div>
          <div style={{marginLeft: "5px"}}>
            <NumberInput
              name="max_addresses"
              placeHolder={I18N.COURIER_MAX_POINTS}
              value={agent.maxPoints}
              onChangeValue={(name, value) =>
                props.updateAgentName(
                  agent.id,
                  agent.name,
                  agent.phone,
                  agent.delType,
                  value,
                  agent.notReturning
                )
              }
            />
          </div>

        </div>

        <div style={{ display: "flex", marginTop: "10px" }}>
          <ControlsAddressItem
            key={agent.id}
            point={props.points[garageID]}
            {...props}
          />
          <CheckboxInput
            value={!agent.notReturning}
            disabled={false}
            toolTip={agent.notReturning ? I18N.CONTROLS_RETURN_DESCRIPTION : I18N.CONTROLS_NOT_RETURN_DESCRIPTION}
            onChangeValue={() =>
              props.updateAgentName(
                agent.id,
                agent.name,
                agent.phone,
                agent.delType,
                agent.maxPoints,
                !agent.notReturning
              )
            }
          />
        </div>

        <div style={{ margin: "auto auto auto auto", maxWidth: "11rem", disabled: !props.default }}>
          <Button
            kind={ButtonKinds.DANGER}
            icon={Icons.CALCULATION_CLEAR}
            onClick={() => props.removeAgent(agent.id)}
          // onClick={()=>console.log(agent.id)}
          >
            {I18N.COURIER_DELETE}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ControlsCourierItem;
