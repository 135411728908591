import React from 'react'
import block from 'bem-cn-lite';
import Icons from '../../../base/icons';
import ManifestTask from "../../../common/helpers/ManifestTask";
import { yandexEvent } from "../../others/";
import PointValidator from "../helpers/PointValidator";
import queries from '../../../base/queries';
import { toast } from 'react-toastify';
import ConfirmButton from '../../../common/components/ConfirmButton';
import I18N from './../../../base/i18n'
import { Agents, ButtonKinds } from '../../../common/helpers/enums';

const b = block('Controls');

export default
  class ControlsFavoriteRoute extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  getFirstAddressPoint = (points) => {
    for (const id of Object.keys(points)) {
      if (!points[id].garage && points[id].geocoded) {
        return points[id]
      }
    }
  }

  getManifestPoints = (storedPoints) => {
    const firstPoint = this.getFirstAddressPoint(storedPoints);
    return Object.keys(storedPoints).reduce((allPoints, localPointID) => {
      if (storedPoints[localPointID].garage && !storedPoints[localPointID].geocoded) {
        this.props.updatePointAddress(localPointID, firstPoint.address, firstPoint.coords);
        storedPoints[localPointID] = {
          ...storedPoints[localPointID],
          address: firstPoint.address,
          coords: firstPoint.coords,
        }
      }
      return [
        ...allPoints,
        {
          garage: storedPoints[localPointID].garage,
          depot: storedPoints[localPointID].garage,
          name: localPointID,
          address: storedPoints[localPointID].address,
          comment: storedPoints[localPointID].comment,
          work_start: storedPoints[localPointID].workStart,
          work_end: storedPoints[localPointID].workEnd,
          shipping_time: storedPoints[localPointID].shipping_time/60,
          lat_lon: storedPoints[localPointID].coords,
        }
      ]
    }, [])
  }

  getManifestCouriers = (storedAgents, res) => {
    const resPoints = res.data.result.reduce((allPoints, point) => ({...allPoints, [point.name]: {...point}}), {})
    return Object.values(storedAgents).reduce((allPoints, localCourier) => [
      ...allPoints,
      {
        name: localCourier.name,
        phone: localCourier.phone,
        lat_lon: localCourier.coords,
        work_start: localCourier.workStart,
        work_end: localCourier.workEnd,
        shipping_time: localCourier.shipping_time,
        vehicle_type: localCourier.delType,
        max_weight: 5000,
        max_volume: 5000,
        garage_id: resPoints[localCourier.garageID].id,
        max_points: localCourier.maxPoints,
        not_returning: localCourier.notReturning,
      }
    ], []);
  }

  _startTask = () => {
    if (this.props.lastStartedManifestID) {
      queries.OPTIMIZATION_START(this.props.lastStartedManifestID)
      .then(() => {
        yandexEvent("buildRoute", this.props.points);
        yandexEvent("Successfully_built_route", this.props.points);
      });
    } else {
      const manifestDate = new Date();
      const manifestName = `${manifestDate.toLocaleDateString()} ${manifestDate.getMilliseconds()}`;
      queries.CREATE_MANIFEST(manifestName)
      .then((res) => {
        const manifest_id = res.data.result.id;
        this.props.setLastStartedManifest(manifest_id);
        this.props.setCurrentManifestID(manifest_id);

        const points = this.getManifestPoints(this.props.points);
        queries.CREATE_MANIFEST_POINTS(manifest_id, points)
        .then((resPoints) => {
          this.props.updatePointsIds(resPoints.data.result);
          const couriers = this.getManifestCouriers(this.props.agents, resPoints);
          queries.CREATE_MANIFEST_COURIERS(manifest_id, couriers)
          .then((resCouriers) => {
            this.props.updateAgentOnDBSave(resCouriers.data.result);
            queries.OPTIMIZATION_START(manifest_id)
            .then(() => {
              yandexEvent("buildRoute", this.props.points);
              // yandexEvent("Successfully_built_route", this.props.points);
            });
          })
        })
      })
      .catch(() => {
        toast.error(I18N.ERROR_C009);
      })
    }
  };

  _hasInvalidPoints = () => {
    if (Object.keys(this.props.points).length === 0) {
      return true
    }

    const points = Object.keys(this.props.points).reduce((all, p_id) => {
      if (!this.props.points[p_id].garage) {
        return {
          ...all,
          [p_id]: this.props.points[p_id]
        }
      } else {
        return all
      }
    }, {});

    if (Object.keys(points).length === 0) {
      return true
    }

    for (let k in points) {
      if (!PointValidator.isValid(this.props.points[k])) {
        return true;
      }
    }

    return false;
  };

  _isNeedCourierAddress = () => {
    let needAddress = false    
    Object.values(this.props.agents).map(agent => {
      if (!PointValidator.isValid(this.props.points[agent.garageID])) {
        needAddress = true
        return;
      }
    })
    return needAddress;
  }

  _handlerSavePoints = () => {
    if (this.props.currentManifestID) {
      queries.SAVE_MANIFEST(this.props.currentManifestID)
        .then((data) => {
          toast.success(I18N.FAVORITE_ROUTE_ADD_MANIFEST_SUCCESS);
        })
    } else {
      toast.error(I18N.FAVORITE_ROUTE_ADD_MANIFEST_ERROR);
    }
  }

  render() {
    const hasInvalidPoints = this._hasInvalidPoints();

    return <div className={b("inWrapper")}>
      <ConfirmButton
        kind={ButtonKinds.CONFIRM}
        confirmMessage={I18N.CONTROLS_ALERT_RUN_TASK}
        onClick={!hasInvalidPoints ? this._startTask : () => { }}
        icon={Icons.CALCULATION_RUN}
        disabled={hasInvalidPoints}
      >
        {I18N.CONTROLS_RUN}
      </ConfirmButton>

      <ConfirmButton
        kind={ButtonKinds.SMALL}
        icon={Icons.SAVE}
        confirmMessage={I18N.FAVORITE_ROUTE_SAVE_CONFIRM}
        // className={b('Button')}
        onClick={this._handlerSavePoints}
      >
        {I18N.FAVORITE_ROUTE_SAVE_MANIFEST}
      </ConfirmButton>

      <ConfirmButton
        kind={ButtonKinds.DANGER}
        confirmMessage={I18N.CONTROLS_ALERT_CLEAR_ADDRESSES}
        onClick={() => {
          this.props.setCurrentManifestID(null);
          this.props.resetAddresses();
          this.props.resetAgents();
        }}
        icon={Icons.CALCULATION_CLEAR}
        smallIcon
        colorRed={true}
      >
        {I18N.CONTROLS_DEL}
      </ConfirmButton>
    </div>;
  }
}