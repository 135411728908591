import React, { useRef, useEffect } from "react";
import { Polyline, withLeaflet } from "react-leaflet";
import L from "leaflet";
import "leaflet-polylinedecorator";

export default withLeaflet(props => {
  const polyRef = useRef();
  useEffect(() => {
    const polyline = polyRef.current.leafletElement;
    const { map } = polyRef.current.props.leaflet;
    const layer = polyRef.current.layerContainer;

    const decorator = L.polylineDecorator(polyline, {
      patterns : props.patterns
    }).addTo(map);

    // remove decorator on removing target polyline
    polyRef.current.leafletElement.on('remove', function () {
      layer.removeLayer(decorator);
    });
  }, []);

  return <Polyline ref={polyRef} {...props} />;
});