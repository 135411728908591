import React from 'react'
import {randomInteger} from "../helpers/randomInteger";

const usersAmount = new Array(30).fill(1);
const randomLetter = () => String.fromCharCode(randomInteger(97, 122));


const userList = usersAmount.map(item => {
  const lastSymbol = randomInteger(0, 1) ? randomLetter() : randomInteger(0, 9);
  return {
    userName: `${randomLetter()}${''.padStart(randomInteger(2, 7), '*')}${lastSymbol}`,
    addressesAmount: randomInteger(3, 300),
  }
});


const RunningLineList = () => (
  <div className="running-line__inner-wrapper" >
    {
      userList.map(({userName, addressesAmount}, i) => {
        // генерирует случайное имя пользователя
        return (
        <div key={i} className={'running-line__item'}>
          <span className='running-line__user-name'>Пользователь <strong>{userName}</strong></span>
          <span className='running-line__subtitle'>cейчас считает <strong>{addressesAmount}</strong> адресов</span>
        </div>
        )
      })
    }
  </div >

)

export const RunningLine = () => {
  return (
    <div className={'running-line'} >
      <RunningLineList />
      <RunningLineList />
    </div >
  )
}