// данные по баннерам

const banners = [{
    name: 'Логист', 
    to: 'Логисту',
    desc: 'Зачем контролировать курьеров?',
    link: 'https://zig-zag.org/',
    text: `<strong class="onetow">Зачем контролировать курьеров?</strong>
    <br />
    <br />
    <ul style="list-style: disc; padding-left: 25px;">
      <li>
        Курьеры опаздывают
        - страдает репутация компании
      </li>
      <li>
        Курьеры занимаются
        своими делами - падает процент доставки
      </li>
      <li>
        Курьеры могут
        сливать заказы вашим конкурентам
      </li>
    </ul>
    <br />
    <br />
    <strong>Как контролировать курьеров</strong>
    <br />
    <br />
    <ul style="list-style: disc;padding-left: 25px;">
      <li>
        Основной принцип контроля это построение ПЛАНА работы курьера.
        План работы курьера - это плановый маршрутный лист с расчетным временем прибытия в каждую точку с общим
        километражем и временем работы. План должен составляется автоматически, тем самым является объективным
        измерительным средством.
      </li>
      <li>
        Суть контроля сводится к Оперативной сверки (каждый час) сверки ПЛАНА
        работы с ЕГО ФАКТОМ т.е. где сейчас находится курьер, какой заказ он выполнил.
        Если план отличается от факт,например, курьер опаздывает, то это может негативно влиять на
        его заработную плату, в зависимости от схемы мотивации курьера.
      </li>
      <li>
        Проводить данную работу по сверки плана и факта руками
        без каких либо средств Автоматизации очень трудозатратно и когда курьеров больше 5 руками
        практически невозможно.
      </li>
    </ul>
    <br />
    <br />
    <strong>Оффер</strong>
    <br />
    <br />
    Наша компания для решения проблемы контроля и повышения
    качества работы курьеров предлагает использовать Сервис “Zig-Zag”, который состоит из блока построение
    оптимальных маршрутов и мобильного приложения курьера.
    <br />
    <br />
    Используя сервис Zig-Zag вы получете следующий результат
    <br />
    <br />
    <strong>Результаты</strong>
    <br />
    <br />
    <ol style="padding-left: 25px;">
      <li>Сокращения опозданий клиентам на 50%</li>
      <li>Сокращения пробега на 20%</li>
      <li>
        Повышение кол-во доставок на 20% тем же кол-вом курьеров
      </li>
      <li>
        Автоматическое начисление ЗП освободит Вам целый рабочий день
      </li>
      <li>Не надо покупать датчики GPS</li>
    </ol>`
},{
    name: 'Руководитель', 
    to: 'Руководителю',
    desc: 'Как правильно мотивировать водителей?',
    link: 'https://zig-zag.org/',
    text: `<strong class=\"onetow\">Как правильно мотивировать водителей</strong> <br /><strong>Почему нужно мотивировать водителей?</strong>
    <br />
    <br />
    <ol style='padding-left: 25px;''>
      <li>Водители могут приписывать себе лишнее время на выполнение заказа: например, потратил на поездку всего пару часов, а в бумажном путевом листе указал и получил деньги за четыре.</li>
      <li>Предприимчивые водители пытаются продать излишки топлива на сторону. Оказавшись на АЗС, они предлагают другим автомобилистам заправиться по их карте, а взамен берут наличные с большой скидкой.</li>
      <li>Вопрос «дедовщины», который часто возникает на предприятиях, где не построена логистика. В таких компаниях водители получают бонусы исключительно за количество доставленных заказов: кто куда поедет – решают сами водители. Сотрудники, которые работают давно, выбирают близкие друг к другу точки, а новеньким достается, что остается. Так, «старенький» водитель может выполнить большее количество заказов и заработать больше денег. </li>
    </ol>
    <br />
    <br />
    <strong>У подобной схемы работы есть несколько минусов: </strong>
    <br />
    <br />
    <ul style="list-style: disc;padding-left: 25px;">
    
      <li>Не оптимальные маршруты</li>
      <li>Несвоевременная доставка. Из-за отдаленности точек новенькие водители не всегда вовремя доставляют товар.</li>
      <li>Невозможность измерить эффективность каждого водителя</li>
      <li>Полное отсутствие контроля</li>
      <li>Несправедливое распределение нагрузки и заработной платы между водителями</li>
      <li>Низкая мотивация водителей, которые везут неудобные заказы</li>
    </ul>
    <br />
    У многих компаний существует подмена понятий: вот сейчас мы поставим на автомобили систему ГЛОНАСС, GPS-маячки и так далее, и мы будем их контролировать. Но знание того, сколько они проезжают километров - это не про планирование: вы просто будете знать едет машина, или нет. Анализировать - все ли машина довезла или нет, проехала она оптимально или нет, - невозможно.
    <br />
    <br />
    <strong>Как выстроить правильную мотивацию? </strong>
    <br />
    <br />
        Всё просто – оплату работы водителя нужно складывать из двух действий, из которых состоит их работа:<br />
    <ol style='padding-left: 25px;''>
    
      <li>Управление автомобилем</li>
      <li>Вручение заказа клиенту </li>
    </ol>
    <br />
    <br />
    Однако выстраивать процесс внедрения и управления мотивацией вручную – рутинно и зачастую неэффективно.
    <br />
    <br />
      В нашем сервисе построение оптимальных маршрутов данная схема мотивации водителей реализована в автоматическом режиме.
    <br />
    <br />
    <strong>Как это работает?</strong>
    <br />
    <br />
    Логист компании строит оптимальные маршруты доставки в сервисе
    <br />
    <br />
    Маршруты отображаются в мобильном приложении у водителей. В нем они выставляют статусы выполнения заказов
    <br />
    <br />
    В конце месяца сервис автоматически формирует зарплатный отчет по заранее определенным коэффициентам, которые влияют на стоимость вознаграждения.
    <br />
    <br />
    <strong>Результат</strong>
    <br />
    <br />
    <ul style="list-style: disc;padding-left: 25px;">
    
      <li>Увеличение дневного объема доставки на 30% </li>
      <li>Экономия на километраже</li>
      <li>Сокращение текучки персонала</li>
      <li>Экономия на обслуживании автомобиля</li>
    </ul>
    <br />
    <br />
    Мы поможем разработать и реализовать индивидуальную схему мотивации водителей. Заполните заявку, и наши специалисты проведут для вас презентацию.`
},{
    name: 'Логист', 
    to: 'Логисту',
    desc: 'Как рассчитать маршрут на неделю/месяц вперед за 15 минут',
    link: 'https://zig-zag.org/',
    text: `<strong class=\"onetow\">Как рассчитать маршрут на неделю/месяц вперед за 15 минут</strong>
    <br />
    <br />
    <strong>Зачем нужно рассчитывать маршрут на долгосрочную основу?</strong>
    <br />
    <br />
    Бизнес-цель любой компании —  рост ее прибыли, достижение которой невозможно представить без корректного учета расходов и действий для их уменьшения и оптимизации. Это позволит заранее узнать необходимые затраты на топливо, сотрудников, а также заработную плату.
    <br />
    <br />
    Кроме того, в компаниях возникают задачи, когда оптимальные маршруты нужно рассчитать не на один день, а на целую неделю или месяц вперед. Например, такая функция необходима мерчендайзерам.
    <br />
    <br />
    Однако провести такую работу вручную невозможно. Даже очень крутому логисту  будет сложно учесть десятки факторов, влияющих на время поездки, и из-за этого маршруты будут строиться с ошибками. В результате вы получите опоздания и дополнительные расходы, например, на бензин.
    <br />
    <br />
    <strong>Как  быстро рассчитать маршрут на неделю/месяц вперед?</strong>
    <br />
    <br />
    В нашем онлайн-сервисе можно рассчитать маршрут для водителя или курьера на неделю или месяц вперед.
    <br />
    <br />
    <strong>Как это работает?</strong>
    <br />
    <br />
    Логист компании загружает в сервис базу точек, которые нужно будет объехать курьеру или водителю.
    <br />
    <br />
    Логист проставляет время работы курьера или водителя, а также частоту посещения точек (например, 2 раза в неделю).
    <br />
    <br />
    Сервис автоматически создает маршруты для курьеров и водителей по заданным параметрам.
    <br />
    <br />
    <strong>Результат</strong>
    <br />
    <br />
    <ul style="list-style: disc;padding-left: 25px;">
      <li>Эффективное планирование. Составленный заранее оптимальные маршруты позволяют не превышать плановую  себестоимость доставки.</li>
      <li>Экономия ресурсов. Вы заранее будете знать: сколько курьеров или водителей вам понадобится, это избавит от дополнительного найма огромного числа сотрудников. Кроме того, составлением маршрута может заниматься один специалист.</li>
      <li>Корректное планирование расходов. Вы заранее будете знать, сколько вам понадобится транспортных средств и расходы на топливо.</li>
    </ul>
    <br />
    <br />
    Мы поможет быстро рассчитать маршрут на неделю/месяц вперед. Заполните заявку, и наши специалисты проведут для вас презентацию.
    <br />`
},{
    name: 'Логист', 
    to: 'Логисту',
    desc: 'Как правильно считать пройденный километраж курьеров',
    link: 'https://zig-zag.org/',
    text: `<strong class=\"onetow\">Как правильно считать пройденный километраж курьеров</strong>
    <br />
    <br />
    <strong>Почему важно считать пройденный километраж курьеров?</strong>
    <br />
    <br />
    Часто работа компаний с доставщиками строится таким образом: компенсируются расходы на топливо, оплачивается проезд, а также же покрывается износ автомобиля.
    <br />
    <br />
    Водители  могут использовать любую возможность получить дополнительные деньги: выполняют левые заказы на автомобиле предприятия, сливают горючее для личного пользования, приписывают лишнее время на выполнение заказа. После водители предъявляют компании завышенные пробеги.
    <br />
    <br />
    <strong>Как правильно рассчитать пройденный километраж курьеров? </strong>
    <br />
    <br />
    Всё просто – компенсировать затраты на топливо нужно по плановому километражу, а не по показателям, которые показывает ваш водитель.
    <br />
    <br />
    Однако выстраивать процесс планового расчета километража вручную – рутинно и зачастую неэффективно.
    <br />
    <br />
    В нашем онлайн-сервисе построение оптимальных маршрутов данная схема расчет пройденного километража курьеров реализована в автоматическом режиме.
    <br />
    <br />
    <strong>Как это работает?</strong>
    <br />
    <br />
    <ol style='padding-left: 25px;''>
      <li>Логист строит маршрут для водителя или курьера в сервисе</li>
      <li>Сервис построит оптимальный маршрут доставки и рассчитает плановый километраж с учетом всех правил движения, пробок, время на передачу из рук в руки.</li>
      <li>Маршруты отображаются в мобильном приложении у водителей. В нем они выставляют статусы выполнения заказов</li>
    </ol>
    <br />
    <br />
    <strong>Результат</strong>
    <br />
    <br />
    <ul style="list-style: disc;padding-left: 25px;">

      <li>Составленные заранее оптимальные маршруты позволяют не превышать плановую  себестоимость доставки.</li>
      <li>Экономия на ГМС и обслуживании автомобилей.</li>
      <li>Опоздания к клиентам сокращаются на 50%</li>
      <li>Пробег сокращается на 20%</li>
    </ul>
    <br />
    <br />
    Наши специалисты помогут быстро составить оптимальные маршруты для водителей и рассчитать километраж. Заполните заявку, и наши специалисты проведут для вас презентацию.`
},{
    name: 'Руководитель', 
    to: 'Руководителю',
    desc: 'Как связать сeрвис логистики с вашей Excel 1С CRM',
    link: 'https://zig-zag.org/',
    text: `<strong class=\"onetow\">Как связать сeрвис логистики с вашей Excel 1С CRM</strong>
    <br />
    <br />
    <strong>Зачем связывать сервис логистики с Excel 1C CRM?</strong>
    <br />
    <br />
    В сервисах Excel 1C CRM компании чаще всего хранят все данные доставки: ФИО клиентов, адреса и наименование товара. Обычно логисты вручную переносят все данные на карту доставки или заполняют таким образом карточки - такая работа может занять несколько часов работы.
    <br />
    <br />
    Из-за нерационального использования рабочего времени и задержек, курьеры позже выезжают на точки доставок, опаздывают или же вообще не доставляют товар день в день.
    <br />
    <br />
    <strong>Как связать сервис логистики с Excel 1C CRM?</strong>
    <br />
    <br />
    Для этого нужно использовать API и модули интеграции - это позволит в автоматическом режиме переносить ваши заказы напрямую в сервис логистики и уже приступить к построению маршрутов для водителей и курьеров.
    <br />
    <br />
    Всего в пару кликов нужные адреса и информация попадает в систему логистики.
    <br />
    <br />
    В нашем онлайн-сервисе данная схема работы реализована в автоматическом режиме. Есть открытое API, что позволит нашим специалистам сделать для вашей компании индивидуальный импорт данных.
    <br />
    <br />
    <strong>Результат</strong>
    <br />
    <br />
    <ul style="list-style: disc;padding-left: 25px;">
      <li>Сокращение рабочего времени логистов на 2-3 часа</li>
      <li>Сокращение опозданий курьеров на место доставки</li>
      <li>Автоматизация всех процессов логистики</li>
    </ul>
    <br />
    <br />
    Наши специалисты помогут разработать индивидуальный импорт данных из EXCEL. Заполните заявку, и наши специалисты проведут для вас презентацию.`
},{
    name: 'Руководитель', 
    to: 'Руководителю',
    desc: 'Как расчитать стоимость доставки для клиента на вашем сайте',
    link: 'https://zig-zag.org/',
    text: `<strong class=\"onetow\">Расчет стоимости доставки для клиента на вашем сайте</strong>
    <br />
    <br />
    Расчет стоимости доставки на сайте интернет-магазинов или ресторанов уже давно стал стандартом сервиса. Клиенты и посетители сайта хотят получить в свое распоряжение современный инструмент, позволяющий просто и быстро понять, в какую стоимость им обойдется стоимость доставки товара.
    <br />
    <br />
    Для компании - это минус недовольные клиенты, которые невнимательно смотрят таблицы с информацией о доставке и ошибаются.
    <br />
    <br />
    Данный кейс подойдет для тех интернет-магазинов, у которых стоимость доставки зависит от удаленности от склада. Как правило, он актуален для городов-миллионников.
    <br />
    <br />
    Наш онлайн-сервис поможет создать виджет, который поможет клиентам рассчитывать стоимость доставки.
    <br />
    <br />
    <strong>Как рассчитать стоимость доставки</strong>
    <br />
    <br />
    <ol style='padding-left: 25px;''>
      <li>Логист с помощью сервиса создает карту районов доставки</li>
      <li>Для каждого района доставки указывается ее стоимость</li>
      <li>Программист размещает виджет на ваш сайт</li>
    </ol>
    <br />
    <br />
    <strong>Результат</strong>
    <br />
    <br />
    <ul style="list-style: disc;padding-left: 25px;">
      <li>Вы решите проблему расчет стоимости доставки для клиентов всего за несколько часов работы</li>
      <li>Клиент получит достоверную информацию о цене и сроках прибытия доставки</li>
      <li>Выстраивает многие логистические решения внутреннего характера</li>
      <li>Повышение конверсии вашего магазина </li>
    </ul>
    <br />
    <br />
    Наши специалисты помогут сделать виджет расчета стоимости доставки для вашего сайта. Заполните заявку, и наши специалисты проведут для вас презентацию.`
}]

const max = 4;

export function random_banners(){
    for (let i = banners.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [banners[i], banners[j]] = [banners[j], banners[i]];
    }
    return banners
}
