import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { Button } from './';
import { ButtonKinds } from '../helpers/enums';
import I18N from '../../base/i18n';

const b = block('ConfirmDialog');

export default class ConfirmDialog extends React.PureComponent {

  static propTypes = {
    closeModal: PropTypes.func,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    confirmKind: PropTypes.oneOf(Object.keys(ButtonKinds)),
  };

  _onConfirm = () => {
    this.props.onConfirm();
    this.props.closeModal();
  }

  _onCancel = () => {
    this.props.closeModal();
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('Message')}>{this.props.message}</div>
        <div className={b('Buttons')}>
          <Button kind={ButtonKinds.UNIMPORTANT} clickOnce={true} onClick={this._onCancel}>{I18N.SUBMIT_CANCEL}</Button>
          <Button kind={this.props.confirmKind} clickOnce={true} onClick={this._onConfirm}>{I18N.SUBMIT_CONFIRM}</Button>
        </div>
      </div>
    );
  }
}

