import React from 'react';
import { isMobileDevice } from '../../../common/helpers/misc'
import Button from '../../../common/components/Button';
import Icons from '../../../base/icons'
import { ButtonKinds } from '../../../common/helpers/enums';
import block from 'bem-cn-lite';

const b = block('Controls');

const questionaryDesktop = '<script src="https://yastatic.net/q/forms-frontend-ext/_/embed.js"></script><iframe src="https://forms.yandex.ru/u/5dd545ae127e5bf1d2d01118/?iframe=1" frameborder="0" name="ya-form-5dd545ae127e5bf1d2d01118" width="100%" height="400px"></iframe>';
const questionaryMobile = '<script src="https://yastatic.net/q/forms-frontend-ext/_/embed.js"></script><iframe src="https://forms.yandex.ru/u/5dd545ae127e5bf1d2d01118/?iframe=1" frameborder="0" name="ya-form-5dd545ae127e5bf1d2d01118" width="100%" height="500px"></iframe>';

const questionary = () => {
  if (isMobileDevice())
    return <div className={b('inFrame')} dangerouslySetInnerHTML={{ __html: questionaryMobile }} />
  else
    return <div className={b('inFrame')} dangerouslySetInnerHTML={{ __html: questionaryDesktop }} />
}

export default
  class iframe extends React.Component {

  render() {
    return (
      <div className={b('IframeQuest')} >
        <Button
        icon={Icons.POINT_REMOVE}
        onClick={this.props.closeModal}
        kind={ButtonKinds.DANGER}
        className="BtnClose"
        >Закрыть
      </Button>
      {questionary()}
    </div>
    );
  }
}